import iconZoomPlus from "./images/iconZoomPlus.svg";
import iconZoomMinus from "./images/iconZoomMinus.svg";
import {COLORS} from "./map.startPage3.constants";
import pinImgDefault from "../nkn/images/point.svg";
import pinImgActive from "../nkn/images/point-active.svg";
import mediaBtn from "./images/mediaBtn.svg";
import videoBtn from "./images/videoBtn.svg";
import audioBtn from "./images/audioBtn.svg";
import mediaArrow from "./images/mediaArrow.svg";
import mediaPreview from "./images/mediaPreview.png";

import {utf8_to_b64} from "../../common/utils";

import "./less/mapStartPage.component.less";
import {EMPTY_PHOTO_URL} from "../../common/constants";

export function createZoomControlLayout(ymaps) {
    // Функция по созданию layout'a целиком взята из песочницы яндекс карт
    // https://tech.yandex.com/maps/jsbox/2.1/zoom_layout
    const ZoomLayout = ymaps.templateLayoutFactory.createClass(
        "<div class='zoomControl'>" +
        "<div id='zoom-in' class='btnZoomControl btnPlus'><img src='" + iconZoomPlus + "' /></div><br>" +
        "<div id='zoom-out' class='btnZoomControl btnMinus'><img src='" + iconZoomMinus + "' /></div>" +
        "</div>", {

            // Переопределяем методы макета, чтобы выполнять дополнительные действия
            // при построении и очистке макета.
            build: function () {
                // Вызываем родительский метод build.
                ZoomLayout.superclass.build.call(this);

                // Привязываем функции-обработчики к контексту и сохраняем ссылки
                // на них, чтобы потом отписаться от событий.
                this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
                this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

                // Начинаем слушать клики на кнопках макета.
                $('#zoom-in').bind('click', this.zoomInCallback);
                $('#zoom-out').bind('click', this.zoomOutCallback);
            },

            clear: function () {
                // Снимаем обработчики кликов.
                $('#zoom-in').unbind('click', this.zoomInCallback);
                $('#zoom-out').unbind('click', this.zoomOutCallback);

                // Вызываем родительский метод clear.
                ZoomLayout.superclass.clear.call(this);
            },

            zoomIn: function () {
                var map = this.getData().control.getMap();
                map.setZoom(map.getZoom() + 1, {checkZoomRange: true});
            },

            zoomOut: function () {
                var map = this.getData().control.getMap();
                map.setZoom(map.getZoom() - 1, {checkZoomRange: true});
            }
        });

    return ZoomLayout;
}

export function prepareRegionsObjects(acc, feature) {
    // Добавим ISO код региона в качестве feature.id для objectManager.
    var iso = feature.properties.iso3166;
    feature.id = iso;
    // Добавим опции региона по умолчанию.
    feature.options = {
        fillColor: "#193B94",
        fillOpacity: 0.6,
        // strokeColor: "#03184F",
        strokeColor: "#8291B7",
        strokeOpacity: 0.5,
        shadow: "20px 20px 20px 20px grey inset",
        // hintCloseTimeout: 0,
        // hintOpenTimeout: 0,
        // hasHint: true,
        // openEmptyHint: true,
        // openHintOnHover: true,
        // openEmptyBalloon: true,

    };
    feature.modules = ["ObjectManadger.addon.balloon", "ObjectManadger.addon.hint", "map.addon.balloon", "map.addon.hint",
        "geoObject.addon.balloon", "geoObject.addon.hint"];
    feature.properties = {
        hasBalloon: true,
        openEmptyBalloon: true,
    };

    acc[iso] = feature;
    return acc;
}

// Функция, которая раскрашивает регион и добавляет всех нераскрасшенных соседей в очередь на раскраску.
function paintRegion(iso, regions) {
    var allowedColors = COLORS.slice();
    // Получим ссылку на раскрашиваемый регион и на его соседей.
    var region = regions[iso];
    var neighbors = region.properties.neighbors;
    // Если у региона есть опция fillColor, значит мы его уже раскрасили.
    if (region.options.fillColor) {
        return;
    }
    // Если у региона есть соседи, то нужно проверить, какие цвета уже заняты.
    if (neighbors.length !== 0) {
        neighbors.forEach(function (neighbor) {
            // var fillColor = regions[neighbor].options.fillColor;
            // // Если регион раскрашен, то исключаем его цвет.
            // if (fillColor) {
            //     var index = allowedColors.indexOf(fillColor);
            //     if (index !== -1) {
            //         allowedColors.splice(index, 1);
            //     }
            //     // Если регион не раскрашен, то добавляем его в очередь на раскраску.
            // } else if (queue.indexOf(neighbor) === -1) {
            //     queue.push(neighbor);
            // }
        });
    }
    // Раскрасим регион в первый доступный цвет.
    region.options.fillColor = allowedColors[0];
}

export function paintRegions(regions) {
    // Очередь раскраски.
    var queue = [];

    for (var iso in regions) {
        // Если регион не раскрашен, добавим его в очередь на раскраску.
        if (!regions[iso].options.fillColor) {
            queue.push(iso);
        }
        // Раскрасим все регионы из очереди.
        while (queue.length > 0) {
            paintRegion(queue.shift(), regions);
        }
    }
}

export function preparePinsFromNkns(acc, placemark) {
    const pin = {};

    //placemark.preview = placemark.mainPhoto ? placemark.mainPhoto : EMPTY_PHOTO_URL;

    const EvtData = {
        title: placemark.title,
        mediaId: placemark.mediaId,
        mediaUrl: placemark.file,
        srcMediaUrl: placemark.srcMediaUrl,
        nknId: placemark.nknId,
    };

    const szEvtDataEncoded = utf8_to_b64(JSON.stringify(EvtData));
    const szOnClick = `"const evt = new CustomEvent('fire-show-audio', { detail: '${szEvtDataEncoded}' }); const btnNode = document.querySelector('#audioModalDiv'); btnNode.dispatchEvent(evt);"`;
    const szOnClickVideo = `"const evt = new CustomEvent('fire-show-video', { detail: '${szEvtDataEncoded}' }); const btnNode = document.querySelector('#videoModalDiv'); btnNode.dispatchEvent(evt);"`;

    /*
                              <video src=${placemark.file} width="100%" height="auto" controls controlsList="nodownload"/>
     */
    const getBtnByMediaType = ( medaType ) => {
        switch (medaType) {
            case 'AUDIO': return `<img src=${audioBtn} alt="mediaBtn" onClick=${szOnClick}/>`;
            case 'VIDEO': return `<img src=${videoBtn} alt="mediaBtn" onClick=${szOnClickVideo}/>`;
            default: return `<img src=${mediaBtn} alt="mediaBtn" />`;
        }
    }

    const balloonContent = `
        <div class="startPageMap__mediaPreview_block" style="width: 500px;">
                        <div class="startPageMap__mediaPreview_btn">
                            ${getBtnByMediaType(placemark.medaType)}
                        </div>
                        <div class="startPageMap__mediaPreview_about">
                            <div class="startPageMap__mediaPreview_about_imgBlock" style="background-image: url(${placemark.preview})">

                            </div>
                            <div class="startPageMap__mediaPreview_about_textBlock">
                                <div class="startPageMap__mediaPreview_about_textBlock-region">${placemark.region}</div>
                                <div class="startPageMap__mediaPreview_about_textBlock-name">${placemark.title}</div>
                            </div>
                            <div class="startPageMap__mediaPreview_about_arrow">
                               <a href="/#/nkn/${placemark.nknId}"><img src=${mediaArrow} alt="mediaArrow"/></a>                           
                            </div>
                        </div>
                    </div>
        `;
    // <div className="startPageMap__mediaPreview_about_imgBlock" style="background-image: url(${placemark.preview})"/>
    //<video src=${presignedUrl} width="100%" height="auto" controls controlsList="nodownload"/>

    pin.id=placemark.nknId;
    pin.geometry={
        type: 'Point',
            coordinates: placemark.coordinates,
    };

    pin.properties={
        hintContent: placemark.title,
        balloonContent: balloonContent,
    };
    pin.options={
        iconLayout: 'default#image',
            iconImageHref: `${pinImgDefault}`,
            hideIconOnBalloonOpen: false,
        // iconImageSize: [68, 84],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        // iconImageOffset: [-30, -40],
    };

    pin.modules=['geoObject.addon.balloon', 'geoObject.addon.hint'];
    pin.onMouseenter=e => e.get('target').options.set({"iconImageHref": `${pinImgActive}`});
    pin.onMouseleave=e => e.get('target').options.set({"iconImageHref": `${pinImgDefault}`});
    pin.onClick=e=>console.log('click');

    // https://github.com/mmarkelov/react-yandex-maps-layout/blob/master/src/App.js

    acc.push(pin);
    return acc;
}
