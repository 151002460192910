import React, {useEffect, useRef, useState} from 'react';
//https://openbase.io/js/react-player
import ReactPlayer from 'react-player';
//https://github.com/alexanderwallin/react-player-controls
import {Direction, PlayerIcon, Slider} from 'react-player-controls';

import Duration from './Duration';

import PaneHeader from './pane.header.component';
import PaneFooter from './pane.footer.component';

import './less/card.full.component.less';
import './less/pane.audio.component.less';
import question from "./images/question.png";
import objAudio from "../root/images/obj-audio.png";

import cubaService from 'common/api/cuba.reqs.service';
import { buildPhAVPaneDescr, getValueByLocale } from "../../common/utils";
import {PANE_NAME} from 'common/constants';
import { useTranslation } from 'react-i18next';
import {t} from "i18next";
// import { findDOMNode } from 'react-dom';

// import screenfull from 'screenfull';

if (ENV.debug.root) console.log('pane.audio.component.js');

const MEDIA_TYPE = "AUDIO";

export const PaneAudio = (props) => {
    const songs = props.nkn.heritageMedia ? props.nkn.heritageMedia.filter(hm => hm.mediaType === MEDIA_TYPE && !hm.isOnMap) : [];
    const { t, i18n } = useTranslation();
    const [url, setUrl] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [pip, setPip] = useState(false);
    const [played, setPlayed] = useState(0);
    const [seeking, setSeeking] = useState();
    const [duration, setDuration] = useState(0);
    const [controls, setControls] = useState(true);
    const [currId, setCurrId] = useState(null);
    const [light, setLight] = useState(false);
    const [volume, setVolume] = useState(0.8);
    const [muted, setMuted] = useState(false);
    const [playbackRate, setPlaybackRate] = useState();
    const [loop, setLoop] = useState(false);
    const [tooltip, setTooltip] = useState('');
    const [title, setTitle] = useState('');

    const playerEl = useRef(null);

    useEffect(() => {
        if (PANE_NAME.AUDIO === props.currActive) {
            // preload first
            if (url == null && songs.length > 0) {
                load(0);
            }
        } else {
            // stop playing
            if (playing === true) {
                setPlaying(false);
            }
        }

    }, [props.currActive]);

    const load = newSongId => {
        if (songs == null) return;
        if (songs.length <= newSongId) return;

        cubaService.fetchStreamUrl(songs[newSongId].file.id).then(data => {
            setTitle(getValueByLocale(songs[newSongId], 'description', i18n.language));
            setTooltip(buildPhAVPaneDescr(songs[newSongId]), i18n.language);

            setPlayed(0);
            setPip(false);
            setCurrId(newSongId);
            setUrl(data.url);
        });
    };

    if (songs.length <= 0) return (<>
        <PaneHeader nkn={props.nkn} />
        <h3>{t("audio")}</h3>
        <p>{t("audio-not-loaded")}</p>
        <PaneFooter nkn={props.nkn} />
    </>);

    return (
        <>
            <PaneHeader nkn={props.nkn} />
            <h3>{t("audio")}</h3>

            <div className='audio-player-wrapper'>
                <ReactPlayer
                    ref={playerEl}
                    className='react-player'
                    width='100%'
                    height='100%'
                    url={url}
                    pip={pip}
                    playing={playing}
                    controls={controls}
                    volume={volume}
                    muted={muted}
                    played={played}
                    onPlay={() => setPlaying(true)}
                    onEnablePIP={() => setPip(true)}
                    onDisablePIP={() => setPip(false)}
                    onPause={() => setPlaying(false)}
                    onSeek={(e) => {
                        if (e.target != null) setPlayed(e.target.value)
                    }}
                    onEnded={() => setPlaying(loop)}
                    onError={(e) => console.log('onError', e)}
                    onProgress={(progress) => setPlayed(progress.played)}
                    onDuration={(duration) => {
                        setDuration(duration)
                    }}

                />
            </div>
            <div className={!title ? 'faded' : ''}>
                <div className="nameSong" dangerouslySetInnerHTML={{__html: title}} />
            </div>

            <table>
                <tbody>
                    <tr>
                        <td width="9%">
                            <button className="btnPlay" title="Play" onClick={() => setPlaying(!playing)}>{playing ?
                                <PlayerIcon.Pause /> :
                                <PlayerIcon.Play />}</button>
                        </td>
                        <td width="53%">
                            <input
                                type='range' min={0} max={0.999999} step='any'
                                className="rangePlay"
                                value={played}
                                onMouseDown={() => setSeeking(true)}
                                onChange={(e) => {
                                    if (e.target == null) return;
                                    setPlayed(parseFloat(e.target.value));
                                }}
                                onMouseUp={(e) => {
                                    setSeeking(false);
                                    if (e.target == null) return;
                                    playerEl.current.seekTo(parseFloat(e.target.value));
                                }}
                            />
                        </td>
                        <td width="9%"><Duration seconds={duration * (1 - played)} /></td>
                        <td width="7%">
                            <div className="question">
                                <img src={question} alt={"Media information"} />
                                <span className="tooltiptext">{tooltip}</span>
                            </div>
                        </td>
                        <td width="3%" title={t("volume-control")}>
                            <img src={objAudio} alt={t("volume-control")} />
                        </td>
                        <td width="15%" title={t("volume-control")}>
                            <input type='range' className="volumeControl" min={0} max={1} step='any' value={volume}
                                onChange={(e) => {
                                    if (e.target == null) return;
                                    setVolume(parseFloat(e.target.value));
                                }} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr>
                        <td width="98%">
                            <ol className="listSongs">
                                {songs.map((song, songId) => (
                                    <div key={`song${songId}`}
                                        onClick={() => load(songId)}
                                        className={`songElement ${songId === currId ? 'active' : ''}`}>
                                        <li>
                                            <div dangerouslySetInnerHTML={{__html: getValueByLocale(songs[songId], 'description', i18n.language)}} />
                                        </li>
                                    </div>))}


                            </ol>
                        </td>
                    </tr>
                </tbody>
            </table>


            <PaneFooter nkn={props.nkn} />
        </>
    );
};

// export class PaneAudio1 extends Component {
//     constructor(props) {
//         super(props);
//         this.props = props;
//
//         const songs = this.props.nkn.heritageMedia ? this.props.nkn.heritageMedia.filter(hm => hm.mediaType == MEDIA_TYPE) : [];
//         songs.forEach(hm => hm.tooltip = `${hm.description}, ${hm.recordDate}. ${hm.recordPlace}`);
//
//         this.state = {
//             nkn: this.props.nkn,
//             isActive: this.props.isActive,
//             url: null,
//             pip: false,
//             playing: false,
//             controls: true,
//             light: false,
//             volume: 0.8,
//             muted: false,
//             played: 0,
//             loaded: 0,
//             duration: 0,
//             playbackRate: 1.0,
//             loop: false,
//             currId: null,
//             songs: songs,
//             /*songs: [
//               {
//                 title: 'Свадебная песня «Алай» в исполнении жителей села Махческ Республики Северная Осетия — Алания',
//                 url: 'https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3',
//                 tooltip: 'Свадебная песня «Алай» в исполнении жителей села Махческ Республики Северная Осетия — Алания'
//               },
//               {
//                 title: 'Свадебная песня «Ой нана» в исполнении народного мужского ансамбля из Владикавказа Республики Северная Осетия — Алания (соло Ю. Баев)',
//                 url: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8',
//                 tooltip: 'Свадебная песня «Ой нана» в исполнении народного мужского ансамбля из Владикавказа Республики Северная Осетия — Алания (соло Ю. Баев)'
//               },
//               {
//                 title: 'Свадебная песня «Чындзхæсджыты зарæг» в исполнении народного мужского ансамбля из Владикавказа Республики Северная Осетия — Алания (соло В. Кайсинов)',
//                 url: 'http://dash.edgesuite.net/envivio/EnvivioDash3/manifest.mpd',
//                 tooltip: 'Свадебная песня «Чындзхæсджыты зарæг» в исполнении народного мужского ансамбля из Владикавказа Республики Северная Осетия — Алания (соло В. Кайсинов)'
//               }
//             ]*/
//         }
//     }
//
//     componentDidUpdate(prevProps, prevState) {
//         if (prevProps.isActive === false && prevState.url == null) {
//             if (this.state.songs.length > 0) this.load(0);
//             // this.setState ( {playing: true} );
//         } else if (prevProps.isActive === false && prevState.playing === true) {
//             this.setState({playing: false});
//         }
//     }
//
//     load = songId => {
//         const {songs} = this.state;
//         if (songs == null) return;
//         if (songs.length <= songId) return;
//
//         this.setState({
//             url: songs[songId].url,
//             title: songs[songId].description,
//             tooltip: songs[songId].tooltip,
//             played: 0,
//             loaded: 0,
//             pip: false,
//             currId: songId
//         })
//     };
//
//     handlePlayPause = () => {
//         this.setState({playing: !this.state.playing})
//     }
//
//     handleStop = () => {
//         this.setState({url: null, playing: false, currId: null})
//     }
//
//     handleToggleControls = () => {
//         const url = this.state.url;
//         this.setState({
//             controls: !this.state.controls,
//             url: null,
//             currId: null
//         }, () => this.load(url))
//     };
//
//     handleToggleLight = () => {
//         this.setState({light: !this.state.light})
//     };
//
//     handleToggleLoop = () => {
//         this.setState({loop: !this.state.loop})
//     }
//
//     handleVolumeChange = e => {
//         this.setState({volume: parseFloat(e.target.value)})
//     }
//
//     handleToggleMuted = () => {
//         this.setState({muted: !this.state.muted})
//     }
//
//     handleSetPlaybackRate = e => {
//         this.setState({playbackRate: parseFloat(e.target.value)})
//     }
//
//     handleTogglePIP = () => {
//         this.setState({pip: !this.state.pip})
//     }
//
//     handlePlay = () => {
//         console.log('onPlay')
//         this.setState({playing: true})
//     }
//
//     handleEnablePIP = () => {
//         console.log('onEnablePIP')
//         this.setState({pip: true})
//     }
//
//     handleDisablePIP = () => {
//         console.log('onDisablePIP')
//         this.setState({pip: false})
//     }
//
//     handlePause = () => {
//         console.log('onPause')
//         this.setState({playing: false})
//     }
//
//     handleSeekMouseDown = e => {
//         this.setState({seeking: true})
//     }
//
//     handleSeekChange = e => {
//         this.setState({played: parseFloat(e.target.value)})
//     }
//
//     handleSeekMouseUp = e => {
//         this.setState({seeking: false})
//         this.player.seekTo(parseFloat(e.target.value))
//     }
//
//     handleProgress = state => {
//         // console.log('onProgress', state)
//         // We only want to update time slider if we are not currently seeking
//         if (!this.state.seeking) {
//             this.setState(state)
//         }
//     }
//
//     handleEnded = () => {
//         console.log('onEnded')
//         this.setState({playing: this.state.loop})
//     }
//
//     handleDuration = (duration) => {
//         console.log('onDuration', duration)
//         this.setState({duration})
//     }
//
//     handleClickFullscreen = () => {
//         screenfull.request(findDOMNode(this.player))
//     }
//
//     renderLoadButton = (songId) => {
//         const {songs} = this.state;
//         return (
//             <div key={`song${songId}`} onClick={() => this.load(songId)}
//                  className={`songElement ${songId === this.state.currId ? 'active' : ''}`}>
//                 <li>
//                     <div dangerouslySetInnerHTML={{__html: songs[songId].description}}/>
//                 </li>
//             </div>
//         )
//     };
//
//     ref = player => {
//         this.player = player
//     };
//
//     render() {
//         const {url, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip, title, tooltip, songs, nkn} = this.state
//         const SEPARATOR = ' · '
//         const {Slider, Direction} = require('react-player-controls')
//         const SliderBar = ({direction, value, style}) => <div style={computedStylesHere}/>
//         console.log("render PaneAudio");
//
//         return (
//             <React.Fragment>
//                 <PaneHeader nkn={nkn}></PaneHeader>
//                 <h3>Аудио</h3>
//                 {this.state.songs.length > 0 ? <React.Fragment>
//                     <div className='audio-player-wrapper'>
//                         <ReactPlayer
//                             ref={this.ref}
//                             className='react-player'
//                             width='100%'
//                             height='100%'
//                             url={url}
//                             pip={pip}
//                             playing={playing}
//                             controls={controls}
//                             light={light}
//                             loop={loop}
//                             playbackRate={playbackRate}
//                             volume={volume}
//                             muted={muted}
//                             onReady={() => console.log('onReady')}
//                             onStart={() => console.log('onStart')}
//                             onPlay={this.handlePlay}
//                             onEnablePIP={this.handleEnablePIP}
//                             onDisablePIP={this.handleDisablePIP}
//                             onPause={this.handlePause}
//                             onBuffer={() => console.log('onBuffer')}
//                             onSeek={e => console.log('onSeek', e)}
//                             onEnded={this.handleEnded}
//                             onError={e => console.log('onError', e)}
//                             onProgress={this.handleProgress}
//                             onDuration={this.handleDuration}
//                         />
//                     </div>
//                     <div className="{!title ? 'faded' : ''}">
//                         <div className="nameSong" dangerouslySetInnerHTML={{__html: title}}/>
//                     </div>
//
//                     <table>
//                         <tbody>
//                         <tr>
//                             <td width="9%">
//                                 <button className="btnPlay" onClick={this.handlePlayPause}>{playing ?
//                                     <PlayerIcon.Pause/> : <PlayerIcon.Play/>}</button>
//                             </td>
//                             <td width="53%">
//                                 <input
//                                     type='range' min={0} max={0.999999} step='any'
//                                     className="rangePlay"
//                                     value={played}
//                                     onMouseDown={this.handleSeekMouseDown}
//                                     onChange={this.handleSeekChange}
//                                     onMouseUp={this.handleSeekMouseUp}
//                                 />
//                             </td>
//                             <td width="9%"><Duration seconds={duration * (1 - played)}/></td>
//                             <td width="7%">
//                                 <div className="question">
//                                     <img src={question}/>
//                                     <span className="tooltiptext">{tooltip}</span>
//                                 </div>
//                             </td>
//                             <td width="3%" title="Регулировка громкости">
//                                 <img src={objAudio}/>
//                             </td>
//                             <td width="15%" title="Регулировка громкости">
//                                 <input type='range' className="volumeControl" min={0} max={1} step='any' value={volume}
//                                        onChange={this.handleVolumeChange}/>
//                             </td>
//                         </tr>
//                         </tbody>
//                     </table>
//                     <table>
//                         <tbody>
//                         <tr>
//                             <td width="98%">
//                                 <ol className="listSongs">
//                                     {this.state.songs.map((song, i) => this.renderLoadButton(i))}
//
//                                 </ol>
//                             </td>
//                         </tr>
//                         </tbody>
//                     </table>
//
//                 </React.Fragment> : <p>Аудио материалы для данного объекта не загружены</p>}
//
//                 <PaneFooter nkn={nkn}></PaneFooter>
//             </React.Fragment>
//         )
//     }
// }

export default PaneAudio
