import React from 'react';
import * as sc from "./search.constants";
import {connect} from "react-redux";
import {SearchShortPopup} from "./search.short.popup.component";
import {SearchParamValueSelection} from "./search.param.value.component";
import {searchSliceActions} from "../../redux/search.reducer";
import { useTranslation } from 'react-i18next';

if ( ENV.debug.root ) console.log ( 'search.tag.list.component.js' );

const filter2tags = (filter) => {
	const tags = [];

	for (let filterType in filter) {
		if (!filter.hasOwnProperty(filterType)) continue;
		switch (filterType) {
			case sc.SEARCH_REGION_NAME:
				// filter[filterType].forEach(value => tags.push(value.title)); //shortDescr
				tags.push(filter[filterType][0].title);
				break;
			case sc.SEARCH_DISTRICT_NAME:
				filter[filterType].forEach(value => tags.push(value.title)); // descr
				break;
			case sc.SEARCH_DICTVAL_NAME:
				filter[filterType].forEach(value => tags.push(value.title));
				break;
			case sc.SEARCH_CATEGORY_NAME:
				tags.push(filter[filterType].title);
				break;
			case sc.SEARCH_TERM_NAME:
				if ( filter[filterType].length ) tags.push(filter[filterType]);
				break;
			case sc.SEARCH_AUTHOR_NAME:
				filter[filterType].forEach(value => tags.push(value.title));
				break;
		}
	}
	return tags;
};

export const SearchTagsList = ( props ) => {
	if ( props.filter == null ) return null;
	const {t} = useTranslation();

	const tagList = filter2tags (props.filter);

	const onClear = ( ) => {
		props.dispatch(searchSliceActions.dropAll());
		if ( props.go ) props.go ( 'public.search.list' );
	};

	return tagList.length === 0
		? ( <div className="searched"><h6>{t("no-search-criterion")}</h6></div>)
		: ( <div className="searched"><h6>{t("looking-for")}:</h6>
			{ tagList.map ( (tag, idx) => <div key={`tag${idx}`} className="tag-searched">{tag}</div> ) }
			<div className="btn-clear" onClick={onClear}>{t("clear")}</div>
		</div>);
};

const mapState = state => {
	return state.search;
};
export default connect(mapState)(SearchTagsList);
// export default SearchTagsList;
