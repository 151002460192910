import React, {useEffect, useState} from 'react';
import {Col, Nav, Row, Tab, Spinner} from 'react-bootstrap';

import SearchFullPanel from 'modules/search/search.full.component';
import SearchResultsList from 'modules/search/search.results.list.component';
import SearchTagsList from 'modules/search/search.tag.list.component';

import EntityTree from "../../common/EntityTree";
import RegionTree from "../../common/RegionTree";

import {store} from "store.config";
import './less/category.list.component.less';
import {useComponentWillMount} from "../../common/utils";
import * as s from "../../redux/load.status.constants";
import {fetchCategories, fetchRegions} from "../../redux/dict.reducer";
import {connect} from "react-redux";
import BreadcrumbNkn from "../nkn/breadcrumb.nkn.component";
import {searchSliceActions} from "../../redux/search.reducer";
import { getValueByLocale } from 'common/utils';
import {useTranslation} from 'react-i18next';

if (ENV.debug.root) console.log('category.list.component.js');

const SubCategories = (props) => {
    if (props.subCats == null) return;
    if (!props.subCats.length) return null;

    const dropPage = () => props.dispatch(searchSliceActions.setPage(0));

    return props.subCats.map(cat => (<Nav.Item key={cat.id} onClick={dropPage}>
        <Nav.Link eventKey={cat.id}>{getValueByLocale(cat,'title', props.lang)}</Nav.Link>
    </Nav.Item>))
};

const calcBreadcrumbs = (breadcrumbs, rootCategory, currCategory, hasSubCats, lang) => {
    const bc = breadcrumbs ? [...breadcrumbs] : [];

    // make breadcrumbs path
    if (rootCategory) bc.push({
        state: 'public.category.list',
        params: {categoryId: rootCategory.id, page: 0},
        title: getValueByLocale(rootCategory, 'title', lang),
    });

    if (hasSubCats && currCategory && currCategory !== rootCategory) bc.push({
        state: 'public.category.list',
        params: {categoryId: currCategory.id, page: 0},
        title: getValueByLocale(currCategory, 'title', lang),
    });
    return bc;
};

export const CategoryList = (props) => {
    const [loading, setLoading] = useState(true);
    const [currCategory, setCurrCategory] = useState(null);
    const [rootCategory, setRootCategory] = useState(null);
    const [subCats, setSubCats] = useState([]);
    const [dicts, setDicts] = useState(null);
    const [currBreadcrumbs, setCurrBreadcrumbs] = useState([]);

    const { t, i18n } = useTranslation();

    // step 1
    useComponentWillMount(() => {
        // console.log("CategoryList: useEffect for dicts");
        if (props.categories.status === s.EMPTY) store.dispatch(fetchCategories());
        if (props.regions.status === s.EMPTY) store.dispatch(fetchRegions());
    });

    // step 2
    useEffect(() => {
        // console.log ( "props.categories = ", props.categories.status  );
        // console.log ( "props.regions = ", props.regions.status  );
        if (props.categories.status === s.EMPTY || props.regions.status === s.EMPTY) return;

        const dicts = {categories: new EntityTree(), regions: new RegionTree()}
        dicts.categories.setTree(props.categories.categories);
        dicts.regions.setTree(props.regions.regions);
        setDicts(dicts);

    }, [props.regions.status, props.categories.status, i18n.language]);

    // step 3
    useEffect(() => {
        // console.log("CategoryList: useEffect for dicts");
        if (dicts == null) return;

        // for rendering
        // setup root and current nodes
        const currCategory = dicts.categories.findById(props.$stateParams.categoryId);

        let rootCategory = currCategory; // if root category selected
        if (currCategory.parentId) {
            // if child category selected
            rootCategory = dicts.categories.findById(currCategory.parentId);
        }

        const subCats = dicts.categories.getChildren(rootCategory.id);
        setCurrCategory(currCategory);
        setRootCategory(rootCategory);
        setSubCats(subCats);

        setCurrBreadcrumbs(calcBreadcrumbs(props.breadcrumbs,
            rootCategory, currCategory,
            subCats != null && subCats.length > 0,i18n.language));

        props.dispatch(searchSliceActions.setSingleCategory({
            id: currCategory.id,
            title: getValueByLocale(currCategory, 'title', i18n.language)
        }));
        if ( props.search.page !== undefined ) {
            props.dispatch(searchSliceActions.setPage(props.search.page));
        }

        setLoading(false);

    }, [dicts, i18n.language]);

    // вызывается при активации пункта дерева слева
    const changeCat = (newKey) => {
        // page param is not set (I don't know why) so drop page here
        props.dispatch(searchSliceActions.setPage(0));
        props.transition.router.stateService.go('public.category.list', {categoryId: newKey, page: 0});
    };

    // UIRouter not pass page when stay in same path
    const dropPage = () => props.dispatch(searchSliceActions.setPage(0));

    if (loading) {
        return (<>
            <BreadcrumbNkn path={currBreadcrumbs} onClick={dropPage} />

            <h1>{currCategory ? getValueByLocale(currCategory, 'title', i18n.language) : ` `}</h1>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </>);
    }

    if (!loading && currCategory == null) return (<>
        <BreadcrumbNkn path={currBreadcrumbs} onClick={dropPage} />
        <h1>{currCategory ? getValueByLocale(currCategory,'title', i18n.language) : t('category-not-found')}</h1>
    </>);

    return (<>
        <BreadcrumbNkn path={currBreadcrumbs} onClick={dropPage} />

        <h1>{currCategory ? getValueByLocale(currCategory,'title', i18n.language) : ` `}</h1>
        <Tab.Container id="category-object-list" activeKey={currCategory ? currCategory.id : null} onSelect={changeCat}>
            <Row>
                <Col lg={3} md={4}>
                    <div className="left-col-card">
                        <Nav className="flex-column left-nav-card">
                            <div className="listItems">
                                <SubCategories subCats={subCats} lang={i18n.language} dispatch={props.dispatch} />
                            </div>
                            <SearchFullPanel dicts={dicts} router={props.$transition$.router} />
                        </Nav>
                    </div>
                </Col>
                <Col lg={9} md={8}>
                    <div className="right-col-card">
                        <Tab.Content>
                            <div className="sort-objects">
                                <SearchTagsList go={props.transition.router.stateService.go} />
                                <SearchResultsList router={props.$transition$.router} startPage={props.$stateParams.page} />
                            </div>
                        </Tab.Content>
                    </div>
                </Col>
            </Row>
        </Tab.Container>
    </>)
};

export default connect((state) => ({
    categories: state.categories,
    regions: state.regions,
    breadcrumbs: state.crumbs,
    search: state.search,
}))(CategoryList);
