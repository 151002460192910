import React, {useState} from 'react';
import {Form, FormControl, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {searchSliceActions} from 'redux/search.reducer';
import topSearch from "./images/top-search.png"
import { useTranslation } from 'react-i18next';

if (ENV.debug.root) console.log('search.short.input.component.js');

export const SearchShortInput = (props) => {
    const [term, setTerm] = useState('');
    const [focused, setFocused] = useState(false);
    const doSearch = (evt) => {
        if (evt == null) return;
        evt.preventDefault();
        evt.stopPropagation();

        // if ( term.length ) {
            props.dispatch(searchSliceActions.setTerm(term));
        // }
        props.stateService.go('public.search.list', {page: 0}, {reload: true, inherit: false, notify: true});
    };
    const { t } = useTranslation();

    return (<>
        <div className="block-search">
            <Form inline className="form-search" onSubmit={doSearch}>
                <FormControl type="text" placeholder={t("search")} className="mr-sm-12"
                    value={term}
                    onChange={(e) => setTerm(e.currentTarget.value)}
                    onBlur={(e) => setFocused(false)}
                    onFocus={(e) => setFocused(true)}
                />
                <Button type={"submit"} variant="light" className={focused ? 'input-focused' : ''}>
                    <img style={{height: 'auto', width: '100%'}}
                        src={topSearch} /></Button>
            </Form>
        </div>
    </>)
};

const mapState = state => ({});
export default connect(mapState)(SearchShortInput);
