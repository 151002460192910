import React, {Component} from 'react';
import {UIView} from '@uirouter/react';

export class CategoryMain extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return ( <React.Fragment>
            <UIView />
            </React.Fragment>
        );

    }
}

export default CategoryMain;
