import React, {Component, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

// компоненты bootstrap
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import cubaService from 'common/api/cuba.reqs.service';
import {UISref} from "@uirouter/react";
import { getLocaleNode } from 'common/utils';

// предлагаю отделять стили для страницы-контейнера и для отдельных карточек в списке
import './less/contacts.page.component.less';

if (ENV.debug.root) console.log('contacts.page.component.js');
const ABOUT_PAGE = 'about_project';

// Классы всегда именуются с большой буквы
export const AboutPage = (props) => {
    const [loading, setLoading] = useState(false)
    const [localeRoot, setLocaleRoot] = useState(null)
    const {t, i18n} = useTranslation();

    const loadText = async () => {
        setLoading(true)

        const response = await cubaService.getAllItemsByView('nkn$SiteText', 'siteTextLocalizationView')
        const data = await response.data;

        let docRoot = data.find(d => d.label === ABOUT_PAGE);
        const locRoot = i18n.language === "ru" ? docRoot
            : getLocaleNode(docRoot?.localizationSiteTextFields, i18n.language);

        setLocaleRoot(locRoot);
        setLoading(false);
    };

    useEffect(() => {
        loadText();
    }, [i18n.language]);

    if (localeRoot === null) return <div>{t("list-filling")}</div>;

    return (<>
    <Breadcrumb>
        <UISref to={"public.main"}><Breadcrumb.Item href="/">{t("main")}</Breadcrumb.Item></UISref>
    </Breadcrumb>
            {localeRoot && <h1>{localeRoot.name}</h1>}
            {localeRoot &&
                <div className='text-underline-only'
                     dangerouslySetInnerHTML={{__html: localeRoot.content}}/>}
        </>
    )
};

export default AboutPage;
