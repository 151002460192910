import React, {useEffect, useState} from 'react';
import {UISref} from '@uirouter/react';
import { buildCommonNknAttrList } from 'common/utils';
import { EMPTY_PHOTO_URL } from 'common/constants';
import {useTranslation} from 'react-i18next';
import { getValueByLocale } from 'common/utils';

import './less/card.short.tableview.component.less';

import pinAddress from "modules/root/images/objLocation.svg";
import objAbout from "modules/root/images/objAbout.svg";
import objAudio from "modules/root/images/objAudio.svg";
import objVideo from "modules/root/images/objVideo.svg";
import objPhoto from "modules/root/images/objPhoto.svg";
import objMap   from "modules/root/images/objMap.svg";


if ( ENV.debug.root ) console.log ( 'card.short.component.js' );

/*
            <div className="share-block clearfix">
                <img src={ objAbout } className="float-left" />
                <img src={ objPhoto } className="float-left" />
                <img src={ objAudio } className="float-left" />
                <img src={ objVideo } className="float-left" />
                <img src={ objMap } className="float-left" />
            </div>
 */
const NknCardShortTableView = ( props ) => {
    // if ( props.nkn.dictTree == null  ) return null;
    const { nkn } = props;
    const { t, i18n } = useTranslation();
    const [title, setTitle] = useState('');
    const [song, setSong] = useState([])
    const [video, setVideo] = useState([])

    useEffect(() => {
        const songs = nkn.heritageMedia ? nkn.heritageMedia.filter(hm => hm.mediaType === "AUDIO" && !hm.isOnMap) : [];
        setSong(songs);
        const videos = nkn.heritageMedia ? nkn.heritageMedia.filter(hm => hm.mediaType === "VIDEO" && !hm.isOnMap) : [];
        setVideo(videos)
    }, [])

    useEffect( () => {
        setTitle(getValueByLocale (nkn, 'title', i18n.language));
    }, [i18n.language]);

    // set right translation
    let cTitles = [];
    if (props.nkn.categories && props.nkn.categories.length > 0) {
        cTitles = props.nkn.categories[0].map(c => ({title: getValueByLocale(c, 'title', i18n.language)}));
    }
    const categories = nkn.categories && nkn.categories.length ? buildCommonNknAttrList ( cTitles ) : '';
    const mainPhoto = nkn.mainPhoto ? nkn.mainPhoto.url : EMPTY_PHOTO_URL;

    let address = '';
    if ( nkn.heritagePlaces && nkn.heritagePlaces.length > 0 ) {
        if ( i18n.language === 'ru') {
            address = nkn.heritagePlaces[0].address.addrText;
        } else  {
            // debugger;
            address = getValueByLocale(nkn.heritagePlaces[0], 'addrText', i18n.language);
            if ( !address ) address = ''; // nkn.heritagePlaces[0].address.addrText;
        }
    }

    return (<div className="random-card">
        <div className="name-card"><h3>{ categories }</h3></div>
        <div className="block-img" style={{'backgroundImage': `url('${mainPhoto}')`}} />
        <div className="text-card">
            <UISref to={'public.nkn.card'} params={{nknId:nkn.id}} ><h2>{title}</h2></UISref>
            { address ? (<><img src={ pinAddress } /><span className="address">{address}</span></>) : null }
        </div>
        <div className="share-block clearfix">
            <UISref to={'public.nkn.card'} params={{nknId:nkn.id, pane: 'about'}} ><img src={ objAbout } className="float-left" /></UISref>
            <UISref to={'public.nkn.card'} params={{nknId:nkn.id, pane: 'photo'}} ><img src={ objPhoto } className="float-left" /></UISref>
            {song.length ? <UISref to={'public.nkn.card'} params={{nknId:nkn.id, pane: 'audio'}} ><img src={ objAudio } className="float-left" /></UISref> : null}
            {video.length ? <UISref to={'public.nkn.card'} params={{nknId:nkn.id, pane: 'video'}} ><img src={ objVideo } className="float-left" /></UISref> : null}
            {i18n.language === "ru" && <UISref to={'public.nkn.card'} params={{nknId:nkn.id, pane: 'map'}} ><img src={ objMap } className="float-left" /></UISref>}
        </div>

    </div>);
};
export default NknCardShortTableView;
