import { catsSliceReducer, regsSliceReducer, dictsSliceReducer } from './dict.reducer';
import { searchSliceReducer } from './search.reducer';

//import counterReducer from '../features/counter/counterSlice'

const rootReducer = {
  categories: catsSliceReducer,
  regions: regsSliceReducer,
  dicts: dictsSliceReducer,
  search: searchSliceReducer,
};

export default rootReducer;
