import React, {useEffect, useState} from 'react';
import Chart from "react-google-charts";
import { useTranslation } from 'react-i18next';

import "./less/footer.component.less";
import cubaService from "../../common/api/cuba.reqs.service";
import {Spinner} from "react-bootstrap";

if (ENV.debug.root) console.log('statistics.component.js');

const pieOptions = {
    slices: [ {color: "#CDDBEF"}, {color: "#6D61B7"}, {color: "#fff"} ],
    title: 'Количество объектов',
    legend: 'none',
    is3D: 'false',
    strokeWidth: '0',
    chartArea: {
        left: 0,
        top: 0,
        width: "100%",
        height: "80%",
        backgroundColor: {
            strokeWidth: '0'
        },
    },
    pieSliceTextStyle: {
        color: '#264576',
    },
    backgroundColor: {
        fill: 'transparent',
        strokeWidth: '0'
    },
    fontName: "Roboto",
    allowHtml: true
};

const fetchStat = async () => {
    // console.log(`fetchStat`);
    const response = await cubaService.getStatTotal();
    return response != null && response.data != null ? response.data : null;
};

export const RootStatisticsBlock = (props) => {
    const [loading, setLoading] = useState(true);
    const [statData, setStatData] = useState(null);
    const [statDataTable, setStatDataTable] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        fetchStat().then(data => {
            if ( data == null ) return;
            /*
            age: {new: 9, old: 0}
            status: {A: 16, C: 6}
             */
            // some magic here
            const dataChart = [
                [' ', ' '],
                [ t('data-chart-table.approved'), data.status.A ],
                [ t('data-chart-table.candidates'), data.status.C ]
            ];
            setStatData ( dataChart );
            const total = parseInt(data.status.C) + parseInt(data.status.A);

            // some magic too
            const dataChartTable = [
                [{type: "string", p: {html: true}}, ' ', ' '],
                [`<div class="block-color white"></div>`, `${t("data-chart-table.total")}`, `<span class="number">${total}</span>`],
                [`<div class="block-color grey"></div>`, `${t("data-chart-table.approved")}`, `<span class="number">${data.status.A}</span>`],
                [`<div class="block-color fiolet"></div>`, `${t("data-chart-table.candidates")}`, `<span class="number">${data.status.C}</span>`]
            ];
            setStatDataTable (dataChartTable);

            setLoading(false)
        });
    }, [t]);

    if (loading) return (<Spinner animation="border" variant="primary"/>);
    if ( statData == null ) return null;

    return (<>
        <div className="pie-chart-main">
            <div className="pie-chart-main_block">
                {/*<h2>Статистика</h2>*/}
                <Chart
                    width={'200px'}
                    height={'200px'}
                    chartType="PieChart"
                    loader={<div>Загрузка диаграммы</div>}
                    data={statData}
                    options={pieOptions}
                    graph_id="PieChartMainPage"
                    rootProps={{'data-testid': '1'}}
                    legend_toggle
                />
            </div>
            <div className="pie-chart-main_block" style={{marginTop: "30px"}}>
                <h2>{t("data-chart-table.title")}</h2>
                <Chart
                    width={'200px'}
                    height={'200px'}
                    chartType="Table"
                    loader={<div>Загрузка диаграммы</div>}
                    data={statDataTable}
                    title={'Количество объектов'}
                    options={pieOptions}
                    graph_id="TableChartMainPage"
                    legend_toggle
                    rootProps={{'data-testid': '1'}}
                />
            </div>
        </div>
    </>)
};
export default RootStatisticsBlock
