import React from 'react';
import { UIView } from '@uirouter/react';
import { Container } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import "assets/less/fonts.less";
import './less/root.layout.less';

if (ENV.debug.root) console.log('root.layout.js');

export const RootLayout = () => {
    return (<>
        <Container fluid>
            <UIView name="header"/>
            <Container>
                <div className="main-content">
                    <UIView/>
                </div>
                <UIView name="footer"/>
            </Container>
        </Container>
    </>);
};

export default RootLayout;
