import EntityTree from 'common/EntityTree';

/*
{
	"_entityName": "nkn$HeritageObjectDictionaryLink",
	"_instanceName": "Русский (Южно-русский говор)",
	"id": {
		"dictionaryEntityId": "7c4670e0-e566-3e6f-481b-3316fdfa6c8f",
		"heritageObjectId": "c3c259fd-0a7e-f873-1909-2a0091174265"
	},
	"dictionaryEntity": {
		"_entityName": "nkn$LanguageDictionaryEntity",
		"_instanceName": "Русский",
		"id": "7c4670e0-e566-3e6f-481b-3316fdfa6c8f",
		"title": "Русский"
	},
	"additionalInfo": "Южно-русский говор"
},
===>
[{
	id: nkn$LanguageDictionaryEntity
},
{
		"_entityName": "nkn$LanguageDictionaryEntity",
		"_instanceName": "Русский",
		"id": "7c4670e0-e566-3e6f-481b-3316fdfa6c8f",
		"title": "Русский",
		"parentId" : nkn$LanguageDictionaryEntity
	}
]
 */
export default class DictionaryTree extends EntityTree {
	constructor( data ) {
		super ( data );
    }

    setTree ( data ) {
    	this.treeList = Array.isArray(data) ? this.parseDictionaryList ( data ) : [];
    }

	parseDictionaryList ( data ) {
		const fixedData = data.map ( item => {
			const resItem = { ...item.dictionaryEntity};
			resItem.parentId = item.dictionaryEntity._entityName;
			return resItem;
		});

    	const roots = data.reduce ( (accu, item) => {

    		// also completely rebuild item
    	    item.parentId = item.dictionaryEntity._entityName || null; // "nkn$HeritageObjectCategoryDictionaryEntity"


    		if ( accu.findIndex ( root => root.id === item.parentId ) === -1 ) {
    			accu.push ( {id: item.parentId } );
    		}
    		return accu;
    	}, [] );

    	// if nothing found use plain list
    	if ( roots.length === 0 ) return data;
    	return [ ...fixedData]; // ...roots,
    }

	get category () {
		// debugger;
		return this.getList ( `nkn$HeritageObjectCategoryDictionaryEntity`)
	}

	get ethnos () {
		return this.getList ( `nkn$EthnosDictionaryEntity`)
	}

	get religion () {
		return this.getList ( `nkn$ReligionDictionaryEntity`)
	}

	get language () {
		return this.getList ( `nkn$LanguageDictionaryEntity`)
	}

	get tags() {
		return this.getList ( `nkn$TagDictionaryEntity`)
	}

}

