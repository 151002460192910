import React, {useState, useEffect} from 'react';
import { Spinner } from 'react-bootstrap';
import cubaService from 'common/api/cuba.reqs.service';
import DictionaryTree from "common/DictionaryTree";
import { getMainPhotoUrl } from "common/nkn.helpers";
import {connect} from "react-redux";
import EntityTree from "common/EntityTree";
import { getRandomIntegerNumber, buildCommonNknAttrList } from "common/utils";
import NknCardRandomTableView from "modules/nkn/card.random.tableview.component";

import { DEFAULT_RANDOM_NKN, EMPTY_PHOTO_URL } from "common/constants";

import {useTranslation} from 'react-i18next';
import { getValueByLocale } from 'common/utils';
import {fetchSearch} from 'common/api/cuba.search.service';
import * as sc from '../search/search.constants';

const fetchRandom = async (lang) => {

    const responseRandomId = await cubaService.getRandomNknObjectId(lang);
    if ( !responseRandomId && !responseRandomId.data ) return null;

    const nknId = responseRandomId.data.id;
    const responseNknCard = await cubaService.getItemById('nkn$HeritageObject', nknId, 'heritageObjectCardView');
    return responseNknCard != null && responseNknCard.data != null && !Array.isArray(responseNknCard.data) ? responseNknCard.data : null;
};

const processNkn = ( randomNkn, catList, regList, lang ) => {
    // console.log ( `processNkn ${randomNkn.id}` );
    const catTree = new EntityTree();
    catTree.setTree ( catList.categories );

    const dictTree = new DictionaryTree(randomNkn.dictionaryLinks);
    // const nknCategory = dictTree.category.length > 0 ? catTree.getBranch(dictTree.category[0].id) : [];
    const nknCategory = dictTree.category.length > 0 ? catTree.getBranch(dictTree.category[0].id) : [];
    // set right translation
    const cTitles = nknCategory.map( c => ({title: getValueByLocale(c, 'title', lang)}) );

    let regLocName = '';
    if ( randomNkn.heritagePlaces && randomNkn.heritagePlaces.length ) {
        const regLoc = regList.regions.find ( r => r.id ===  randomNkn.heritagePlaces[0].region.id );
        if ( regLoc ) regLocName = getValueByLocale(regLoc, 'descr', lang );
    }

    return randomNkn ? {
        id: randomNkn.id,
        category: buildCommonNknAttrList (cTitles),
        // category: randomNkn.dictionaryLinks && randomNkn.dictionaryLinks.length ? randomNkn.dictionaryLinks[1].dictionaryEntity.title
        region: regLocName, // .address.addrText
        title: getValueByLocale(randomNkn, 'title', lang),
        shortDescription: getValueByLocale(randomNkn, 'shortDescription', lang),
    } : null;
};

export const RandomNknBlock = ( props ) => {
    const [loading, setLoading] = useState(true);
    const [randomNkn, setRandomNkn] = useState(null);
    const [preview, setPreview] = useState(EMPTY_PHOTO_URL);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if ( !props.regions.regions.length ) return;
        fetchRandom(i18n.language).then ( nkn => {
            // get photo
            nkn && getMainPhotoUrl(nkn).then(url => setPreview(url));
            // fill objext
            setRandomNkn(nkn ? processNkn(nkn, props.categories, props.regions, i18n.language) : nkn);
            setLoading(false);
        });
    }, [props.regions, i18n.language]);

    if (loading) return (<Spinner animation="border" variant="primary"/>);

    return randomNkn != null ? (<NknCardRandomTableView {...randomNkn} preview={preview} />) : null;
};

const mapState = state => ({
    categories: state.categories,
    regions: state.regions
});
export default connect(mapState)(RandomNknBlock);
