import React, {useState} from 'react';
import {YMaps, Map, Placemark} from 'react-yandex-maps';

import PaneHeader from './pane.header.component';
import PaneFooter from './pane.footer.component';

import './less/card.full.component.less';
import pin from "./images/pin.png";
import { useTranslation } from 'react-i18next';

if (ENV.debug.root) console.log('pane.map.component.js');

const MIN_ZOOM = 10;

export const PaneMap = (props) => {
    const { t } = useTranslation()
    const { nkn } = props;
    console.log ( "render PaneMap" );

    const [ymaps, setYmaps] = useState(null);
    const heritagePlaces = nkn.heritagePlaces && nkn.heritagePlaces.length ? nkn.heritagePlaces : [];

    const centerGeo =
        heritagePlaces.length &&
        heritagePlaces[0] &&
        heritagePlaces[0].address &&
        heritagePlaces[0].address.coordinate ? [heritagePlaces[0].address.coordinate.latitude, heritagePlaces[0].address.coordinate.longitude]
            : [55.684758, 37.738521];

    const pointsList = heritagePlaces
        .filter(place => place.address != null && place.address.coordinate != null)
        .map(place => [place.address.coordinate.latitude, place.address.coordinate.longitude]);

    return (
        <>
            <PaneHeader nkn={nkn}/>
            <h3>{t("on-the-map")}</h3>
            <div className="map-container1">
                <YMaps query={{lang: "ru_RU", load: "util.bounds"}}>
                    <Map state={{center: centerGeo, zoom: 5}}
                         onLoad={ymaps => setYmaps(ymaps)}
                         modules={["layout.ImageWithContent"]}
                         width={"100%"} height={"400px"}
                         instanceRef={ref => ref && calcCenter( ymaps, ref, pointsList )}
                    >
                        <PlacemarksList heritagePlaces={heritagePlaces}/>
                    </Map>
                </YMaps>
            </div>

            <PaneFooter nkn={nkn} />
        </>
        )
};

const PlacemarksList = ( props ) => {
    // console.log ( "this.heritagePlaces = ", this.heritagePlaces);
    return props.heritagePlaces
    .filter(place => place.address != null && place.address.coordinate != null)
    .map(place => (<Placemark
        key={`ymp${place.id}`}
        geometry={[place.address.coordinate.latitude, place.address.coordinate.longitude]}
        properties={{
            //balloonContentHeader: "Балун метки",
            balloonContentBody: `${place.address.addrText}`,
            //balloonContentFooter: "Подвал",
            hintContent: `${place.address.addrText || ''}`,
        }}
        modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
        options={{
            iconLayout: 'default#image',
            iconImageHref: `${pin}`,
            //iconContentLayout: this.state.template,
            // iconImageSize: [25, 35],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            // iconImageOffset: [0, 0]
        }}
    />))
};

const calcCenter = (ymaps, ref, pointsList) => {
    // First of all check if ymaps is available
    if (ymaps) {

        ref.container.fitToViewport();
        const contSize = ref.container.getSize();
        if ( contSize[0] > 0  && contSize[1] > 0 ) { // if is visible

            const result = ymaps.util.bounds.getCenterAndZoom(
                ymaps.util.bounds.fromPoints(pointsList),
                contSize
            );

            // Setting the optimal center and zoom level of the map.
            ref.setCenter(result.center, Math.min ( result.zoom, MIN_ZOOM ) ); //result.zoom
        }
    }
};

export default PaneMap
