import React, {Component} from 'react';
import {UIView} from '@uirouter/react';

export const RegionMain = ( props ) => <UIView></UIView>;
// export class RegionMain extends Component {
//     constructor(props) {
//         super(props);
//         this.props = props;
//     }
//
//     render() {
//         return ( <UIView></UIView>);
//
//     }
// }

export default RegionMain;
