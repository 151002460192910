import React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {UISref} from "@uirouter/react";

// return (
//     <Breadcrumb>
//         <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
//         <Breadcrumb.Item href="#">Российская Федерация</Breadcrumb.Item>
//         <Breadcrumb.Item href="#">{props.nkn.heritagePlaces[0].address.addrText}</Breadcrumb.Item>
//         <Breadcrumb.Item href="#">{props.nkn.dictTree.ethnos.map ( c=> c.title ).join ( ` / ` )}</Breadcrumb.Item>
//         <Breadcrumb.Item href="#">{props.nkn.dictTree.category.map ( c=> c.title ).join ( ` / ` )}</Breadcrumb.Item>
//     </Breadcrumb>
// )
const BreadcrumbNkn = (props) => {
    const {t} = useTranslation();

    const calcPath = ( path ) => {
        if ( path == null ) return null;
        if ( path.length === 0 ) return null;

        // title
        // state 'public.category.list'
        // params {categoryId:c.id, page: 0}
        //
        // active={idx === (path.length-1)}
        return path.map( (p, idx) => {
            return <UISref key={`brd${idx}`} to={p.state} params={p.params}>
                <Breadcrumb.Item onClick={props.onClick}>{p.title}</Breadcrumb.Item>
            </UISref>;
        });
    };

    // onClick should drop page in state
    return (
        <Breadcrumb>
            <UISref to={"public.main"}><Breadcrumb.Item href="/" onClick={props.onClick}>{t("main")}</Breadcrumb.Item></UISref>
            { calcPath ( props.path) }
        </Breadcrumb>
    )


};
export default BreadcrumbNkn;
