export const EMPTY_PHOTO_URL = '/assets/images/empty-photo.png';
export const PREVIEW_LENGTH = 2000;

export const ADMIN_URL = `/admin/#/`;
export const TOP_MENU = [
    {titleId: `nav.statistics`, path: `public.statistics`, lang: ["ru"]},
    {titleId: `nav.docs`, path: `public.docs`, lang: ["ru", "en"]},
    {titleId: `nav.council`, path: `public.council`, lang: ["ru", "en"]},
    {titleId: `nav.publications`, path: `public.publications`, lang: ["ru", "en"]},
    {titleId: `nav.about`, path: `public.about`, lang: ["ru", "en"]},
];

export const NEW_NKN_COUNT = 6;
export const DEFAULT_RANDOM_NKN = 1;

export const PANE_NAME = {
    ABOUT: 'about',
    PHOTO: 'photo',
    AUDIO: 'audio',
    VIDEO: 'video',
    MAP: 'map'
};

export const SEARCH_ATTRS_COUNT = 6;
export const SEARCH_OPTIONS = [
    // {title: "Критерий не задан", entityName: null },
    {title: "Этнос", entityName: 'nkn$EthnosDictionaryEntity', viewName: 'ethnosDictionaryEntityView', titleName: 'title', placeholder: `search-options.ethnos`, lang: ["ru", "en"] },
    {title: "Категории", entityName: 'nkn$HeritageObjectCategoryDictionaryEntity', viewName: 'heritageObjectCategoryDictionaryEntityView', titleName: 'title', placeholder: `search-options.category`, lang: ["ru", "en"] },
    {title: "Регион", entityName: 'common$DictRegion', viewName: '_local', titleName: 'shortDescr', placeholder: `search-options.region`, lang: ["ru", "en"] },
    {title: "Язык", entityName: 'nkn$LanguageDictionaryEntity', viewName: 'languageDictionaryEntityView', titleName: 'title', placeholder: `search-options.language`, lang: ["ru", "en"] },
    {title: "Конфессия", entityName: 'nkn$ReligionDictionaryEntity', viewName: 'religionDictionaryEntityView', titleName: 'title', placeholder: `search-options.confessia`, lang: ["ru", "en"] },
    {title: "Теги", entityName: 'nkn$TagDictionaryEntity', viewName: 'tagDictionaryEntityView', titleName: 'title', placeholder: `search-options.tag`, lang: ["ru", "en"] },
    {title: "Авторы", entityName: 'nkn$Researcher', viewName: 'researcherView', titleName: 'lastName', placeholder: `search-options.authors`, lang: ["ru", "en"] },
];
export const DICT_REGION_NAME = 'common$DictRegion';
export const DICT_CATEGOY_NAME = 'nkn$HeritageObjectCategoryDictionaryEntity';
export const DICT_AUTHOR_NAME = 'nkn$Researcher';


export const MAX_DOT_TAIL = 5;
