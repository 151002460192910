import React, {useState} from 'react';

import {buildPhAVPaneDescr} from "../../common/utils";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from 'react-slick';

import PaneHeader from './pane.header.component';
import PaneFooter from './pane.footer.component';
import './less/card.full.component.less';

import {removeHtmlTags} from 'common/utils';
import {MAX_DOT_TAIL} from "common/constants";

import prev from "./images/prev.png";
import next from "./images/next.png";
import { useTranslation } from 'react-i18next';

if (ENV.debug.root) console.log('pane.photo.component.js');
const MEDIA_TYPE = "PHOTO";

const settingsMain = {
    className: "slider sliderMain variable-width",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    //centerMode: true, // dont use this
    //customPaging: this.renderThumbs,
};

const settingsThumbs = {
    className: "slider sliderThumbs variable-width",
    slidesToShow: 7,
    slidesToScroll: 1,
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px'
};

const PhotoTitle = (props) => {
    if (!props.slide) return null;
    const title = buildPhAVPaneDescr(props.slide, props.lang);
    if ( !title.length ) return null;

    return (<div><p dangerouslySetInnerHTML={{__html: title}}/></div>);
};

export const PanePhoto = (props) => {
    const gallery = props.nkn.heritageMedia ? props.nkn.heritageMedia.filter(hm => hm.mediaType === MEDIA_TYPE && !hm.isOnMap) : [];
    const { t, i18n } = useTranslation();
    const [navMain, setNavMain] = useState(null);
    const [navThumbs, setNavThumbs] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);

    if (props.nkn == null) return null;

    return (<>
            <PaneHeader nkn={props.nkn}/>
            <h3>{t("photo")}</h3>

            {gallery.length === 0
                ? <p>{t('photo-not-found')}</p>
                : <div className="slider-wrapper">
                    <Slider {...settingsMain}
                            asNavFor={navThumbs}
                            ref={slider => setNavMain(slider)}
                            afterChange={current => setActiveSlide(current)}
                    >
                        {gallery.map((s, idx) => {
                            const photoDescr = buildPhAVPaneDescr(s, i18n.language);
                            return (<div key={`fcphg${idx}`}>
                                <div className="slideBlock" style={{backgroundImage: `url('${s.url}')`}}>
                                    { photoDescr.length ? (<span className="tooltiptext" dangerouslySetInnerHTML={ { __html: photoDescr} }/>) : null }
                                </div>
                            </div>);
                        })}
                    </Slider>

                    <div className="thumbnail-slider-wrap">
                        <Slider
                            {...settingsThumbs}
                            asNavFor={navMain}
                            ref={slider => setNavThumbs(slider)}
                        >
                            {gallery.map((s, idx) => (
                                <div key={`fcphg${idx}`}>
                                    <div className="slideBlock" style={{backgroundImage: `url('${s.url}')`}}/>
                                </div>))
                            }
                        </Slider>
                    </div>
                </div>
            }
            <div className="info-block">
                <div className="info-block-text">
                    <PhotoTitle slide={gallery[activeSlide]} lang={i18n.language} />
                </div>
            </div>
            <PaneFooter nkn={props.nkn}/>
        </>
    );
};

export default PanePhoto;
