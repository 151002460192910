import React, {useState} from 'react';
import {connect} from "react-redux";
import { useTranslation } from 'react-i18next';

import {searchSliceActions} from 'redux/search.reducer';
import SearchParamValueSelection from './search.param.value.component';
import {makeFilterMultiselect} from "../../common/utils";

import {SEARCH_OPTIONS} from "common/constants";

if (ENV.debug.root) console.log('search.short.popup.component.js');

// const makeFilter = (params) => {
//     const filter = {};
//     params.forEach( item => {
//         if ( item.dictValue == null ) return;
//
//         const option = SEARCH_OPTIONS[item.optId];
//         if ( !option) return;
//
//         const fNodeName = option.entityName === DICT_REGION_NAME ? sc.SEARCH_REGION_NAME : sc.SEARCH_DICTVAL_NAME;
//         if ( filter[fNodeName] == null ) filter[fNodeName] = [];
//         filter[fNodeName].push ({id: item.dictValue, title: item.valueTitle});
//     });
//
//     return filter;
// };

const initAttrs = () => {
    return Array(SEARCH_OPTIONS.length).fill('').map((_, idx) => ({
        dictValue: null,
        valueTitle: '',
        optId: idx,
        multiselect: null,
    }));
};

export const SearchShortPopup = (props) => {
    const { t } = useTranslation()
    const [searchAttrs, setSearchAttrs] = useState(initAttrs());

    const doSearch = (evt) => {
        if (evt == null) return;
        evt.preventDefault();
        evt.stopPropagation();

        const f = makeFilterMultiselect(searchAttrs);
        props.dispatch(searchSliceActions.setFullFilter(f));
        setSearchAttrs(initAttrs());

        props.stateService.go('public.search.list', {page: 0}, {reload: true, inherit: false, notify: true});
    };

    const onKeyChange = (e, searchableId) => {
        const optId = e.currentTarget.value;

        const currAttr = searchAttrs[searchableId];
        currAttr.dictValue = null;
        currAttr.optId = parseInt(optId);
        setSearchAttrs(searchAttrs => [...searchAttrs]);
    };

    const onValueChange = (value, searchableId) => {
        const currAttr = searchAttrs[searchableId];
        currAttr.dictValue = value.valueId;
        currAttr.valueTitle = value.valueTitle;

        setSearchAttrs(searchAttrs => [...searchAttrs]);
    };


    const onMultiValueChange = (values, searchableId) => {
        const currAttr = searchAttrs[searchableId];
        // currAttr.dictValue = value.valueId;
        // currAttr.valueTitle = value.valueTitle;
        currAttr.multiselect = values;
        setSearchAttrs(searchAttrs => [...searchAttrs]);
    };


    return (<>
        <div className="advanced-search">
            {searchAttrs.map((item, idx) => (
                <SearchParamValueSelection
                    key={idx}
                    attrId={idx}
                    optId={item.optId}
                    dictValue={searchAttrs[idx].multiselect?.valueId ? searchAttrs[idx].multiselect.valueId : null}
                    onChange={onMultiValueChange} />
            ))}

            <div className="btn-filter" onClick={doSearch}>{t("search")}</div>
        </div>
    </>)
};

export default connect(null)(SearchShortPopup);
// export default SearchShortPopup;
