import React, {useEffect, useState} from 'react';
import {Row, Col, Nav, Tab, Spinner} from 'react-bootstrap';
import {t} from 'i18next';
import {useTranslation} from 'react-i18next';

import EntityTree from "common/EntityTree";
import RegionTree from 'common/RegionTree';

import SearchTagsList from "modules/search/search.tag.list.component";
import SearchResultsList from 'modules/search/search.results.list.component';
import BreadcrumbNkn from "../nkn/breadcrumb.nkn.component";

import {store} from "store.config";
import {connect} from "react-redux";
import {searchSliceActions} from "../../redux/search.reducer";
import {fetchCategories, fetchRegions} from "../../redux/dict.reducer";

import {useComponentWillMount} from "../../common/utils";
import {getValueByLocale} from 'common/utils';
import * as s from "../../redux/load.status.constants";

import './less/region.list.component.less';

ENV.debug.region && console.log('region.list.component.js');

const calcBreadcrumbs = (currRegion, lang) => {
    return [
        {
            title: t('rf'),
            state: 'public.country.list',
            params: {page: 0}
        },
        {
            title: getValueByLocale(currRegion.federalDistrict, 'descr', lang),
            state: 'public.district.list',
            params: {districtId: currRegion.federalDistrict.id, page: 0}
        },
        {
            title: getValueByLocale(currRegion, 'descr', lang),
            state: 'public.region.list',
            params: {regionId: currRegion.id, page: 0}
        }
    ];
};

const SubRegions = (props) => {
    if (props.subRegs == null) return;
    if (!props.subRegs.length) return null;

    const dropPage = () => props.dispatch(searchSliceActions.setPage(0));

    return props.subRegs.map(reg => (
        <Nav.Item onClick={dropPage} key={reg.id}>
            <Nav.Link eventKey={`${reg.id}`}>{getValueByLocale(reg, 'shortDescr', props.lang)}</Nav.Link>
        </Nav.Item>
    ))
};

const RegionList = (props) => {
    const [loading, setLoading] = useState(true);
    const [currRegion, setCurrRegion] = useState(null);
    const [subRegs, setSubRegs] = useState([]);
    const [currBreadcrumbs, setCurrBreadcrumbs] = useState([]);
    const [dicts, setDicts] = useState(null);

    const {t, i18n} = useTranslation();

    // step 1
    useComponentWillMount(() => {
        ENV.debug.region && console.log("RegionList: useEffect for dicts");
        if (props.categories.status === s.EMPTY) store.dispatch(fetchCategories());
        if (props.regions.status === s.EMPTY) store.dispatch(fetchRegions());
    });

    // step 2
    useEffect(() => {
        // ENV.debug.region && console.log ( "props.categories = ", props.categories.status  );
        // ENV.debug.region && console.log ( "props.regions = ", props.regions.status  );
        if (props.categories.status === s.EMPTY || props.regions.status === s.EMPTY) return;

        const dicts = {categories: new EntityTree(), regions: new RegionTree()}
        dicts.categories.setTree(props.categories.categories);
        dicts.regions.setTree(props.regions.regions);
        setDicts(dicts);

    }, [props.regions.status, props.categories.status]);

    // step 3
    useEffect(() => {
        ENV.debug.region && console.log("useEffect for all other");
        if (dicts == null) return;

        const currRegion = dicts.regions.findById(props.$stateParams.regionId);
        if (currRegion) {
            const subRegs = dicts.regions.getChildren(currRegion.federalDistrict.id).sort((a, b) => a.sortOrd - b.sortOrd);

            setCurrRegion(currRegion);
            setSubRegs(subRegs);
            setCurrBreadcrumbs(calcBreadcrumbs(currRegion, i18n.language));

            props.dispatch(searchSliceActions.setSingleRegion([{
                id: currRegion.id,
                title: getValueByLocale(currRegion, 'shortDescr', i18n.language)
            }]));
            if (props.search.page !== undefined) {
                props.dispatch(searchSliceActions.setPage(props.search.page));
            }
        }
        setLoading(false);
    }, [dicts]);

    // вызывается при активации пункта дерева слева
    const changeReg = (currKey) => {
        props.transition.router.stateService.go('public.region.list', {regionId: currKey, page: 0});
    };

    // UIRouter not pass page when stay in same path
    const dropPage = () => props.dispatch(searchSliceActions.setPage(0));

    if (loading) {
        return (<>
            <BreadcrumbNkn path={currBreadcrumbs} onClick={dropPage}/>
            <h1>{currRegion ? getValueByLocale(currRegion.federalDistrict, 'shortDescr', i18n.language) : ``}</h1>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </>);
    }

    if (!loading && currRegion == null) return (<>
        <BreadcrumbNkn path={currBreadcrumbs} onClick={dropPage}/>
        <h1>{currRegion ? getValueByLocale(currRegion.federalDistrict, 'shortDescr', i18n.language) : t('region-page-not-found')}</h1>
    </>);

    return (<>
        <BreadcrumbNkn path={currBreadcrumbs} onClick={dropPage}/>
        <h1>{currRegion ? getValueByLocale(currRegion.federalDistrict, 'shortDescr', i18n.language) : ``}</h1>
        <Tab.Container id="region-object-list" activeKey={currRegion ? currRegion.id : null} onSelect={changeReg}>
            <Row>
                <Col lg={3} md={4}>
                    <div className="left-col-card">
                        <h2>{t('subjects-of-the-Russian-Federation')}</h2>
                        <Nav className="flex-column left-nav-card">
                            <SubRegions subRegs={subRegs} lang={i18n.language} dispatch={props.dispatch}/>
                        </Nav>
                    </div>
                </Col>
                <Col lg={9} md={8}>
                    <div className="right-col-card">
                        <Tab.Content>
                            <div className="sort-objects">
                                <SearchTagsList go={props.transition.router.stateService.go}/>
                                <SearchResultsList router={props.$transition$.router} startPage={props.$stateParams.page}/>
                            </div>
                        </Tab.Content>

                    </div>
                </Col>
            </Row>
        </Tab.Container>
    </>)
};


export default connect((state) => ({
    categories: state.categories,
    regions: state.regions,
    breadcrumbs: state.crumbs,
    search: state.search,
}))(RegionList);

// export class RegionList1 extends Component {
//     constructor(props) {
//         super(props);
//         this.props = props;
//
//         this.state = {entityList: [], viewId: 0, currKey: null, loading: true};
//         this.dicts = {categories: new EntityTree(), regions: new RegionTree()};
//
//         this.regionId = props.$stateParams.regionId;
//         this.currRegion = null;
//         this.filter = {};
//         this.breadcrumbs = [];
//
//         this.loadContent = this.loadContent.bind(this);
//         this.initFilter = this.initFilter.bind(this);
//         this.onClear = this.onClear.bind(this);
//         this.changeReg = this.changeReg.bind(this);
//     }
//
//     async loadDicts() {
//         const calls = [];
//         calls.push(cubaService.getAllItems('nkn$HeritageObjectCategoryDictionaryEntity'));
//         calls.push(cubaService.getAllItemsByView('common$DictRegion', 'regionWithDistrictView'));
//
//         const data = await Promise.all(calls);
//         const catList = data[0].data;
//         const regionList = data[1].data;
//         this.dicts.categories.setTree(catList);
//         this.dicts.regions.setTree(regionList);
//     }
//
//     // вызывается при активации пункта дерева слева
//     async loadContent(currKey) {
//         // for rendering
//         this.currRegion = this.dicts.regions.findById(currKey);
//         if (this.currRegion == null) return;
//
//         this.subRegs = this.dicts.regions.getChildren(this.currRegion.federalDistrict.id).sort((a, b) => a.sortOrd - b.sortOrd);
//
//         this.initFilter(); // with this.currRegion
//         this.breadcrumbs = [
//             {
//                 title: 'Российская Федерация',
//                 state: 'public.search.list',
//                 params: {}
//             },
//             {
//                 title: this.currRegion.federalDistrict.descr,
//                 state: 'public.district.list',
//                 params: {districtId: this.currRegion.federalDistrict.id}
//             },
//             {
//                 title: this.currRegion.descr,
//                 state: 'public.region.list',
//                 params: {regionId: this.currRegion.id}
//             }
//         ];
//
//
//         this.setState({loading: true, currKey: currKey});
//         // debugger;
//         const srch = filter2search(this.filter);
//         const response = await cubaService.searchNknObjects(srch, 'heritageObjectListView');
//         // debugger;
//         const data = response.data;
//
//         // fileter objects with curr region
//         // const data = response.data.filter ( obj => obj.heritagePlaces && obj.heritagePlaces.length > 0 && obj.heritagePlaces[0].region.id === currKey );
//
//         // add service structures for easy acceess to dictionary values
//         data.forEach(nkn => {
//             nkn.dictTree = new DictionaryTree(nkn.dictionaryLinks);
//
//             // build branch from categories tree
//             nkn.categories = nkn.dictTree.category.map(cat => this.dicts.categories.getBranch(cat.id));
//
//         });
//
//         // сохраняем полученный данные в состояние
//         this.setState({loading: false, entityList: data ? data : []});
//     }
//
//     async componentDidMount() {
//         // ставим на паузу
//         this.setState({loading: true});
//
//         await this.loadDicts();
//         await this.loadContent(this.regionId);
//
//         // this.setState ({ loading: false });
//     }
//
//     // вызывается при активации пункта дерева слева
//     changeReg(currKey) {
//
//         this.props.transition.router.stateService.go('public.region.list', {regionId: currKey});
//     }
//
//
//     onClear_v1() {
//         this.filter = {};
//         this.filterContent(this.filter);
//     }
//
//     onClear() {
//         // debugger;
//         this.props.transition.router.stateService.go('public.search.list');
//     }
//
//     initFilter() {
//         if (this.currRegion == null) return;
//
//         this.filter = {};
//         // replace category only
//         this.filter[sc.SEARCH_REGION_NAME] = [this.currRegion];
//
//         store.dispatch({
//             type: "SET_CRUBMS",
//             crumbs: [
//                 {
//                     title: 'Российская Федерация',
//                     state: 'public.search.list',
//                     params: {}
//                 },
//                 {
//                     title: this.currRegion.federalDistrict.descr,
//                     state: 'public.district.list',
//                     params: {districtId: this.currRegion.federalDistrict.id}
//                 },
//                 {
//                     title: this.currRegion.descr,
//                     state: 'public.region.list',
//                     params: {regionId: this.currRegion.id}
//                 }
//             ]
//         });
//
//     }
//
//     renderLeftColumn() {
//         //console.log ( okr._id );
//         if (this.currRegion == null) return null;
//
//
//     }
//
//     renderRegNotFound() {
//         return <p>''</p>
//     }
//
//     // debugger;
//
//     render() {
//         if (!this.state.loading && this.currRegion == null) return this.renderRegNotFound();
//
//         return (<>
//             <BreadcrumbRegion {...this.props} path={this.breadcrumbs} />
//             <h1>{this.currRegion ? this.currRegion.federalDistrict.shortDescr : ``}</h1>
//             <Tab.Container id="region-object-list" activeKey={this.state.currKey} onSelect={this.changeReg}>
//                 <Row>
//                     <Col lg={3} md={4}>
//                         <div className="left-col-card">
//                             <h2>{t('subjects-of-the-Russian-Federation')}</h2>
//                             <Nav className="flex-column left-nav-card">
//                                 {this.renderLeftColumn()}
//                             </Nav>
//                         </div>
//                     </Col>
//                     <Col lg={9} md={8}>
//                         <div className="right-col-card">
//                             <Tab.Content>
//                                 <div className="sort-objects">
//                                     <SearchTagsList filter={this.filter} onClear={this.onClear} />
//                                     <SearchResultsList entityList={this.state.entityList} loading={this.state.loading} />
//                                 </div>
//                             </Tab.Content>
//
//                         </div>
//                     </Col>
//                 </Row>
//             </Tab.Container>
//         </>)
//     }
// }

// export default RegionList1;
