import React, {Component, useState} from 'react';
import {connect} from "react-redux";
import {Form, FormControl} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import cubaService from 'common/api/cuba.reqs.service';
import {searchSliceActions} from "../../redux/search.reducer";
import SearchParamValueSelection from "./search.param.value.component";
import {makeFilterMultiselect} from '../../common/utils';

import {SEARCH_OPTIONS} from '../../common/constants';
import * as sc from './search.constants.js';
import {store} from "store.config";

import topSearch from "./images/top-search.png";

import './less/search.full.component.less';

if (ENV.debug.root) console.log('search.base.component.js');

const TextSearchBox = (props) => {
    const {t} = useTranslation();

    return (<div className="left-block-search">
            <div className="block-search">
                <Form inline className="form-search">
                    <FormControl type="text" placeholder={t("context-search")} value={props.searchTerm}
                                 onChange={props.onChange}/>
                    <div className="search-lens" variant="light">
                        <img alt='' style={{height: '100%', width: 'auto'}}
                             src={topSearch}/>
                    </div>
                </Form>
            </div>
        </div>
    )
};

// const makeFilter = (params) => {
//     const filter = {};
//     params.forEach( item => {
//         if ( item.dictValue == null ) return;
//
//         const option = SEARCH_OPTIONS[item.optId];
//         if ( !option) return;
//
//         const fNodeName = option.entityName === DICT_REGION_NAME ? sc.SEARCH_REGION_NAME : sc.SEARCH_DICTVAL_NAME;
//         if ( filter[fNodeName] == null ) filter[fNodeName] = [];
//         filter[fNodeName].push (item.dictValue);
//     });
//
//     return filter;
// };
const initAttrs = () => {
    return Array(SEARCH_OPTIONS.length).fill('').map((_, idx) => ({
        dictValue: null,
        valueTitle: '',
        optId: idx,
        multiselect: null,
    }))
}

export const SearchFullPanel = (props) => {
    const [searchAttrs, setSearchAttrs] = useState(initAttrs());
    const [searchTerm, setSearchTerm] = useState('');

    const {t, i18n} = useTranslation()
    const [currLang, setCurrLang] = useState(i18n.language);

    React.useEffect(() => {
        if (currLang === i18n.language) return;
        console.log('Effect in SearchFullPanel with i18n.language', i18n.language)
        setCurrLang(i18n.language);
        setSearchAttrs(initAttrs());
        doSearch();
    }, [i18n.language]);

    const doSearch = (evt) => {
        if (evt != null) {
            evt.preventDefault();
            evt.stopPropagation();
        }

        const f = makeFilterMultiselect(searchAttrs);
        if (searchTerm.length > 0) {
            f[sc.SEARCH_TERM_NAME] = searchTerm;
        }

        props.dispatch(searchSliceActions.setFullFilter(f));
        props.dispatch(searchSliceActions.setPage(0));
        // // setSearchAttrs(initAttrs());
        //
        const stateName = props.router.stateService.current.name;
        const stateParams = props.router.stateService.params;
        stateParams.page = 0;
        console.log('before transitionTo');
        props.router.stateService.transitionTo('public.search.list', stateParams, {
            reload: true,
            inherit: false,
            notify: true
        });
    };

    const onKeyChange = (e, searchableId) => {
        const optId = e.currentTarget.value;

        const currAttr = searchAttrs[searchableId];
        currAttr.dictValue = null;
        currAttr.optId = parseInt(optId);
        setSearchAttrs(searchAttrs => [...searchAttrs]);
    };

    // single value selection
    const onValueChange = (value, searchableId) => {
        const currAttr = searchAttrs[searchableId];
        currAttr.dictValue = value.valueId;
        currAttr.valueTitle = value.valueTitle;

        setSearchAttrs(searchAttrs => [...searchAttrs]);
    };

    const onMultiValueChange = (values, searchableId) => {
        const currAttr = searchAttrs[searchableId];
        // currAttr.dictValue = value.valueId;
        // currAttr.valueTitle = value.valueTitle;
        currAttr.multiselect = values.valueId.length > 0 ? values : null;
        setSearchAttrs(searchAttrs => [...searchAttrs]);
    };

    const onTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (<>
        <div className="advanced-search">
            { /* контекстный поиск */}
            <TextSearchBox searchTerm={searchTerm} onChange={onTermChange}/>
            { /* поиск по критериям старый */}
            {searchAttrs.map((item, idx) => (
                <SearchParamValueSelection
                    key={idx}
                    attrId={idx}
                    optId={item.optId}
                    dictValue={searchAttrs[idx].multiselect?.valueId ? searchAttrs[idx].multiselect.valueId : null}
                    onChange={onMultiValueChange}/>
            ))}


            { /* поиск по критериям старый */}
            {/*{searchAttrs.map((item, idx) => (*/}
            {/*    <div key={`srch1${idx}`} className="block-attribute-value">*/}
            {/*        <SearchParamKeySelection siblings={searchAttrs} onChange={(e) => onKeyChange(e, idx)} orderId={idx} />*/}
            {/*        <SearchParamValueSelection*/}
            {/*            attrId={idx}*/}
            {/*            optId={item.optId}*/}
            {/*            onChange={onValueChange} />*/}
            {/*    </div>*/}
            {/*))}*/}
            <div className="btn-filter" onClick={doSearch}>{t("search")}</div>
            {/*<a href={props.router.stateService.href(props.router.stateService.$current.name, {page: 0})} className="btn-filter" >{t("search")}</a>*/}
        </div>
    </>)

};


// onClick={doSearch}

export default connect(null)(SearchFullPanel);

export class SearchFull1 extends Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {loading: true, searchTerm: ''};

        this.searchables = [
            {id: 0, title: "Критерий не задан", entityName: null, used: false, value: null, afterLoad: this.sortValues},
            {
                id: 1,
                title: "Регион",
                entityName: 'common$DictRegion',
                used: false,
                value: null,
                afterLoad: this.fixRegion,
                filterNode: sc.SEARCH_REGION_NAME
            },
            {
                id: 2,
                title: "Этнос",
                entityName: 'nkn$EthnosDictionaryEntity',
                used: false,
                value: null,
                afterLoad: this.sortValues
            },
            {
                id: 3,
                title: "Конфессия",
                entityName: 'nkn$ReligionDictionaryEntity',
                used: false,
                value: null,
                afterLoad: this.sortValues
            },
            {
                id: 4,
                title: "Язык",
                entityName: 'nkn$LanguageDictionaryEntity',
                used: false,
                value: null,
                afterLoad: this.sortValues
            },
            {
                id: 5,
                title: "Теги",
                entityName: 'nkn$TagDictionaryEntity',
                used: false,
                value: null,
                afterLoad: this.sortValues
            }
        ];
        //this.searchablesExt = ["Категория", "Подкатегория", "Регион", ];

        this.onSearch = this.props.onSearch;
        this.params = [];

        this.dicts = props.dicts;
        this.loadAttrValues = this.loadAttrValues.bind(this);
        this.setAttrValue = this.setAttrValue.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.onTermChange = this.onTermChange.bind(this);
    }

    fixRegion(data) {
        data.forEach(item => item.title = item.shortDescr);
        data.sort((a, b) => a.sortOrd - b.sortOrd);
    }

    sortValues(data) {
        data.sort((a, b) => a.title.localeCompare(b.title, 'ru', {sensitivity: 'base'}));
    }

    onTermChange(e) {
        this.setState({searchTerm: e.target.value});
    }

    renderTextSearchBox() {
        return (<div className="left-block-search">
                <div className="block-search">
                    <Form inline className="form-search">
                        <FormControl type="text" placeholder="Контекстный поиск" className=""
                                     value={this.state.searchTerm} onChange={this.onTermChange}/>
                        <div className="search-lens" variant="light"><img alt='' style={{height: '100%', width: 'auto'}}
                                                                          src={topSearch}/></div>
                    </Form>
                </div>
            </div>
        )
    };

    async loadAttrValues(paramId, selected) {
        const selectedId = selected.currentTarget.value;
        const dictName = this.searchables[selectedId].entityName;
        const placeholder = this.searchables[selectedId].placeholder;

        // free previous searchable
        if (this.params[paramId] != null) this.searchables[this.params[paramId]].used = false;

        // for attr list filtering: change only real attrs, set 0 for def(first) attr
        this.searchables[selectedId].used = selectedId !== 0;

        this.params[paramId] = selectedId;

        if (dictName != null && this.dicts[dictName] == null) {
            const calls = [cubaService.getAllItems(dictName)];
            const data = await Promise.all(calls);

            if (this.searchables[selectedId].afterLoad != null) {
                // copy shortDescr to title
                this.searchables[selectedId].afterLoad(data[0].data);
            }

            this.dicts[dictName] = data[0].data;
            this.dicts[dictName].unshift({title: placeholder});
            // debugger;
            this.searchables[selectedId].value = this.dicts[dictName] ? this.dicts[dictName][0] : null;
        }
        this.setState({loading: false});
    };

    async setAttrValue(paramId, selected) {
        const valueId = selected.currentTarget.value;

        const searchable = this.searchables[this.params[paramId]];
        const dictName = searchable.entityName;

        if (dictName != null && this.dicts[dictName] != null) {
            searchable.value = this.dicts[dictName].find(dictVal => dictVal.id === valueId);
        }
    };

    async doSearch(evt) {
        if (evt != null) {
            evt.preventDefault();
            evt.stopPropagation();
        }

        const filter = this.makeFilter();
        store.dispatch({
            type: "SET_SEARCH",
            filter: filter
        });

        // debugger;
        // console.log ( "before onSearch" );
        if (this.onSearch) this.onSearch(filter);
    };

    // этот метод будет переопределяться для разных поисковых компонентов
    // для всплывашки на главной нужен переход на поисковую выдачу и там уже запрашивать данные
    // для левой колонки поисковой выдачи нужно сразу запрашивать данные
    // doGo () {
    //     const filter = this.makeFilter ();
    //     this.props.onSearch(filter);
    //
    //     console.log ( "SearchFull doGo");
    // }

    makeFilter() {
        const filter = {};
        for (let key in this.params) {
            const searchable = this.searchables[this.params[key]];
            // console.log ( searchable.value.title );

            const fNodeName = searchable.filterNode ? searchable.filterNode : sc.SEARCH_DICTVAL_NAME;
            if (filter[fNodeName] == null) filter[fNodeName] = [];
            filter[fNodeName].push(searchable.value);
        }
        if (this.state.searchTerm != null && this.state.searchTerm.length > 0) {
            filter[sc.SEARCH_TERM_NAME] = this.state.searchTerm;
        }
        return filter;
    }

    renderParamSelection(paramId) {

        const dictName = this.params && this.params[paramId] ? this.searchables[this.params[paramId]].entityName : null;
        const dictVals = dictName && this.dicts[dictName] ? this.dicts[dictName] : [];

        return (<div className="block-attribute-value">
            <div className="input-advanced-search">
                <Form.Group>
                    <Form.Control as="select" defaultValue="0" custom
                                  onChange={(evt) => this.loadAttrValues(paramId, evt)}>
                        {this.searchables.map(attr => (<option key={`srchAttr${attr.id}`} value={attr.id}
                                                               disabled={attr.used}>{attr.title}</option>))}
                    </Form.Control>
                </Form.Group>
            </div>
            <div className="input-advanced-search">
                <Form.Group>
                    <Form.Control as="select" defaultValue="0" custom
                                  onChange={(evt) => this.setAttrValue(paramId, evt)}>
                        {dictVals.map(val => (<option key={val.id} value={val.id}>{val.title}</option>))}
                    </Form.Control>
                </Form.Group>
            </div>
        </div>)
    };

    render() {
        return (<React.Fragment>

            <div className="advanced-search">
                <h2>Начать новый поиск</h2>
                {this.renderParamSelection(0)}
                {this.renderParamSelection(1)}
                {this.renderParamSelection(2)}
                {this.renderTextSearchBox()}
                <div className="btn-filter" onClick={this.doSearch}>Найти</div>
            </div>

        </React.Fragment>)
    }
}

/*
                <UISref to={'public.nkn.card'} params={{filter:this.makeFilter ()}} ><div className="btn-filter" onClick={this.doSearch}>Найти</div></UISref>
*/
// export default SearchFull1;


/*<label className="container-checkbox">Искать по полному описанию
                        <input type="checkbox"/>
                        <span className="checkmark" />
                    </label>*/
