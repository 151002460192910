import React from 'react';
import i18next from "i18next";
import {makeRequest} from './cuba.base.service';

const getAllItems = (entityName) => {
    return makeRequest(ENV.apiFrontEnd + `/entities/${entityName}`);
};

const getItemById = (entityName, id, viewName ) => {
    const url = ENV.apiFrontEnd + `/entities/${entityName}/${id}`;
    return makeRequest( url, 'GET', [], null, viewName);
};

const getAllItemsByView = (entityName, viewName) => {
    return makeRequest(ENV.apiFrontEnd + `/entities/${entityName}`, 'GET', [], null, viewName);
};

const getAllItemsByParams = (entityName, params) => {
    // console.log ( 'lang = ', i18next.language);
    const localizedParams = Object.assign( {}, params, i18next.language === 'en' ? { locale: 'EN' } : {} );
    return makeRequest(ENV.apiFrontEnd + `/entities/${entityName}`, 'GET', [], null, localizedParams );
};

const getItemsByFilter = (entityName, filter, viewName ) => {
    return makeRequest(ENV.apiFrontEnd + `/entities/${entityName}/search`, 'POST', null, filter, viewName );
};

const saveItem = (entityName, body) => {
    return makeRequest(ENV.apiFrontEnd + `/entities/${entityName}`, 'POST', [], JSON.stringify(body));
};

const dellAllItems = (entityName, items) => {
    const calls = items.map(i => makeRequest(ENV.apiFrontEnd + `/entities/${entityName}/${i}`, 'DELETE'));
    return Promise.all(calls);
};

const searchNknObjects = ( filter, viewName ) => {
    // console.log ( 'lang = ', i18next.language);
    const localizedFilter = Object.assign( {}, filter, i18next.language === 'en' ? { locale: 'EN' } : {} );
    // debugger;
    return makeRequest(ENV.apiFrontEndExt + `/nkn/heritage-objects/search`, 'POST', [], JSON.stringify(localizedFilter), viewName );
};

const getNewNknObjects = ( filter ) => {
    return makeRequest(ENV.apiFrontEnd + `/entities/nkn$HeritageObject/search`, 'POST', [], JSON.stringify(filter) );

};

const getStatByRegion = ( id ) => {
    return makeRequest(ENV.apiFrontEndExt + `/nkn/heritage-objects/stat/federal-districts/${id}/by-region` );
};

const getStatCatsByRegion = ( id ) => {
    return makeRequest(ENV.apiFrontEndExt + `/nkn/heritage-objects/stat/regions/${id}/by-category` );
};

const getStatTotal = ( ) => {
    return makeRequest(ENV.apiFrontEndExt + `/nkn/heritage-objects/stat/total` );
};

const fetchStreamUrl = async (id) => {
    const url = ENV.apiFrontEndExt + `/common/s3/${id}/presigned-url`;
    const response = await makeRequest(url);
    return response.data;
};

const getRandomNknObjectId = ( lang ) => {
    return makeRequest(ENV.apiFrontEndExt + `/nkn/heritage-objects/random-id`, 'GET', [], null, {hasTranslation : lang === 'en'} );
};


export default {
    makeRequest,
    getAllItems,
    getAllItemsByView,
    getAllItemsByParams,
    getItemById,
    getItemsByFilter,
    saveItem,
    dellAllItems,
    searchNknObjects,
    getNewNknObjects,
    getStatByRegion,
    getStatCatsByRegion,
    getStatTotal,
    fetchStreamUrl,
    getRandomNknObjectId,
};
