import React, {Component} from 'react';
import {Row, Col, Nav, Tab} from 'react-bootstrap';
import i18next from "i18next";
import {useTranslation, withTranslation} from 'react-i18next';

import EntityTree from "common/EntityTree";
import RegionTree from 'common/RegionTree';
import cubaService from "common/api/cuba.reqs.service";

import SearchTagsList from "modules/search/search.tag.list.component";
import SearchResultsList from 'modules/search/search.results.list.component';
import BreadcrumbRegion from 'modules/region/breadcrumb.region.component';
import BreadcrumbNkn from "../nkn/breadcrumb.nkn.component";

import {store} from "store.config";
import {connect} from "react-redux";
import {searchSliceActions} from "../../redux/search.reducer";

import {getValueByLocale} from 'common/utils';

import * as sc from "modules/search/search.constants";

import './less/region.list.component.less';

if (ENV.debug.root) console.log('region.list.component.js');

const filter2search = (filter) => {
    const searchBody = {[sc.SEARCH_DICTVAL_NAME]: []};
    const {t} = useTranslation();
    for (let filterType in filter) {
        if (!filter.hasOwnProperty(filterType)) continue;

        switch (filterType) {
            case sc.SEARCH_REGION_NAME:
                // TODO: use all region items in search
                searchBody[filterType] = filter[filterType][0].id;
                break;
            case sc.SEARCH_DISTRICT_NAME:
                // TODO: use all category items in search
                searchBody[filterType] = filter[filterType][0].id;
                break;
            case sc.SEARCH_DICTVAL_NAME:
                filter[filterType].forEach(i => searchBody[filterType].push(i.id));
                break;
            case sc.SEARCH_CATEGORY_NAME:
                searchBody[sc.SEARCH_DICTVAL_NAME].push(filter[filterType].id);
                break;
            case sc.SEARCH_TERM_NAME:
                searchBody[filterType] = filter[filterType];
        }
    }

    return searchBody;
};

export class DistrictList extends Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {entityList: [], viewId: 0, currKey: null, loading: true};
        this.dicts = {categories: new EntityTree(), regions: new RegionTree()};

        this.districtId = props.$stateParams.districtId;
        this.currDistrict = null;
        this.filter = {};
        this.breadcrumbs = [];

        this.loadContent = this.loadContent.bind(this);
        this.initFilter = this.initFilter.bind(this);
        this.onClear = this.onClear.bind(this);
        this.changeReg = this.changeReg.bind(this);
    }

    async loadDicts() {
        const calls = [];
        calls.push(cubaService.getAllItems('nkn$HeritageObjectCategoryDictionaryEntity'));
        calls.push(cubaService.getAllItemsByView('common$DictRegion', 'regionWithDistrictView'));

        const data = await Promise.all(calls);
        const catList = data[0].data;
        const regionList = data[1].data;
        this.dicts.categories.setTree(catList);
        this.dicts.regions.setTree(regionList);
    }

    // вызывается при активации пункта дерева слева
    async loadContent(currKey) {
        // for rendering
        const roots = this.dicts.regions.getRootList();
        this.currDistrict = roots.find(root => root.id === currKey);
        if (this.currDistrict == null) this.currDistrict = roots[0];
        this.subRegs = this.dicts.regions.getChildren(this.currDistrict.id).sort((a, b) => a.sortOrd - b.sortOrd);

        this.initFilter(); // with this.currRegion
        this.breadcrumbs = [
            {
                title: this.props.t('rf'),
                state: 'public.country.list',
                params: {},
            },
            {
                title: this.currDistrict.descr,
                state: 'public.district.list',
                params: {districtId: this.currDistrict.id, page: 0}
            }
        ];


        this.setState({loading: true, currKey: currKey});
        this.props.dispatch(searchSliceActions.setSingleDistrict([{
            id: this.currDistrict.id,
            title: this.currDistrict.shortDescr
        }]));
        if (this.props.search.page !== undefined) {
            this.props.dispatch(searchSliceActions.setPage(this.props.search.page));
        }

        // const srch = filter2search ( this.filter );
        // const response = await cubaService.searchNknObjects( srch );
        // const data = response.data;
        //
        // // fileter objects with curr region
        // // const data = response.data.filter ( obj => obj.heritagePlaces && obj.heritagePlaces.length > 0 && obj.heritagePlaces[0].region.id === currKey );
        //
        // // add service structures for easy acceess to dictionary values
        // data.forEach( nkn => {
        //     nkn.dictTree = new DictionaryTree(nkn.dictionaryLinks);
        //
        //     // build branch from categories tree
        //     nkn.categories = nkn.dictTree.category.map ( cat => this.dicts.categories.getBranch( cat.id ) );
        //
        // });

        // сохраняем полученный данные в состояние
        this.setState({loading: false}); // , entityList: data ? data: []
    }

    async componentDidMount() {
        // ставим на паузу
        this.setState({loading: true});

        await this.loadDicts();
        await this.loadContent(this.districtId);

        // this.setState ({ loading: false });
    }

    onClear() {
        this.initFilter();
    }

    initFilter() {
        if (this.currDistrict == null) return;

        this.filter = {};
        // replace category only
        this.filter[sc.SEARCH_DISTRICT_NAME] = [this.currDistrict];

        store.dispatch({
            type: "SET_CRUBMS",
            crumbs: [
                {
                    title: this.props.t('rf'),
                    state: 'public.search.list',
                    params: {}
                },
                {
                    title: this.currDistrict.descr,
                    state: 'public.district.list',
                    params: {districtId: this.currDistrict.id, page: 0}
                }
            ]
        });

    }

    // вызывается при активации пункта дерева слева
    changeReg(currKey) {
        this.props.transition.router.stateService.go('public.region.list', {regionId: currKey, page: 0});
    }

    renderLeftColumn() {
        // console.log ( okr._id );
        const lang = i18next.language;
        if (this.currDistrict == null) return null;

        // UIRouter not pass page when stay in same path
        const dropPage = () => this.props.dispatch(searchSliceActions.setPage(0));

        return this.subRegs.map(reg => (
            <Nav.Item onClick={dropPage} key={`rlni${this.currDistrict.id}${reg.id}`}>
                <Nav.Link eventKey={`${reg.id}`}>{getValueByLocale(reg, 'shortDescr', lang)}</Nav.Link>
            </Nav.Item>
        ))
    }

    renderRegNotFound() {
        return <p>''</p>
    }

    render() {
        if (!this.state.loading && this.currDistrict == null) return this.renderRegNotFound();
        const dropPage = () => {
            // debugger
            this.props.dispatch(searchSliceActions.setPage(0));
        }

        return (<>
            <BreadcrumbNkn {...this.props} path={this.breadcrumbs} onClick={dropPage}/>
            <h1>{this.currDistrict ? this.currDistrict.descr : ``}</h1>
            <Tab.Container id="region-object-list" activeKey={this.state.currKey} onSelect={this.changeReg}>
                <Row>
                    <Col lg={3} md={4}>
                        <div className="left-col-card">
                            <h2>{this.props.t('subjects-of-the-Russian-Federation')}</h2>
                            <Nav className="flex-column left-nav-card">
                                {this.renderLeftColumn()}
                            </Nav>
                        </div>
                    </Col>
                    <Col lg={9} md={8}>
                        <div className="right-col-card">
                            <Tab.Content>
                                <div className="sort-objects">
                                    <SearchTagsList go={this.props.transition.router.stateService.go}/>
                                    <SearchResultsList router={this.props.$transition$.router} startPage={this.props.$stateParams.page} />
                                    {/*<SearchTagsList filter={this.filter} onClear={this.onClear} />*/}
                                    {/*<SearchResultsList entityList={this.state.entityList} loading={this.state.loading} />*/}
                                </div>
                            </Tab.Content>
                        </div>
                    </Col>
                </Row>
            </Tab.Container>
        </>)
    }
}

const DistrictListTranslated = withTranslation()(DistrictList);
export default connect((state) => (state))(DistrictListTranslated);

// export default DistrictList;
