import React, {useEffect, useState} from 'react';
import {Form, Spinner} from 'react-bootstrap';
import {connect} from "react-redux";
import {useTranslation} from 'react-i18next';
import {store} from "store.config";
import {getValueByLocale} from 'common/utils'
import {DICT_REGION_NAME, DICT_CATEGOY_NAME} from "common/constants";
import {fetchCustomDict, fetchRegions} from 'redux/dict.reducer';
import * as s from "../../redux/load.status.constants";

import {SEARCH_OPTIONS} from "common/constants";
import {prop} from "@uirouter/react";

if (ENV.debug.root) console.log('search.param.value.component.js');

// {dicts, categories, dictName, afterLoad, onChange}
export const SearchParamValueSelection = (props) => {
    const [dictVals, setDictVals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currValue, setCurrValue] = useState(0)
    const {t, i18n} = useTranslation()

    const entityName = SEARCH_OPTIONS [props.optId] ? SEARCH_OPTIONS [props.optId].entityName : null;
    const viewName = SEARCH_OPTIONS [props.optId] ? SEARCH_OPTIONS [props.optId].viewName : null;
    const placeholder = SEARCH_OPTIONS [props.optId] ? t(SEARCH_OPTIONS [props.optId].placeholder) : '';

    useEffect(() => {
        // console.log(`${props.attrId} - ${props.entityName}`);
        if (entityName == null) {
            setDictVals([]);
            setLoading(false);
            return;
        }

        switch ( entityName ) {
            case DICT_REGION_NAME:
                if (props.regions.length > 0) {
                    setDictVals(props.regions);
                    setLoading(false);
                    return;
                }
            case DICT_CATEGOY_NAME:
                if (props.categories.length > 0) {
                    setDictVals(props.categories);
                    setLoading(false);
                    return;
                }
            default:
                if (props.dicts[entityName]) {
                    setDictVals(props.dicts[entityName]);
                    setLoading(false);
                    return;
                }
        }

        setLoading(true);
        // if (props.status === s.EMPTY || props.status === s.SUCCESS) {
            switch (entityName) {
                case DICT_REGION_NAME:
                    store.dispatch(fetchRegions());
                    break;
                // case DICT_AUTHOR_NAME: return sortValues(fixAuthor(response.data, lang), lang);
                default:
                    store.dispatch(fetchCustomDict({dictName: entityName, viewName, lang: i18n.language}));
            }
        // }
    }, [entityName]);

    useEffect(() => {
        if (props.dicts [entityName] == null) return;

        setDictVals(props.dicts [entityName]);

        // props.onChange ( getResult(props.dicts [entityName][0].id), props.attrId );
        setLoading(false);

    }, [props.dicts[entityName]]);

    useEffect(() => {
        if (props.regions == null) return;

        if (entityName !== DICT_REGION_NAME) return;
        setDictVals(props.regions);
        setLoading(false);

    }, [props.regions]);

    useEffect(() => {
        setCurrValue(props.dictValue ? props.dictValue : 0);
    }, [props.dictValue]);

    const getResult = (valueId) => {
        if ( valueId.length === 0 ) return ({valueId, valueTitle: ''});

        let valueItem;
        switch ( entityName ) {
            case DICT_REGION_NAME:
                valueItem = props.regions.find(item => item.id === valueId);
                break;
            case DICT_CATEGOY_NAME:
                valueItem = props.categories.find(item => item.id === valueId);
                break;
            default:
                valueItem = props.dicts[entityName].find(item => item.id === valueId);
        }

        return {
            valueId,
            valueTitle: getValueByLocale(valueItem, 'title', i18n.language)
        };
    };

    const onChange = (e /* selectedOption */) => {
        const valueId = e.currentTarget.value;
        // props.onChange ( selectedOption.map( opt => getResult(opt.value)), props.attrId );
        props.onChange(getResult(valueId), props.attrId);
        // setCurrValue(valueId);
    };

    if (loading) return (<Spinner animation="border" variant="primary"/>);

    return (
        <div className="input-advanced-search">
            <Form.Group>
                <Form.Control as="select" value={currValue} custom onChange={onChange} placeholder={placeholder}>
                    {dictVals.length && <option key={0} value={''}>{placeholder} </option>}
                    {dictVals.length ?
                        dictVals
                        .filter (val => getValueByLocale(val, 'title', i18n.language).length )
                        .map(val =>
                            (<option key={val.id}
                                     value={val.id}>{getValueByLocale(val, 'title', i18n.language)}
                            </option>))
                        : (<option key={0} value={null}>{t('search-options.no-values')}</option>)
                    }
                </Form.Control>
            </Form.Group>
            {/*<Select*/}
            {/*    isClearable={true}*/}
            {/*    isMulti*/}
            {/*    options={options}*/}
            {/*    onChange={onChange}*/}
            {/*    placeholder={placeholder}*/}
            {/*/>*/}
        </div>
    )
};

const mapState = state => ({
    regions: state.regions.regions,
    dicts: state.dicts.dicts,
    categories: state.categories.categories,
});
export default connect(mapState)(SearchParamValueSelection);
