import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Nav, Tab, Spinner} from 'react-bootstrap';

import PaneAbout from "./pane.about.component";
import PanePhoto from "./pane.photo.component";
import PaneAudio from "./pane.audio.component";
import PaneVideo from "./pane.video.component";
import PaneMap from "./pane.map.component";

import './less/card.full.component.less';

import objAbout from "modules/root/images/objAbout.svg";
import objAudio from "modules/root/images/objAudio.svg";
import objVideo from "modules/root/images/objVideo.svg";
import objPhoto from "modules/root/images/objPhoto.svg";
import objMap from "modules/root/images/objMap.svg";

if (ENV.debug.root) console.log('card.full.component.js');

import cubaService from "common/api/cuba.reqs.service";

import BreadcrumbNkn from "./breadcrumb.nkn.component";

import EntityTree from "common/EntityTree";
import RegionTree from "common/RegionTree";
import DictionaryTree from "common/DictionaryTree";

import {removeHtmlTags, useComponentWillMount} from 'common/utils';
import * as s from "redux/load.status.constants.js";

import {EMPTY_PHOTO_URL, PREVIEW_LENGTH, PANE_NAME} from 'common/constants';

import {getMainPhotoUrl, getResearcher} from "common/nkn.helpers";
import {store} from "../../store.config";
import {fetchCategories, fetchCustomDict, fetchRegions} from "../../redux/dict.reducer";
import { useTranslation } from 'react-i18next';
import { getValueByLocale } from 'common/utils'
import {searchSliceActions} from "../../redux/search.reducer";

const fetchNkn = async (nknId) => {
    console.log(`fetchNkn ${nknId}`);
    const response = await cubaService.getItemById('nkn$HeritageObject', nknId, 'heritageObjectCardView');
    return response != null && response.data != null && !Array.isArray(response.data) ? response.data : null;
};

const getPaneName = pane => {
    switch (pane) {
        case 'about':
            return PANE_NAME.ABOUT;
        case 'photo':
            return PANE_NAME.PHOTO;
        case 'audio':
            return PANE_NAME.AUDIO;
        case 'video':
            return PANE_NAME.VIDEO;
        case 'map':
            return PANE_NAME.MAP;
        default:
            return PANE_NAME.ABOUT;
    }
};

const calcBreadcrumbs = (breadcrumbs, dicts, nkn, lang) => {

    if (breadcrumbs != null && breadcrumbs.length > 0) return breadcrumbs;

    const resBC = [];


    // for rendering Breadcrumbs: setup root and current nodes
    let categoryId = null;
    if (nkn.dictTree.category != null && nkn.dictTree.category.length > 0) {
        categoryId = nkn.dictTree.category[0].id;
    }

    let currCategory = dicts.categories.findById(categoryId);
    let rootCategory = null;
    let subCats = [];

    if (currCategory && currCategory.parentId) {
        // if child category selected
        rootCategory = dicts.categories.findById(currCategory.parentId);
    } else {
        // if root category selected
        rootCategory = currCategory;
    }

    if (rootCategory && currCategory) {
        subCats = dicts.categories.getChildren(rootCategory.id);
        // try to set first subcategory
        if (currCategory.parentId == null && subCats.length > 0) {
            currCategory = subCats[0];
        }
    }

    // make breadcrumbs path
    // this.breadcrumbs = [
    //     {
    //         title: 'Российская Федерация',
    //         state: 'public.main',
    //         params: {} }
    //     ,
    //     {
    //         title: this.currRegion.federalDistrict.descr,
    //         state: 'public.main',
    //         params: {}
    //     },
    //     {
    //         title: this.currRegion.descr,
    //         state: 'public.region.list',
    //         params: {regionId: this.currRegion .id}
    //     }
    // ]
    if (rootCategory) resBC.push(
        {
            title: getValueByLocale (rootCategory, 'title', lang),
            state: 'public.category.list',
            params: {categoryId: rootCategory.id, page: 0}
        });

    if (subCats != null && subCats.length > 0 && currCategory) resBC.push(
        {
            title: getValueByLocale (currCategory, 'title', lang),
            state: 'public.category.list',
            params: {categoryId: currCategory.id, page: 0}
        });
    return resBC;
};

export const NknCardFull = (props) => {
    const {pane, breadcrumbs, nknId} = props.$stateParams; // also has nknId
    const { t, i18n } = useTranslation()

    const [loading, setLoading] = useState(true);
    const [activePane, setActivePane] = useState(getPaneName(pane));
    const [nkn, setNkn] = useState(null);
    const [dicts, setDicts] = useState(null);
    const [preview, setPreview] = useState(EMPTY_PHOTO_URL);
    const [authors, setAuthors] = useState(null);
    const [currBreadcrumbs, setCurrBreadcrumbs] = useState([]);
    const [song, setSong] = useState(null)
    const [video, setVideo] = useState(null)
    const cardRootRef = React.useRef(null);

    // step 1
    useComponentWillMount(() => {
        console.log("useEffect for dicts");
        if (props.categories.status === s.EMPTY) store.dispatch(fetchCategories());
        if (props.regions.status === s.EMPTY) store.dispatch(fetchRegions());

        store.dispatch(fetchCustomDict({dictName: 'nkn$EthnosDictionaryEntity', viewName: 'ethnosDictionaryEntityView', lang: i18n.language}));
        store.dispatch(fetchCustomDict({dictName: 'nkn$LanguageDictionaryEntity', viewName: 'languageDictionaryEntityView', lang: i18n.language}));
        store.dispatch(fetchCustomDict({dictName: 'nkn$ReligionDictionaryEntity', viewName: 'religionDictionaryEntityView', lang: i18n.language}));
    });

    // step 2
    useEffect(() => {
        // console.log ( "props.categories = ", props.categories.status  );
        // console.log ( "props.regions = ", props.regions.status  );
        if (props.categories.status === s.EMPTY ||
            props.regions.status === s.EMPTY ||
            props.dicts.counter < 3 ) return;

        const dicts = {categories: new EntityTree(), regions: new RegionTree()}
        dicts.categories.setTree(props.categories.categories);
        dicts.regions.setTree(props.regions.regions);
        setDicts(dicts);

    }, [props.regions.status, props.categories.status, props.dicts.counter]);

    // step 3
    useEffect(() => {
        console.log("useEffect for nkn");
        if (nknId == null) return;
        if (dicts == null) return;

        fetchNkn(nknId).then(nkn => {
            getMainPhotoUrl(nkn).then(url => setPreview(url));
            getResearcher(nkn).then( authors => setAuthors( authors ));

            processNkn(nkn);

            setNkn(nkn);
            const songs = nkn.heritageMedia ? nkn.heritageMedia.filter(hm => hm.mediaType === "AUDIO" && !hm.isOnMap) : [];
            setSong(songs)
            const videos = nkn.heritageMedia ? nkn.heritageMedia.filter(hm => hm.mediaType === "VIDEO" && !hm.isOnMap) : [];
            setVideo(videos)
            setCurrBreadcrumbs(calcBreadcrumbs(props.breadcrumbs, dicts, nkn, i18n.language));
            setLoading(false);

            if ( cardRootRef != null && cardRootRef.current !== null &&
                cardRootRef.current.parentNode!= null && cardRootRef.current.parentNode.parentNode != null ) {
                cardRootRef.current.parentNode.parentNode.scrollIntoView();
            }
        });
    }, [dicts, i18n.language]);

    useEffect(() => {
        if(i18n.language !== "ru"){
            setActivePane(getPaneName(pane))
        }
    },[i18n.language])

    const cleanupFullDescription = ( fullDescription ) => {
        if (fullDescription == null) return;
        const cutPos = fullDescription.indexOf('-----');
        return cutPos >= 0 ? fullDescription.replace(/-----/g, '') : fullDescription;
    }

    const makeCutDescription = ( fullDescription ) => {
        if (fullDescription == null) return null;
        const cutPos = fullDescription.indexOf('-----');
        return cutPos >= 0
            ? fullDescription.substring(0, cutPos)
            : removeHtmlTags(fullDescription.substring(0, PREVIEW_LENGTH))
    }

    const processNkn = (nkn) => {
        // used by paneHeader
        if (!nkn) return;

        nkn.dictionaryLinks.forEach( dl => {
            if ( props.dicts.dicts[dl.dictionaryEntity._entityName] == null ) return;

            const item = props.dicts.dicts[dl.dictionaryEntity._entityName].find ( dli => dli.id === dl.dictionaryEntity.id);
            // TODO: !!! нарушение целостности для b4dc5eb1-adc4-7378-4202-f15dc05e60bb
            /*
            dictionaryEntity :
                id :  "516573b6-045b-cb34-0424-ad5dff050ff7"
                title : "Русский "
                _entityName : "nkn$LanguageDictionaryEntity"
                _instanceName: "Русский "
            */

            if (  item ) dl.dictionaryEntity = item;
        })

        nkn.dictTree = new DictionaryTree(nkn.dictionaryLinks);
        // debugger
        // nkn.dictTree = new DictionaryTree(localizedDL);

        // build branch from categories tree
        // used by paneHeader
        nkn.categories = nkn.dictTree.category.map(cat => dicts.categories.getBranch(cat.id));

        nkn.cutDescription = makeCutDescription(nkn.fullDescription);
        nkn.fullDescription = cleanupFullDescription(nkn.fullDescription);

        if ( Array.isArray(nkn.localizationFields) &&
            nkn.localizationFields.length > 0 ) {
            nkn.localizationFields[0]
                .cutDescription = makeCutDescription(nkn.localizationFields[0].fullDescription)
            nkn.localizationFields[0]
                .fullDescription = cleanupFullDescription(nkn.localizationFields[0].fullDescription)
        }

        if ( Array.isArray(nkn.heritageMedia) ) {
            nkn.heritageMedia.forEach( hm => {
                if ( !hm.localizationHeritageMedia ) return;
                hm.localizationFields = hm.localizationHeritageMedia;
                delete hm.localizationHeritageMedia;
            })
        }

        /*
                const wikiPos = nkn.fullDescription.indexOf('<a href');
                if ( wikiPos >= 0 ) {
                    nkn.fullDescription = nkn.fullDescription.replace('<a href', '<a onClick=href');
                    nkn.fullDescription = nkn.fullDescription.replace('<\/a>', '<\/WikiPopup>');
                }
          */
    };

    if (loading) return <Spinner animation="border" variant="primary"/>;
    if (nkn == null) return (<NknNotExits path={breadcrumbs}/>);

    return (<div ref={cardRootRef}>
        <BreadcrumbNkn path={currBreadcrumbs}/>
        <Tab.Container activeKey={activePane} onSelect={setActivePane}>
            <Row>
                <Col lg={3} md={4}>
                    <div className="left-col-card">
                        <img src={preview} className="imgObg"/>
                        <Nav className="left-nav-card test">
                            <Nav.Item>
                                <Nav.Link eventKey="about"><img src={objAbout}/> <span>{t("description")}</span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="photo"><img src={objPhoto}/> <span>{t("photo")}</span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                {song.length ? <Nav.Link eventKey="audio"><img src={objAudio}/> <span>{t("audio")}</span></Nav.Link> : null}
                                {/* <Nav.Link eventKey="audio"><img src={objAudio}/> <span>{t("audio")}</span></Nav.Link> */}
                            </Nav.Item>
                            <Nav.Item>
                                {video.length ? <Nav.Link eventKey="video"><img src={objVideo}/> <span>{t("video")}</span></Nav.Link> : null}
                                {/* <Nav.Link eventKey="video"><img src={objVideo}/> <span>{t("video")}</span></Nav.Link> */}
                            </Nav.Item>
                            {i18n.language === 'ru' && <Nav.Item>
                                <Nav.Link eventKey="map"><img src={objMap}/> <span>{t("on-the-map")}</span></Nav.Link>
                            </Nav.Item>}
                        </Nav>
                    </div>
                </Col>
                <Col lg={9} md={8}>
                    <div className="right-col-card">
                        <Tab.Content>
                            <Tab.Pane eventKey="about">
                                <PaneAbout nkn={nkn} currActive={activePane} authors={authors}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="photo">
                                <PanePhoto nkn={nkn} currActive={activePane}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="audio">
                                <PaneAudio nkn={nkn} currActive={activePane}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="video">
                                <PaneVideo nkn={nkn} currActive={activePane}/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="map">
                                <PaneMap nkn={nkn} currActive={activePane}/>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Col>
            </Row>
        </Tab.Container>
        </div>)
    // };
};

const NknNotExits = (props) => (<>
    <BreadcrumbNkn path={props.breadcrumbs}/>
    <Row>
        <Col sm={12}>
            <h2>{t("object-not-found")}</h2>
        </Col>
    </Row>
</>);


export default connect((state) => ({
    categories: state.categories,
    regions: state.regions,
    search: state.search,
    dicts: state.dicts,
    breadcrumbs: state.crumbs,
}))(NknCardFull);

