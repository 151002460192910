import React, {useEffect, useState} from 'react';
import {UISref} from "@uirouter/react";
import { Table, Spinner } from 'react-bootstrap';
import cubaService from "common/api/cuba.reqs.service";

import './less/map.region.component.less';
import {searchSliceActions} from "../../redux/search.reducer";
import { getValueByLocale } from 'common/utils';
import {useTranslation} from 'react-i18next';

if (ENV.debug.root) console.log('map.region.stattable.component.js');

const fetchStat = async (regionId) => {
    console.log(`fetchStat ${regionId}`);
    const response = await cubaService.getStatCatsByRegion( regionId );
    return response != null && response.data != null ? response.data : null;
};

export const StatTable = (props) => {
    const [loading, setLoading] = useState(true);
    const [catStat, setCatStat] = useState([]);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        // console.log ( "useEffect for stat data" );
        fetchStat(props.regionId).then(statData => {
/*
        category:
            id: "f9ae9209-26e1-a480-7154-4fb17db9cbde"
            parentId: "b02c1d6d-0bef-e09d-5ed9-bfe26e574e13"
            sortOrder: 1
            title: "Словесные жанры"
            version: 2
            _entityName: "nkn$HeritageObjectCategoryDictionaryEntity"
        count: 0
 */
            // console.log ( statData );
            const stats = statData
                .filter (item => !item.category.parentId )
                .reduce ( (accu, item) => {
                    accu[item.category.id] = item.count;
                    return accu;
            }, [] );

            setCatStat ( stats );
            setLoading(false)
        });
    }, []);

    // <UISref to={'public.category.list'} params={{categoryId: item.id}}></UISref>
	return (
    <Table responsive className="table-region-statistic">
        <thead>
        <tr>
            <th width="14.2%">&nbsp;</th>
            {props.catList.map((item, index) => (
                <th key={index}><a onClick={() => props.onCategoryClick(item)}>{getValueByLocale(item,'title', i18n.language) }</a></th>
            ))}
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>{t('region-page-stat-title')}</td>
            {props.catList.map((cat, index) => (
                <td className="quantity" key={index}>{loading ? <Spinner animation="border" variant="primary"/> : catStat[cat.id] != null ? catStat[cat.id] : 0  }</td>
            ))}
        </tr>
        </tbody>
    </Table>);
};

export default StatTable;
