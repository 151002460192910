import EntityTree from 'common/EntityTree';

export default class RegionTree extends EntityTree {
	constructor( data ) {
		super ( data );
    }

    setTree ( data ) {
    	this.treeList = Array.isArray(data) ? this.parseRegionList ( data ) : [];
    }

    parseRegionList ( data ) {

    	const roots = data.reduce ( (accu, item) => {
    	    // Байконур
    		if ( item.federalDistrict == null ) return accu;

    		if ( accu.findIndex ( root => root.shortDescr === item.federalDistrict.shortDescr ) == -1 ) {
    			accu.push ( item.federalDistrict );
    		}
    		return accu;
    	}, [] );

    	// if nothing found use plain list
    	if ( roots.length === 0 ) return data;
    	roots.sort ( (a,b) => a.sortOrd - b.sortOrd );

		data.forEach ( item => {
			if ( !item.federalDistrict ) return;
			if ( item.parentId !== null && item.parentId !== undefined ) return;
			item.parentId = item.federalDistrict.id;
		});
    	return [ ...roots, ...data ];
    }

}

