import React, { useEffect, useState } from 'react';

// компоненты bootstrap
import {Container, Row, Col, Tab, Nav} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Pagination from 'react-bootstrap/Pagination';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

// компонент для организации перехода по ссылке
import {UISref} from '@uirouter/react';

// компоненты для получения данных от backend
import cubaService from 'common/api/cuba.reqs.service';
import QueryBuilder from 'common/api/query-builder.service.js';
import EntityTree from 'common/EntityTree';

// предлагаю отделять стили для страницы-контейнера и для отдельных карточек в списке
import './less/contacts.page.component.less';

import PTV from "./images/purtova.jpg";
import RMV from "./images/rusanova.png";
import DVE from "./images/dobrovolskaya.jpg";
import KNE from "./images/kotelnikova.jpg";
import { useTranslation } from 'react-i18next';

if ( ENV.debug.root ) console.log ( 'contacts.page.component.js' );

// Классы всегда именуются с большой буквы
export const ContactsPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [currTypeKey, setCurrTypeKey] = useState(null);
  const [docTypesTree, setDocTypesTree] = useState(null);
  const { t } = useTranslation();
  const texts = {
    title: t("contacts.title"),
    emptyNode: t("list-filling"),
    emptyList: t("filling-out")
};

    // чтения данных при старте компонента
    // сначала читаем типы, потом выбераем первый по списку тип,
    // потом грузим данные как если бы этот тип выбрал пользователь
	async function loadDicts() {
        // ставим на паузу
        setLoading(true);

        // здесь читаем только титы
        const response = await cubaService.getAllItems('nkn$DocumentTypeDictionaryEntity')

        // сохраняем данные
        const data = await response.data;

        const docTypesTree = new EntityTree(data);
        setDocTypesTree(docTypesTree);

        // вычисляем id первого типа документов
        const firstChild = docTypesTree.getFirstChild();

        // сохраняем полученный данные в состояние
        const currTypeKey = firstChild ? firstChild.id : null
        setCurrTypeKey(currTypeKey);
        setLoading(false);
	}

	// вызывается при активации пункта дерева слева
	async function loadContent( currTypeKey ) {
        // ставим на паузу
        setLoading(true);

        const q = new QueryBuilder ( { "docType" : currTypeKey } );
        const response = await cubaService.getItemsByFilter ( 'nkn$Document', q.toString() );
        const data = response.data;

        // сохраняем полученный данные в состояние
        const entityList = data ? data : [];
        setEntityList(entityList);
        setLoading(false);
    }

  useEffect(() => {
    loadDicts();
  }, []);

  const renderObjectsPanes = ( typeId ) => {
    	return <div className="sort-objects">
                   <Row>
                   { entityList.length
                   	? entityList.map ( e => {
                   		return <Col md={12} key={e.id}><DocumentShortCard doc={e} /></Col>
                   	})
                   	: <Col md={12}><p>{texts.emptyList}</p></Col> }
                   	</Row>

                   	{ entityList.length

                   	? <Pagination className="sort-pagination">
                       <Pagination.First />
                       <Pagination.Prev />
                       <Pagination.Item>{1}</Pagination.Item>
                       <Pagination.Ellipsis />

                       <Pagination.Item>{10}</Pagination.Item>
                       <Pagination.Item>{11}</Pagination.Item>
                       <Pagination.Item active>{12}</Pagination.Item>
                       <Pagination.Item>{13}</Pagination.Item>
                       <Pagination.Item disabled>{14}</Pagination.Item>

                       <Pagination.Ellipsis />
                       <Pagination.Item>{20}</Pagination.Item>
                       <Pagination.Next />
                       <Pagination.Last />
                     </Pagination>
                   : null}
               </div>
    }
  /*<li key={docType.id} onClick={() => this.loadContent(docType.id)}>
                {docType.title}
            </li>*/
    // вызывается для отрисовки дочерних элементов списка слева
    const renderTypesBranch = ( parentId ) => {

        return docTypesTree.getChildren( parentId  ).map ( docType => {
            return <Nav.Item>
              <Nav.Link key={docType.id} eventKey={docType.id}>{docType.title}</Nav.Link>
            </Nav.Item>

        });
    }

    // вызывается для отрисовки родительских элементов списка слева
    const renderTypesTree = ( ) => {
        return docTypesTree.getRootList().map ( docType => {
            return <div key={docType.id}>
                <span>{docType.title}</span>
                <ul>
                    {renderTypesBranch(docType.id)}
                </ul>
            </div>
        });
    }

    

        return (
            <React.Fragment>
            <Breadcrumb>
                <UISref to={"public.main"}><Breadcrumb.Item href="/">{t("main")}</Breadcrumb.Item></UISref>
            </Breadcrumb>
            <h1>{texts.title}</h1>

            <Row className="block-map">
                <Col md={5}>
                  <div className="contacts-right-block">
                    <p>{t("footer-title")}</p>
                    <p className="name">{t("contacts.address-title")}</p>
                    <p>101000, {t("contacts.address")}</p>
                    <p className="name">{t("contacts.telephone")}</p>
                    <p><a href="tel:+74956284087">+7 (495) 628-40-87</a></p>
                    <p className="name">{t("contacts.e-mail")}</p>
                    <p className="text-underline"><a href="mailto:grdnt@rusfolk.ru">grdnt@rusfolk.ru</a></p>
                    <p className="name">{t("contacts.working-hours")}</p>
                    <p>{t("contacts.working-days")}</p>
                  </div>
                </Col>
                <Col md={7} className="map-left-block">
                  <YMaps>
                      <div>
                        <Map defaultState={{ center: [55.759851, 37.640282], zoom: 17 }}

                                 width={"100%"}  height={"500px"}>
                                <Placemark
                                  geometry={[55.759851, 37.640282]}
                                  properties={{
                                      iconCaption: 'Сверчков переулок, 8c3'
                                  }}
                                  options={{
                                        preset: 'islands#dotIcon',
                                        iconColor: '#ff3333'
                                    }}

                                />
                        </Map>
                      </div>
                  </YMaps>
                </Col>
            </Row>
            <Tab.Container defaultActiveKey="purtova">
              <Row className="structure" >
                <Col sm={5}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="purtova">
                        <p className="fio">{t("purtova.name")}</p>
                        <p className="position">{t("purtova.position")}</p>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="rusanova">
                        <p className="fio">{t("rusanova.name")}</p>
                        <p className="position">{t("rusanova.position")}</p>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="dobrovolskaya">
                        <p className="fio">{t("dobrovolskaya.name")}</p>
                        <p className="position">{t("dobrovolskaya.position")}</p>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="kotelnikova">
                        <p className="fio">{t("kotelnikova.name")}</p>
                        <p className="position">{t("kotelnikova.position")}</p>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={7}>
                  <Tab.Content>
                    <Tab.Pane eventKey="purtova">
                    <div className='right-col-card'>
                      <Row>
                        <Col sm={5}>
                          <img  style={{height:'auto', width:'100%'}} src={ PTV } />
                        </Col>
                        <Col sm={7}>
                          <h3>{t("purtova.name")}</h3>
                          <p className="position-right-col">{t("purtova.position")}</p>
                          <p className="name">{t("contacts.e-mail")}</p>
                          <p className="text-underline"><a href="mailto:grdnt@rusfolk.ru">grdnt@rusfolk.ru</a></p>
                          <p className="name">{t("contacts.telephone")}</p>
                          <p><a href="tel:+74956284087">+7 (495) 628-40-87</a></p>
                        </Col>
                      </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="rusanova">
                    <div className='right-col-card'>
                      <Row>
                        <Col sm={5}>
                          <img  style={{height:'auto', width:'100%'}} src={ RMV } />
                        </Col>
                        <Col sm={7}>
                          <h3>{t("rusanova.name")}</h3>
                          <p className="position-right-col">{t("rusanova.position")}, {t("rusanova.position2")}</p>
                          <p className="name">{t("contacts.e-mail")}</p>
                          <p className="text-underline"><a href="mailto:mglonty@mail.ru">mglonty@mail.ru</a></p>
                          <p className="name">{t("contacts.telephone")}</p>
                          <p><a href="tel:+74956284067">+7 (495) 628-40-67</a></p>
                        </Col>
                      </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="dobrovolskaya">
                    <div className='right-col-card'>
                      <Row>
                        <Col sm={5}>
                          <img  style={{height:'auto', width:'100%'}} src={ DVE } />
                        </Col>
                        <Col sm={7}>
                          <h3>{t("dobrovolskaya.name")}</h3>
                          <p className="position-right-col">{t("dobrovolskaya.position")}</p>
                          <p className="name">{t("contacts.e-mail")}</p>
                          <p className="text-underline"><a href="mailto:dobrovolska@inbox.ru">dobrovolska@inbox.ru</a></p>
                          <p className="name">{t("contacts.telephone")}</p>
                          <p><a href="tel:+7(495)624-25-53">+7 (495) 624-25-53</a></p>
                        </Col>
                      </Row>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="kotelnikova">
                    <div className='right-col-card'>
                      <Row>
                        <Col sm={5}>
                          <img  style={{height:'auto', width:'100%'}} src={ KNE } />
                        </Col>
                        <Col sm={7}>
                          <h3>{t("kotelnikova.name")}</h3>
                          <p className="position-right-col">{t("kotelnikova.position")}</p>
                          <p className="name">{t("contacts.e-mail")}</p>
                          <p className="text-underline"><a href="mailto:t-kultura@yandex.ru">t-kultura@yandex.ru</a></p>
                          <p className="name">{t("contacts.telephone")}</p>
                          <p><a href="tel:+7(495)624-40-87">+7 (495) 624-40-87</a></p>
                        </Col>
                      </Row>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>


            </React.Fragment>
        )
}

export default ContactsPage;
