import React from 'react';
import { useTranslation } from 'react-i18next';

import { buildCommonNknAttrList, buildNknCategoriesList, buildNknHeritagePlacesList } from 'common/utils';
import  { getValueByLocale } from '../../common/utils';

import './less/card.full.component.less';

if ( ENV.debug.root ) console.log ( 'pane.header.component.js' );

export const PaneHeader = (props) => {
    const { nkn } = props;
    if ( nkn == null ) return null;

    const { t, i18n } = useTranslation();

    // set right translation
    const cTitles = nkn.categories.map( catRoot => catRoot.map ( c=> ({title: getValueByLocale(c, 'title', i18n.language) }) ));

    // these ara array of arrays
    const categories =  buildNknCategoriesList ( cTitles );

    const eTitles = nkn.dictTree.ethnos.map( e => ({title: getValueByLocale(e, 'title', i18n.language) }) );
    const ethnos =      buildCommonNknAttrList ( eTitles );

    const lTitles = nkn.dictTree.language.map( e => ({title: getValueByLocale(e, 'title', i18n.language) }) );
    const language =      buildCommonNknAttrList ( lTitles );

    const rTitles = nkn.dictTree.religion.map( e => ({title: getValueByLocale(e, 'title', i18n.language) }) );
    const religion =      buildCommonNknAttrList ( rTitles );

    const hpTitles = nkn.heritagePlaces.map( e => i18n.language === 'ru' ? e :  ({address: {addrText: getValueByLocale(e, 'addrText', i18n.language)}}));
    const heritagePlaces = buildNknHeritagePlacesList ( hpTitles );

    return (
        <React.Fragment>
            <h2>{getValueByLocale(nkn, 'title', i18n.language)}</h2>
            <div className="nknCard_subtitle-container">
                <div className="nknCard_subtitle-elem">
                    <span className="nknCard_subtitle">{t("category")}:</span>
                    <span>{categories}</span>
                </div>
                <div className="nknCard_subtitle-elem">
                  <span className="nknCard_subtitle">{t("affiliation")}:</span>
                  <span>{religion}</span>
                </div>
                <div className="nknCard_subtitle-elem">
                    <span className="nknCard_subtitle">{t("ethnos")}:</span>
                    <span>{ethnos}</span>
                </div>
                <div className="nknCard_subtitle-elem">
                    <span className="nknCard_subtitle">{t("existence")}:</span>
                    <span>{t('rf')}{ heritagePlaces}</span>
                </div>
                <div className="nknCard_subtitle-elem">
                    <span className="nknCard_subtitle">{t("language")}:</span>
                    <span>{language}</span>
                </div>
          </div>
            <hr/>
        </React.Fragment>
    )
};

export default PaneHeader
