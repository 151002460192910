class QueryBuilder {
    constructor(data, viewName) {
        //this.srcData = data;
        this.eqFields = ['entityType', 'docType', 'heritageObject', 'isMain', 'mediaType' ];
        this.queryBase = {
            // return all if no filter given
            "filter": {"conditions": []}, //{"property": "id", "operator": "!=", "value": 0}
            "limit": 10,
            "offset": 0,
            "returnCount": true
        };
        if (viewName != null) this.queryBase.viewName = viewName;

        this.query = {...this.queryBase};
        this.extract(data, this.query.filter.conditions, '');
    }

    setLimit = (limit) => this.query.limit = limit;
    setOffset = (offset) => this.query.offset = offset;
    setReturnCount = (returnCount) => this.query.returnCount = returnCount;
    setView = (viewName) => this.query.viewName = viewName;
    setSort = (sortField) => this.query.sort = sortField;
    setData = (data) => {
        this.query.filter.conditions = [];
        this.extract(data, this.query.filter.conditions, '');
    };

    extract = (data, accu, path, attr) => {

        if (data == null) return accu;

        if (typeof data != 'object') {
            if (typeof data === 'string' && data.length === 0) return;
            if (typeof data === 'number' && data === 0) return;

            accu.push({
                "property": path,
                "operator": this.eqFields.indexOf(attr) !== -1 ? "=" : 'contains',
                "value": data
            });
            return;
        }

        if (attr != null && data.hasOwnProperty(attr)) {
            data = data [attr];
            path = path + '.' + attr;
        }

        if (Array.isArray(data)) {
            data.forEach(item => this.extract(data, accu, path, ''));
        } else {
            for (let key in data) {
                if (!data.hasOwnProperty(key)) continue;
                this.extract(data[key], accu, path + (path.length > 0 ? '.' : '') + key, key);
            }
        }
    };

    toJSON = () => this.query || {};
    toString = () => JSON.stringify(this.toJSON());
}

export default QueryBuilder;
