// Нам нужны функции createStore, connect, and Provider:
import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import rootReducer from './redux/root.reducer';
//import { loadDictionaries } from './redux/actions'
import { fetchCategories, fetchRegions } from './redux/dict.reducer'


if ( ENV.debug.root ) console.log ( 'store.config.js' );

// Создаем reducer с пустым объектом в качестве начального состояния.
const initialState = { filter: {}, crumbs:[] };

//console.log ( rootReducer );

const store = configureStore({
  reducer: rootReducer, 
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
  initialState,
//  enhancers: [reduxBatch],
});

//store.dispatch ({ type: "INIT", initialState });
//Dispatch the fetchPosts() before our root component renders
store.dispatch ( fetchCategories () );
store.dispatch ( fetchRegions () );


export { store };
