import React from 'react';
import imgAbout from "./images/v3.jpg";
import * as url from "url";
import { useTranslation } from 'react-i18next';
import "../../common/i18next"

if (ENV.debug.root) console.log('about.component.js');

export const AboutBlock = () => {

    //Translation
    const { t } = useTranslation();

    return (<>
        <div className="about-img" style={{ backgroundImage: `url(${imgAbout})`, height: '192px', width: '100%' }}>
        </div>
        <p><b>{t("aboutblock.title1")}</b></p>
        <p>{t("aboutblock.descr1")}</p>
        <hr/>
        <p><b>{t("aboutblock.title2")}</b></p>
        <p>{t("aboutblock.descr2")}</p>
        <hr/>

        <p><b>{t("aboutblock.title3")}</b></p>
        <p>{t("aboutblock.descr3")}</p>

    </>)
};
export default AboutBlock;
