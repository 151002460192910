import React, {Component} from 'react';
import {searchSliceActions} from "../../redux/search.reducer";
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import SearchList from "../search/search.list.component";
if (ENV.debug.root) console.log('country.list.component.js');

export class CountryList extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }
    async componentDidMount() {
        const p = this.props.search.page;
        this.props.dispatch(searchSliceActions.dropAll());

        if (p !== undefined) {
            this.props.dispatch(searchSliceActions.setPage(p));
        }
    }
    render() {
        return <SearchList />
    }
}

const CountryListTranslated = withTranslation()(CountryList);
export default connect((state) => (state))(CountryListTranslated);

