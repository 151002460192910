import cubaService from 'common/api/cuba.reqs.service';
import * as sc from '../../modules/search/search.constants';

const filter2search = (filter) => {
    // debugger;
    const searchBody = {[sc.SEARCH_DICTVAL_NAME]: [], limit: sc.PAGE_SIZE};
    for (let filterType in filter) {
        if (!filter.hasOwnProperty(filterType)) continue;

        // debugger;
        switch (filterType) {
            case sc.SEARCH_DISTRICT_NAME:
                // TODO: use all category items in search
                searchBody[filterType] = filter[filterType][0].id;
                break;
            case sc.SEARCH_REGION_NAME:
                // TODO: use all region items in search
                searchBody[filterType] = filter[filterType][0].id;
                break;
            case sc.SEARCH_FIAS_NAME:
                // TODO: use all fias items in search
                searchBody[filterType] = filter[filterType][0].id;
                break;
            case sc.SEARCH_DICTVAL_NAME:
                filter[filterType].forEach(i => searchBody[filterType].push(i.id));
                break;
            case sc.SEARCH_CATEGORY_NAME:
                searchBody[sc.SEARCH_DICTVAL_NAME].push(filter[filterType].id);
                break;
            case sc.SEARCH_TERM_NAME:
                searchBody[filterType] = filter[filterType];
                break;
            case sc.SEARCH_STATUS_NAME:
                // TODO: use all status items in search
                searchBody[filterType] = filter[filterType][0].id;
                break;
            case sc.SEARCH_ACTUAL_STATE_NAME:
                // TODO: use all actual items in search
                searchBody[filterType] = filter[filterType][0].id;
                break;
            case sc.SEARCH_AUTHOR_NAME:
                // TODO: use all authors items in search
                searchBody[sc.SEARCH_TERM_NAME] = `${searchBody[sc.SEARCH_TERM_NAME] ? searchBody[sc.SEARCH_TERM_NAME] : ''} ${filter[filterType][0].title}`;
                break;
            case sc.SEARCH_PAGE_NAME:
                searchBody[filterType] = filter.offset = filter[filterType];
        }
    }

    return searchBody;
};

export const fetchSearch = async (filter, offset = 0) => {
    // console.log(filter);
    const criteria = {...filter};

    if (criteria[sc.SEARCH_DICTVAL_NAME] == null) criteria[sc.SEARCH_DICTVAL_NAME] = [];

    const srch = filter2search(criteria);
    if (srch[sc.SEARCH_DICTVAL_NAME].length === 0) delete srch[sc.SEARCH_DICTVAL_NAME];
    srch['offset'] = offset;
    return await cubaService.searchNknObjects(srch, 'heritageObjectListView');
};
