import React, {Component, useContext} from 'react';

import classNames from 'classnames';
import {UISref} from '@uirouter/react';

import pinAddress from "./images/card.short/pin-address.png"
import objAbout from "modules/root/images/obj-about.png"
import objAudio from "modules/root/images/obj-audio.png"
import objVideo from "modules/root/images/obj-video.png"
import objPhoto from "modules/root/images/obj-photo.png"
import objMap   from "modules/root/images/obj-map.png"

if ( ENV.debug.root ) console.log ( 'card.short.component.js' );

import { getValueByLocale } from 'common/utils'
import {useTranslation} from 'react-i18next';


/*
export const NknCardShortListView = ( props ) => <div className="random-card-list">
        <div className="header-card clearfix">
            <span className="card-name">
            <UISref to={'public.nkn.card'} params={{nknId:props.nkn.id}} ><span>{props.nkn.fullName}</span></UISref>
                <span className="card-category">
                    {props.nkn.category[0]}
                </span>
            </span>
        </div>
        <div className="card-text">
             <p>Нематериальное культурное наследие проявляется в таких областях, как устные традиции, исполнительские искусства, обычаи, обряды, празднества, знания и навыки, связанныеs с традиционными ремеслами...»</p>
        </div>
        <div className="clearfix">
            <div className="address">
                <img src={ pinAddress } /><span className="">{props.nkn.place.text}</span>
            </div>
            <div className="share-block clearfix">
                <img src={ objAbout } className="float-left" />
                <img src={ objPhoto } className="float-left" />
                <img src={ objAudio } className="float-left" />
                <img src={ objVideo } className="float-left" />
                <img src={ objMap } className="float-left" />
            </div>
        </div>
    </div>;

         <div className="card-text">
             <p>{props.nkn.shortDescription}</p>
        </div>

 */

const NknCardShortListView = ( props ) => {
    const { t, i18n } = useTranslation();

    const NknCatItem = ( props ) => <span className="card-category">{props.branch.map ( catItem => getValueByLocale(catItem, 'title', i18n.language) ).join(` / `)}</span>;

    let address = '';
    if ( props.nkn.heritagePlaces && props.nkn.heritagePlaces.length > 0 ) {
        if ( i18n.language === 'ru') {
            address = props.nkn.heritagePlaces[0].address.addrText;
        } else  {
            // debugger;
            address = getValueByLocale(props.nkn.heritagePlaces[0], 'addrText', i18n.language)
            if ( !address ) address = props.nkn.heritagePlaces[0].address.addrText;
        }
    };

    return (<div className="random-card-list">
        <div className="header-card clearfix">
            <div className="card-name">
                <UISref to={'public.nkn.card'} params={{nknId:props.nkn.id}} ><div>{getValueByLocale(props.nkn, 'title', i18n.language)}</div></UISref>
                { props.nkn.categories && props.nkn.categories.length > 0 ?
                    props.nkn.categories.map ( (branch, idx) => <NknCatItem key={`${idx}`} branch={branch}/>)
                    : ''
                }
            </div>
        </div>
        <div className="card-text">
            <p></p>
        </div>
        <div className="clearfix">
            <div className="address">
                <img src={ pinAddress } /><span className="">{address}</span>
            </div>
            <div className="share-block clearfix">
                <UISref to={'public.nkn.card'} params={{nknId:props.nkn.id, pane: 'about'}} ><img src={ objAbout } className="float-left" /></UISref>
                <UISref to={'public.nkn.card'} params={{nknId:props.nkn.id, pane: 'photo'}} ><img src={ objPhoto } className="float-left" /></UISref>
                <UISref to={'public.nkn.card'} params={{nknId:props.nkn.id, pane: 'audio'}} ><img src={ objAudio } className="float-left" /></UISref>
                <UISref to={'public.nkn.card'} params={{nknId:props.nkn.id, pane: 'video'}} ><img src={ objVideo } className="float-left" /></UISref>
                <UISref to={'public.nkn.card'} params={{nknId:props.nkn.id, pane: 'map'}} ><img src={ objMap } className="float-left" /></UISref>
            </div>
        </div>
    </div>);
}
export default NknCardShortListView;
