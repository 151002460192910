const ERROR_MSGS = {
    "401": "Неверные логин и/или пароль",
};

const GUEST_DATA = {
    username: 'guest',
    password: 'gZBTau6TGtaN',
    grant_type: 'password'
};
const AUTH_URL = ENV.apiFrontEnd + '/oauth/token';

const makeRequest = async (url, method = 'GET', hdrSrc = [], body = null, qParams = null) => {

    const headers = new Headers([
    	['Accept', 'application/json'],
    	['Content-Language', 'ru'],
		['Accept-Language', 'ru'],
    ]);
    if (hdrSrc != null) hdrSrc.forEach(item => headers.set(item[0], item[1]));

    let tok = null;

    try {
    	tok = await getToken ();
    } catch ( err ) {
    	console.log ( err );
    }

    // Login as guest if no token and no auth request
    if (!tok && !headers.get('Authorization')) {
        const data = await authorize(GUEST_DATA);
        tok = setToken(data && data.access_token ? data : null);
    }
    // Set headers after all
    if (tok && tok.access_token && tok.access_token.length > 0) {
        headers.set('Authorization', 'Bearer ' + tok.access_token);
    }

    let qStr = '';
    if ( typeof qParams == 'string' && qParams.length ) {
        qStr = `view=${qParams}`;
    } else if ( typeof qParams == 'object' && qParams != null ) {
        for (var i = 0, keys = Object.keys(qParams); i < keys.length; i++) {
            qStr += `${keys[i]}=${qParams[keys[i]]}&`;
            qStr = qStr.slice(0, -1);
        }
    }

    url = qStr.length ? `${url}?${qStr}` : url;

    const request = new Request(url,
        {
            method: method,
            body: body,
            headers: headers,
            //credentials: "omit", //  same-origin', include, *same-origin, omit
            //mode: 'no-cors', // cors, no-cors, *cors, same-origin
        });

    return fetch(request).then( async response => {
        if (response.ok) {
            const headers = response.headers;

            const contentType = headers.get("content-type");
            const resp = {};
            resp.data = contentType ? await response.json() : response;
            resp.headers = headers;
            // null провоцирует ошибку - исправить запросы, что б получать правильный count
            // в запрос добавляем returnCount=true (в post и в get) qStr += '&returnCount=true';
            // должно быть "X-Total-Count"
            resp.count = null; // headers.get("content-type");
            return Promise.resolve ( resp );
        }
        else if (response.status === 401 && tok && tok.access_token) {
            delete sessionStorage.token;
            location.reload();
        }
        else {
            let error = response.error ? response.error : {
                title: 'Ошибка сервера',
                content: 'Сервер не может обработать данный запрос'
            };
            throw Error( {data: error, headers: response.headers});
        }
	    })
	    .catch((err) => {
        	console.log ( `Fetch error for ${url}` );
        	return { data:[] }
    	});
}

const getToken = async () => {

    // Check saved token in session storage
    if ( !sessionStorage.token ) return null;

    let tok = JSON.parse(sessionStorage.token);
    let curTime = new Date().getTime() / 1000;


    // Remove token if damaged
    if ( !tok.access_token || !tok.refresh_token || !tok.expiration_time) {
        delete sessionStorage.token;
    }

    // Refresh token if expired
    if (curTime >= tok.expiration_time) {
        const req_body = { refresh_token: tok.refresh_token, grant_type: 'refresh_token' };
        try {
            const data = await authorize(req_body);
            tok = setToken(data);
        } catch {
            tok = null;
        }
    }
    return tok;
}


const authorize = async (body) => {
	//console.log ( AUTH_URL );
    const request = new Request(AUTH_URL, {
        method: 'POST',
        headers: {
            'Authorization': 'Basic Y2xpZW50OnNlY3JldA==',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        },
        body: encodeRequest(body),
    });
    return fetch(request).then(response => {
        if (response.ok) {
            const contentType = response.headers.get("content-type");
            return contentType ? response.json() : Promise.resolve ( response );
        } else {
            return Promise.reject(null);
        }
    });
}

const encodeRequest = (obj) => {
    var str = [];
    for (var p in obj)
        str.push(p + "=" + obj[p]);
    return str.join("&");
};


const setToken = (tok) => {
    if (tok == null) throw new Error(ERROR_MSGS['401']);

    var curTime = new Date().getTime() / 1000;
    const token = {
        access_token: tok.access_token,
        refresh_token: tok.refresh_token,
        expiration_time: curTime + tok.expires_in
    };

    window.sessionStorage.token = JSON.stringify(token);
    return token;
};

export {
    makeRequest,
    authorize,
    encodeRequest,
    setToken,
}
