import React, {useContext} from 'react';

import {Accordion, Card} from 'react-bootstrap';

import AccordionContext from 'react-bootstrap/AccordionContext';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import classNames from 'classnames';

function ContextAwareToggle({children, eventKey, callback}) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div className={classNames('name-info', {'collapsedStyling': isCurrentEventKey})} onClick={decoratedOnClick}>
            {children}
        </div>
    );
}

export const renderAccordionCards = (roots, keyRoot, renderTitle, renderList) => {
    return roots.map((item, idx) => (
            <Card key={`${keyRoot}${idx + 1}`}>
                <Card.Header>
                    <ContextAwareToggle eventKey={idx + 1}>{renderTitle(item)}</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={idx + 1}>
                    <Card.Body>
                        <ul className="list-unstyled">
                            {renderList (item)}
                        </ul>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    )
};
export default renderAccordionCards;
