import React, {useEffect, useState} from 'react';

// компоненты bootstrap
import {Row, Col, Tab, Nav, Spinner} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

// отрисовка карточки документа
import {DocumentShortCard} from './document.shortcard.component';

import {UISref} from "@uirouter/react";

// компоненты для получения данных от backend
import cubaService from 'common/api/cuba.reqs.service';
import QueryBuilder from 'common/api/query-builder.service.js';

import EntityTree from 'common/EntityTree';

// предлагаю отделять стили для страницы-контейнера и для отдельных карточек в списке
// TODO: поддерживаю
import './less/document.list.component.less';
import { useTranslation } from 'react-i18next';

if (ENV.debug.root) console.log('document.list.component.js');


const FILES_ROOT = ENV.apiFrontEnd + "/files/";

// Классы всегда именуются с большой буквы
export const DocumentList = (props) => {
    const [loading, setLoading] = useState(false);
    const [entityList, setEntityList] = useState([]);
    const [currTypeKey, setCurrTypeKey] = useState(null);
    const [docTypesTree, setDocTypesTree] = useState(null);
    const { t, i18n } = useTranslation();

    const texts = {
        title: t("documents"),
        emptyNode: t("list-filling"),
        emptyList: t("filling-out")
    };

    // чтения данных при старте компонента
    // сначала читаем типы, потом выбераем первый по списку тип,
    // потом грузим данные как если бы этот тип выбрал пользователь
    const loadDicts = async () => {

        const DOC_ROOT_DESCR = `docRoot-${i18n.language}`;

        // ставим на паузу
        setLoading(true)

        // здесь читаем только типы
        const response = await cubaService.getAllItems('nkn$DocumentTypeDictionaryEntity')

        // сохраняем данные
        const data = await response.data;
        console.log(data);

        let docRoot = data.find(d => d.description === DOC_ROOT_DESCR);
        
            const docTypesTree = new EntityTree(data)
            docTypesTree.setRoot(docRoot);
            setDocTypesTree(docTypesTree)

            // вычисляем id первого типа документов
            const firstChild = docTypesTree.getFirstChild();


            // сохраняем полученный данные в состояние
            const currTypeKey = firstChild ? firstChild.id : null
            setCurrTypeKey(currTypeKey)
        setLoading(false)
    }

    useEffect(() => {
        loadDicts();
    }, [i18n.language])

    // вызывается при активации пункта дерева слева
    const loadContent = async (currTypeKey) => {
        if (currTypeKey === null) return;

        // ставим на паузу
        setLoading(true)

        const q = new QueryBuilder({"docType": currTypeKey});
        const response = await cubaService.getItemsByFilter('nkn$Document', q.toString());
        const data = response.data;

        // сохраняем полученный данные в состояние
        setLoading(false)
        const entityList = data ? data : null
        setEntityList(entityList)
    }

    // вызывается при старте компонента
    useEffect(() => {
        loadContent(currTypeKey);
    }, [currTypeKey])

    const downloadItem = async (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        const docId = evt.currentTarget.id;
        if (docId == null) return;

        let response = await cubaService.getItemById('nkn$Document', docId, "documentFileView");
        if (response.data == null) return;
        if (response.data.file == null) return;

        const doc = response.data;
        setTimeout(() => {
            window.open(`${FILES_ROOT}${doc.file.id}`);
        }, 100);
    };

    const renderObjectsPanes = (typeId) =>
        loading ? <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
            : <div className="sort-objects">
                <Row>
                    {entityList.length
                        ? entityList.map(e => {
                            return <Col md={12} key={e.id}><DocumentShortCard doc={e} onDownload={downloadItem}/></Col>
                        })
                        : <Col md={12}><p>{texts.emptyList}</p></Col>}
                </Row>
            </div>

    // вызывается для отрисовки дочерних элементов списка слева
    const renderTypesBranch = (parentId) =>
        docTypesTree.getChildren(parentId).map(docType =>
            <Nav.Item key={docType.id}>
                <Nav.Link eventKey={docType.id}>{docType.title}</Nav.Link>
            </Nav.Item>
        );

    // вызывается для отрисовки родительских элементов списка слева
    const renderTypesTree = () => docTypesTree.getRootList().map(docType =>
        <div key={docType.id}>
            <h2>{docType.title}</h2>
            <ul>
                {renderTypesBranch(docType.id)}
            </ul>
        </div>
    );

    if (docTypesTree === null) return <div>{t("categories-not-found")}</div>;
    if (currTypeKey === null) return <div>{t("documents-not-uploaded")}</div>;

    return (
        <>
            <Breadcrumb>
                <UISref to={"public.main"}><Breadcrumb.Item href="/">{t("main")}</Breadcrumb.Item></UISref>
            </Breadcrumb>

            <Tab.Container id="region-object-list" defaultActiveKey={currTypeKey} onSelect={loadContent}>
                <Row>
                    <Col lg={3} md={4}>
                        <h1>{texts.title}</h1>
                        <div className="left-col-card">
                            <Nav className="flex-column left-nav-card">
                                {i18n.language === "ru" 
                                ? renderTypesTree()
                                : renderTypesBranch(docTypesTree.root.id)
                                }
                            </Nav>
                        </div>
                    </Col>
                    <Col lg={9} md={8}>
                        <div className="right-col-card">
                            <Tab.Content>
                                {renderObjectsPanes()}
                            </Tab.Content>
                        </div>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    )
}

export default DocumentList;
