import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

if ( ENV.debug.root ) console.log ( 'pane.footer.component.js' );

export const PaneFooter = (props) => {
    const { t } = useTranslation()
    const { nkn } = props;
    if ( nkn == null ) return null;
    if ( !nkn.publishDate ) return  null;

    return (<>
            <div className="datesBlock clearfix">
                <div className="date">{t("publication-date")}: {nkn.publishDate}</div>
            </div>
            <TagListBlock nkn={nkn} />
        </>)
};

const TagListBlock = (props) => {
    if ( props.nkn.tags == null || props.nkn.tags.length === 0 ) return null;
    return (<div className="tags clearfix">
        <Row>
            <Col sm={1}><div className="tag">{t('tags')}:</div></Col>
            <Col sm={11}>
                { props.nkn.tags.map ( (a, idx)  => <div key={`ftag${idx}`} className="tag nameTag">{a}</div> )}
            </Col>
        </Row>
    </div>)
};

/*
<div className="date">Дата обновления: {nkn.dateUpdate}</div>
 */
export default PaneFooter
