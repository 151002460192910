import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {UISref} from '@uirouter/react';
import { useTranslation } from 'react-i18next';

import "./less/footer.component.less";

import logoKSNKN from "./images/ksnkn-logo.png";
import logoKSNKNen from "./images/ksnkn-en-logo.png";
import logoGRD from "./images/footer-logo.svg";

if (ENV.debug.root) console.log('footer.component.js');

export const RootFooter = (props) => {
    const { t, i18n } = useTranslation();
    return (<>
        <Container>
            <div className="footer-block">
                <div>
                    <a href="http://www.rusfolk.ru/" target="_blank">
                        <div className="logo-grd">
                            <img src={logoGRD} alt="logo GRD"/>
                            <span
                                className="text-grd">{t("footer-title")}</span>
                        </div>
                    </a>
                </div>
                <div>
                    <UISref to={'public.contacts'}>
                        <div className="contacts">
                            <h6>{t("contacts.title")}</h6>
                            <p>{t("contacts.address")}</p>
                            <p>{t("contacts.tel")} <a href="tel:+7 495 628-40-87">+7 495 628-40-87</a></p>
                        </div>
                    </UISref>
                </div>
                <div>
                    {i18n.language === "ru" 
                    ? <img src={logoKSNKN} alt="logo KSNKN" className="ksnkn"/> 
                    : <img src={logoKSNKNen} alt="logo KSNKN" className="ksnkn"/>}
                </div>
            </div>
        </Container>
    </>)
};
export default RootFooter
