import React, {useState, useEffect} from 'react';
import {Spinner} from 'react-bootstrap';
import cubaService from 'common/api/cuba.reqs.service';
import NknCardNewListView from 'modules/nkn/card.new.listview.component';
import {setupDictsData} from "common/nkn.helpers";
import { useTranslation } from 'react-i18next';

import {NEW_NKN_COUNT} from "common/constants";
import {connect} from "react-redux";
import {RandomNknBlock} from "./randomnkn.component";
import EntityTree from "../../common/EntityTree";
import DictionaryTree from "../../common/DictionaryTree";
import { getValueByLocale } from 'common/utils'

const fetchData = async (lang) => {
    const criteria = {
        "filter": {
            "conditions": [{
                "group": "AND",
                "conditions": [{"property": "status", "operator": "=", "value": "REGISTER"},
                    {"property": "isNew", "operator": "=", "value": "true"}]
            }]
        },
        // "limit": NEW_NKN_COUNT,
        "sort": "-createTs",
        "view": 'heritageObjectCardView'
    };
    // if ( lang === 'en') criteria.locale = 'EN';
    // const response = await cubaService.getNewNknObjects(criteria);
    // const response = await cubaService.searchNknObjects(criteria);
    const response = await cubaService.getNewNknObjects(criteria);
    return response != null ? response.data : [];
};

const processData = (data, catList, regList, lang) => {
    const catTree = new EntityTree();
    catTree.setTree(catList);

    return data.map(nkn => {
        // build branch from categories tree
        const dictTree = new DictionaryTree(nkn.dictionaryLinks);
        const nknCategories = dictTree.category.map(cat => catTree.getBranch(cat.id));


        let dataTitle = ''
        dataTitle = getValueByLocale(nkn, 'title', lang)

        let regLocName = '';
        if ( nkn.heritagePlaces && nkn.heritagePlaces.length ) {
            const regLoc = regList.regions.find ( r => r.id ===  nkn.heritagePlaces[0].region.id );
            if ( regLoc ) regLocName = getValueByLocale(regLoc, 'descr', lang );
        }
        return {
            id: nkn.id,
            title: dataTitle,
            region: regLocName,
            categories: nknCategories.map(catBranch => catBranch.map(catNode => catNode.title).join(` / `)).join(`, `)
        };
    });
};


export const NewNknListBlock = (props) => {
    const [loading, setLoading] = useState(true);
    const [newObjList, setNewObjList] = useState([]);
    const { t, i18n } = useTranslation();


    useEffect(() => {
        if ( !props.regions.regions.length ) return;
        fetchData(i18n.language).then(data => {
            setNewObjList(processData(data, props.categories, props.regions, i18n.language));
            setLoading(false)
        })
    }, [props.regions, i18n.language]);

    if (loading) return (<Spinner animation="border" variant="primary"/>);
    return (<div>
        <h2 style={{paddingLeft: "1.5rem"}}>{t("new-objects")}</h2>
        <div className="new-objects">
            {newObjList.map((nkn, idx) => <NknCardNewListView key={`newobjects${idx}`} {...nkn} />)}
        </div>
    </div>)
};


const mapState = state => ({
    categories: state.categories,
    regions: state.regions,
});
export default connect(mapState)(NewNknListBlock);
