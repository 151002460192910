import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Col, Row, Spinner} from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import NknCardShortListView from 'modules/nkn/card.short.listview.component';
import NknCardShortTableView from 'modules/nkn/card.short.tableview.component';

import EntityTree from "common/EntityTree";
import RegionTree from "common/RegionTree";
import DictionaryTree from "common/DictionaryTree";

import {fetchSearch} from 'common/api/cuba.search.service';

import {useComponentWillMount, getValueByLocale} from 'common/utils';

import {fetchCategories, fetchRegions} from "../../redux/dict.reducer";

import * as sc from './search.constants';
import './less/search.list.component.less';

import imgListSort from "modules/region/images/img-list-sort.svg";
import imgTileSort from "modules/region/images/img-tile-sort.svg";
import arrowLeft from "modules/search/images/arrow-left.png";
import arrowLeftActive from "modules/search/images/arrow-left-active.png";
import arrowRight from "modules/search/images/arrow-right.png";
import arrowRightActive from "modules/search/images/arrow-right-active.png";

import * as s from "../../redux/load.status.constants";
import {searchSliceActions} from "../../redux/search.reducer";
import {useTranslation} from 'react-i18next';
import {SEARCH_ACTUAL_STATE_NAME, SEARCH_AUTHOR_NAME, SEARCH_FIAS_NAME, SEARCH_STATUS_NAME} from "./search.constants";

ENV.debug.search && console.log('search.result.list.component.js');

export const SearchResultsList = (props) => {
    const [loading, setLoading] = useState(true);
    const [dicts, setDicts] = useState(null);
    const [viewId, setViewId] = useState(1);
    const [entityList, setEntityList] = useState([]);
    const [entityTotal, setEntityTotal] = useState(0);
    const [nknList, setNknList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0); // props.search.page
    const {t, i18n} = useTranslation();
    const listRootRef = React.useRef(null);

    // step 1
    useComponentWillMount(() => {
        ENV.debug.search && console.log("useEffect for dicts");

        if (props.categories.status === s.EMPTY) props.dispatch(fetchCategories());
        if (props.regions.status === s.EMPTY) props.dispatch(fetchRegions());
    });

    // step 2
    useEffect(() => {
        ENV.debug.search && console.log("useEffect for dicts loaded");
        if (props.categories.status === s.EMPTY || props.regions.status === s.EMPTY) return;

        const d = {categories: new EntityTree(), regions: new RegionTree()};
        d.categories.setTree(props.categories.categories);
        d.regions.setTree(props.regions.regions);
        setDicts(d);

    }, [props.regions.status, props.categories.status]);

    // step 3
    useEffect(() => {
        if (dicts == null) return;

        ENV.debug.search && console.log("useEffect update [dicts, entityList]");
        const t = [...entityList];

        t.forEach(nkn => {
            nkn.dictTree = new DictionaryTree(nkn.dictionaryLinks);
            // build branch from categories tree
            nkn.categories = nkn.dictTree.category.map(cat => dicts.categories.getBranch(cat.id));
            if (nkn.mainPhoto?.url) nkn.mainPhoto.url = nkn.mainPhoto.url.replace('http:', 'https:');

            let regLocName = '';
            if (nkn.heritagePlaces && nkn.heritagePlaces.length) {
                const regLoc = props.regions.regions.find(r => r.id === nkn.heritagePlaces[0].region.id);
                if (regLoc) regLocName = getValueByLocale(regLoc, 'shortDescr', i18n.language);
            }
            nkn.region = regLocName;
        });

        setNknList(t);
        setLoading(false);

        // console.log ( props.search.filter );
        // if ( props.search.term.length !== 0 || Object.keys(props.search.filter).length !== 0 ) return;
        //
        // debugger;
        // fetchSearch(props.search.filter).then(data => {
        //     // add service structures for easy acceess to dictionary values
        //     data.forEach( nkn => {
        //         nkn.dictTree = new DictionaryTree(nkn.dictionaryLinks);
        //         // build branch from categories tree
        //         nkn.categories = nkn.dictTree.category.map ( cat => dicts.categories.getBranch( cat.id ) );
        //     });
        //     debugger;
        //     setEntityList(data);
        //     setLoading(false);
        // });

    }, [entityList, i18n.language]); //dicts,

    // step 4
    useEffect(() => {
        ENV.debug.search && console.log("useEffect for props.search");
        // if (dicts == null) return;
        setLoading(true);
        let isMounted = true;

        // fetchSearch(props.search.filter, props.search.page * sc.PAGE_SIZE).then(response => {
        fetchSearch(props.search.filter, (+props.startPage || 0) * sc.PAGE_SIZE).then(response => {
            if (isMounted) setEntityTotal(response.headers.get('X-Total-Count'));
            if (isMounted) setEntityList(response.data);
            if (isMounted) setCurrentPage(0);
            // // add service structures for easy acceess to dictionary values
            // data.forEach(nkn => {
            //     nkn.dictTree = new DictionaryTree(nkn.dictionaryLinks);
            //     // build branch from categories tree
            //     nkn.categories = nkn.dictTree.category.map(cat => dicts.categories.getBranch(cat.id));
            // });
            // setEntityList(data);
            if (isMounted) setLoading(false);
            if ( listRootRef != null && listRootRef.current !== null &&
                listRootRef.current.parentNode!= null && listRootRef.current.parentNode.parentNode != null ) {
                listRootRef.current.parentNode.parentNode.scrollIntoView();
            }
        });
        return () => { isMounted = false };

    }, [props.search]);

    useEffect(() => {
        setCurrentPage(props.startPage||0);
    }, []);

    // const setPage = (page) => {
    //     props.dispatch(searchSliceActions.setPage(page));
    //     setCurrentPage(page);
    // };

    const renderPagination = (entityList, entityTotal) => {
        // return null;
        if (entityTotal <= 0) return null;
        const pageMax = Math.ceil(entityTotal / sc.PAGE_SIZE);

        let items = [];
        for (let number = 0; number < pageMax; number++) {
            items.push((<Pagination.Item
                key={number}
                active={"" + number === props.startPage}
                onClick={() => {
                    // setPage(number);
                    props.dispatch(searchSliceActions.setPage(number));
                    const stateName = props.router.stateService.$current.name;
                    props.router.stateService.go(stateName, {page: number}, {location: true});
                }}
            >{number + 1}</Pagination.Item>));
        }

        return (<Pagination className="sort-pagination">
            {/* <Pagination.First onClick={() => setPage(0)}/> */}
            {/* <Pagination.Prev  onClick={() => setPage(currentPage - 1)}/> */}
            <Pagination.Prev disabled={currentPage <= 0} onClick={() => setPage(currentPage - 1)}>
                {currentPage <= 0 ? <img src={arrowLeft} alt=""/> : <img src={arrowLeftActive} alt=""/>}
            </Pagination.Prev>
            {items}
            <Pagination.Next disabled={currentPage + 1 >= pageMax}
                             onClick={() => setPage(Math.min(pageMax, currentPage + 1))}>
                {currentPage + 1 >= pageMax ? <img src={arrowRight} alt=""/> : <img src={arrowRightActive} alt=""/>}
            </Pagination.Next>
            {/* <Pagination.Next disabled={pageMax < currentPage+1 } onClick={() => setPage(Math.min ( pageMax, currentPage+1)) }/> */}
            {/* <Pagination.Last disabled={pageMax < currentPage+1 } onClick={() => setPage(entityTotal % sc.PAGE_SIZE)} /> */}
        </Pagination>)
    };

    const showResults = (loading = false, entityList = [], viewId) => {
        if (loading) {
            return <Col md={12}>
                <div className='random-card-list'><Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner></div>
            </Col>;
        }

        if (entityList == null || entityList.length === 0) {
            return <Col md={12}>
                <div>{t(`search-not-found`)}</div>
            </Col>
        }
        return nknList.map(o => {
            return viewId
                ? <div className="containerRandomCard" key={`srch${o.id}`}><NknCardShortTableView nkn={o}/></div>
                : <Col md={12} key={`srch${o.id}`}><NknCardShortListView nkn={o}/></Col>
        })
    };

    if (loading) return (<Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>);

    return <>
        {loading ? null : <div className="block-sort clearfix">
            <div className="total">{t("total-objects")}: {entityTotal}</div>
            <img alt='' src={imgTileSort} className="imgTileSort" onClick={() => setViewId(1)}/>
            <img alt='' src={imgListSort} className="imgListSort" onClick={() => setViewId(0)}/>
        </div>}
        <div ref={listRootRef}  className="flexContainerCards">
            {showResults(loading, entityList, viewId)}
        </div>
        {renderPagination(entityList, entityTotal)}
    </>
};

const mapState = state => ({
    categories: state.categories,
    regions: state.regions,
    search: state.search,

});
export default connect(mapState)(SearchResultsList);

/*
        <Pagination.Ellipsis />

        <Pagination.Item>{10}</Pagination.Item>
        <Pagination.Item>{11}</Pagination.Item>
        <Pagination.Item>{12}</Pagination.Item>
        <Pagination.Item>{13}</Pagination.Item>
        <Pagination.Item disabled>{14}</Pagination.Item>

        <Pagination.Ellipsis />
        <Pagination.Item>{20}</Pagination.Item>

 */
