import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import cubaService from "common/api/cuba.reqs.service";

import { Breadcrumb } from 'react-bootstrap';
// компонент для организации перехода по ссылке
import {UISref} from '@uirouter/react';


import {useComponentWillMount, buildCommonNknAttrList, extractCoordinates} from "common/utils";
import EntityTree from "common/EntityTree";
import RegionTree from "common/RegionTree";
import DictionaryTree from "common/DictionaryTree";
import {store} from "store.config";
import {fetchCategories, fetchRegions} from "redux/dict.reducer";
import {EMPTY_PHOTO_URL, NEW_NKN_COUNT} from 'common/constants';

import { MapRegionBlock } from "./map.region.map.component";
import { StatTable } from "./map.region.stattable.component";

import './less/map.region.component.less';
import * as s from "redux/load.status.constants";
import * as sc from "modules/search/search.constants";

import {searchSliceActions} from "../../redux/search.reducer";

import { getValueByLocale } from 'common/utils';
import {useTranslation} from 'react-i18next';

if (ENV.debug.root) console.log('map.region.component.js');

const fetchNkns = async () => {
    console.log(`fetchNkns`);
    // // const response = await cubaService.getAllItemsByView('nkn$HeritageObject', 'heritageObjectCardView');
    // const response = await cubaService.searchNknObjects({
    //     status: "A",
    //     view: 'heritageObjectCardView'
    // });
    // return response != null && response.data != null ? response.data : null;
    const response = await cubaService.getNewNknObjects({
        "filter": {
                "conditions": [{"property": "status", "operator": "=", "value": "REGISTER"}
                    ]
        },
        // "limit": NEW_NKN_COUNT,
        "sort": "-createTs",
        "view": 'heritageObjectCardView'
    });
    return response != null ? response.data : [];
};

const processNkns = (nkns, dicts, lang) => {
    return nkns.map(nkn => {
        const dictTree = new DictionaryTree(nkn.dictionaryLinks);
        // build branch from categories tree
        // const nknCategory = dictTree.category.length > 0 ? dicts.categories.getBranch(dictTree.category[0].id) : [];
        // const nknCategory = dictTree.category.length > 0 ? dicts.categories.getBranch(dictTree.dictionaryEntity.id) : [];
        // const nknCategory = nkn.categories && nkn.categories.length ? buildCommonNknAttrList ( props.nkn.categories[0] ) : '';
        //[0].dictionaryLinks[0].dictionaryEntity.title

        const placemarkData = {};
        // placemarkData.category = buildCommonNknAttrList(nknCategory);
        // build branch from categories tree
        nkn.categories = dictTree.category.map(cat => dicts.categories.getBranch(cat.id));

        // set right translation
        const cTitles = nkn.categories.map( catRoot => catRoot.map ( c=> ({title: getValueByLocale(c, 'title', lang) }) ));

        placemarkData.categories = nkn.categories && nkn.categories.length ? buildCommonNknAttrList ( cTitles[0] ) : '';

        placemarkData.preview = nkn.mainPhoto && nkn.mainPhoto.url ? nkn.mainPhoto.url : EMPTY_PHOTO_URL;
        placemarkData.coordinates = extractCoordinates(nkn.heritagePlaces, 0);
        placemarkData.title = nkn.title;
        placemarkData.id = nkn.id;

        return placemarkData;
    });
};

export const MapRegion = (props) => {
    const {regionId} = props.$stateParams;
    const stateService = props.transition.router.stateService;
    const [dicts, setDicts] = useState(null);
    const [entityList, setEntityList] = useState(null);
    const [catRoots, setCatRoots] = useState([]);
    const [currRegion, setcurrRegion] = useState(null);

    const { t, i18n } = useTranslation();

    // step 1: test if dicts loaded
    useComponentWillMount(() => {
        console.log("useEffect for props");
        if (props.categories.status === s.EMPTY) store.dispatch(fetchCategories());
        if (props.regions.status === s.EMPTY) store.dispatch(fetchRegions());
    });

    // step 2: build trees
    useEffect(() => {
        console.log("useEffect for dicts");
        // console.log ( "props.categories = ", props.categories.status  );
        // console.log ( "props.regions = ", props.regions.status  );
        if (props.categories.status === s.EMPTY || props.regions.status === s.EMPTY) return;

        const dicts = {categories: new EntityTree(), regions: new RegionTree()};
        dicts.categories.setTree(props.categories.categories);
        dicts.regions.setTree(props.regions.regions);
        setDicts(dicts);

    }, [props.regions.status, props.categories.status]);

    // step 3: use trees
    useEffect(() => {
        console.log("useEffect for nkn");
        if (dicts == null) return;

        // for rendering
        setcurrRegion(dicts.regions.findById(regionId));
        setCatRoots(dicts.categories.getRootList());

        // const filter = {};
        // filter[sc.SEARCH_REGION_NAME] = [currRegion];
        // store.dispatch({
        //     type: "SET_SEARCH",
        //     filter: filter
        // });

        fetchNkns().then(data => {
            // filter objects with curr region
            const regionNkns = data.filter(obj => obj.heritagePlaces && obj.heritagePlaces.length > 0 && obj.heritagePlaces[0].region.id === regionId);
            setEntityList ( processNkns(regionNkns, dicts, i18n.language) );
        });
    }, [dicts]);


    const onCategoryClick = ( catItem ) => {

        const f = {};
        f[sc.SEARCH_REGION_NAME] = [{id: currRegion.id, title: currRegion.shortDescr}];
        f[sc.SEARCH_CATEGORY_NAME] = {id: catItem.id, title: getValueByLocale(catItem, 'title', i18n.language) };
        props.dispatch(searchSliceActions.setRawFilter(f));

        // props.dispatch(searchSliceActions.setRegion();
        // props.dispatch(searchSliceActions.setCategory();
        stateService.go('public.search.list', {}, { reload: true, inherit: false, notify: true });

    };
    return (<>
        <Breadcrumb>
            <UISref to={"public.main"}><Breadcrumb.Item href="/">{t('main')}</Breadcrumb.Item></UISref>
        </Breadcrumb>
        <h1>{t('region-page-title')} {currRegion ? getValueByLocale(currRegion, 'descr', i18n.language)  : ''}</h1>
        <StatTable catList={catRoots} regionId={regionId} onCategoryClick={onCategoryClick}/>

        <div className="map-container">
            <MapRegionBlock entityList={entityList} currRegion={currRegion} />
        </div>
    </>)
};

export default connect((state) => ({
    categories: state.categories,
    regions: state.regions,
}))(MapRegion);


