import React, {useEffect, useState} from 'react';
import {Map, Placemark, YMaps} from "react-yandex-maps";

import pin from "modules/nkn/images/pin.png";
import pinActive from "modules/nkn/images/pin-active.png"

import './less/map.region.component.less';

if (ENV.debug.root) console.log('map.region.map.component.js');

export const MapRegionBlock = (props) => {
    const [ymaps, setYMaps] = useState(null);
    const [mapRef, setMapRef] = useState(React.createRef(null));
    const [centerGeo, setCenterGeo] = useState([55.684758, 37.738521]);

    // load region borders
    useEffect(() => {
        if (props.entityList == null) return;
        if (props.entityList.length === 0) return;

        const firstPoint = props.entityList.find(e => e.coordinates != null);
        if (firstPoint) setCenterGeo(firstPoint.coordinates);

    }, [props.entityList]);

    // load region borders
    useEffect(() => {
        if (ymaps == null) return;
        ymaps.borders.load("RU", {
            lang: "ru",
            quality: 3
        })
        .then(paintCurrentRegion);
    }, [ymaps]);

    const paintCurrentRegion = (result) => {

        const currRegPolygon = result.features.find(r => r.properties.iso3166 == props.currRegion.iso3166Code);
        currRegPolygon.options = {
            fillOpacity: 0.3,
            strokeColor: '#d87780',
            strokeOpacity: 1,
            fillColor: '#d87780'
        };
        currRegPolygon.id = currRegPolygon.properties.iso3166;

        const objectManager = new ymaps.ObjectManager();
        objectManager.add(currRegPolygon);

        if (mapRef) {
            mapRef.geoObjects.add(objectManager);

            mapRef.container.fitToViewport();
            const contSize = mapRef.container.getSize();
            if (contSize[0] > 0 && contSize[1] > 0) { // if it's visible
                mapRef.setBounds(mapRef.geoObjects.getBounds(), {
                    checkZoomRange: true,
                    zoomMargin: 0
                });

                const c = ymaps.util.bounds.getCenter(ref.geoObjects.getBounds());
                mapRef.panTo(c, { delay: 500 }); // Задержка между перемещениями.
            }
        }
    };

    return (<YMaps query={{lang: "ru_RU", load: "util.bounds"}}>
        <Map onLoad={ymaps => setYMaps(ymaps)}
             state={{center: centerGeo, zoom: 5}}
             modules={["borders", "ObjectManager", "geoObject.addon.balloon", "geoObject.addon.hint"]}
             width={"100%"} height={"500px"}
             instanceRef={mapRef => setMapRef(mapRef)}
        >
            <PlacemarkListView entityList={props.entityList}/>
        </Map>
    </YMaps>)
};

const PlacemarkListView = (props) => {
    if (props.entityList == null) return null;

    debugger;
    return props.entityList.map(({preview, id, title, categories, coordinates}) => {
        const baloonContent = `
            <table className="balloon-map"><tbody>
                <tr>
                <td rowSpan="2" className="balloon-block-img" style="padding: 0 10px 0 0; vertical-align: top;"><img src=${preview} style="height: auto; width: 100px;" className="" /></td>
                <td style="font-size: 18px; font-weight: bold; color: #3e485a; font-family: 'Roboto'; cursor: pointer;"><a href="/#/nkn/${id}">${title}</a></td>
                </tr>
                <tr>
                <td cstyle="font-size: 13px;">${categories}</td>
                </tr>
            </tbody></table>`;

        return (<Placemark
            key={id}
            geometry={{
                type: 'Point',
                coordinates: coordinates,
            }}
            properties={{
                hintContent: title,
                balloonContent: baloonContent,
            }}
            options={{
                iconLayout: 'default#image',
                iconImageHref: `${pin}`,
                hideIconOnBalloonOpen: false,
                // iconImageSize: [68, 84],
                // Смещение левого верхнего угла иконки относительно
                // её "ножки" (точки привязки).
                // iconImageOffset: [-30, -40],
            }}
            modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
            onMouseenter={e => e.get('target').options.set({"iconImageHref": `${pinActive}`})}
            onMouseleave={e => e.get('target').options.set({"iconImageHref": `${pin}`})}
        />)
    })
};

// if ( this.pointsList.length ) {
//     const result = ymaps.util.bounds.getCenterAndZoom(
//         ymaps.util.bounds.fromPoints(this.pointsList),
//         contSize
//     );
//
//     // Setting the optimal center and zoom level of the map.
//     ref.setCenter(result.center, Math.min(result.zoom, MIN_ZOOM)); //result.zoom
// } else {

export default MapRegionBlock;
