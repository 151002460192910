import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {store} from "store.config";
import {useComponentWillMount} from 'common/utils';

// компоненты bootstrap
import { Row, Col, Spinner} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import {UISref} from "@uirouter/react";

// компоненты для получения данных от backend
import {fetchCategories, fetchRegions} from "redux/dict.reducer";
import cubaService from 'common/api/cuba.reqs.service';
import EntityTree from "common/EntityTree";
import RegionTree from 'common/RegionTree';

import { DistrictStatisticsBlock } from "./district.statistics.component";
import { RegionStatisticsBlock } from "./region.statistics.component";

// предлагаю отделять стили для страницы-контейнера и для отдельных карточек в списке
import './less/statistics.page.component.less';
import * as s from "../../redux/load.status.constants";
import { useTranslation } from 'react-i18next';
import i18n from '../../common/i18next';

if (ENV.debug.root) console.log('statistics.page.component.js');

const fetchStatTotal = async () => {
    // console.log(`fetchStatTotal`);
    const response = await cubaService.getStatTotal();
    return response != null && response.data != null ? response.data : 0;
};

const Now2Str = () => {
    const newDate = new Date();
    const date = newDate.getDate(); //Current Date
    const month = newDate.getMonth() + 1; //Current Month
    const year = newDate.getFullYear(); //Current Year

    return `${date < 10 ? '0' : ''}${date}.${month < 10 ? '0' : ''}${month}.${year}`;
};

// Классы всегда именуются с большой буквы
export const StatisticsPage = (props) => {
    // const [loading, setLoading] = useState(true);
    const [dicts, setDicts] = useState(null);
    const {t} = useTranslation()
    const [currDistrict, setCurrDistrict] = useState(null);
    const [currRegion, setCurrRegion] = useState(null);
    const [total, setTotal] = useState(0);

    // step 1: test if dicts loaded
    useComponentWillMount(() => {
        // console.log("useEffect for props");
        if (props.categories.status === s.EMPTY) store.dispatch(fetchCategories());
        if (props.regions.status === s.EMPTY) store.dispatch(fetchRegions());

        fetchStatTotal().then(data => setTotal(parseInt(data.status.C) + parseInt(data.status.A)));
    });

    // step 2: build dicts trees
    useEffect(() => {
        // console.log("useEffect for dicts");
        console.log ( "props.categories = ", props.categories.status  );
        console.log ( "props.regions = ", props.regions.status  );
        if (props.categories.status === s.EMPTY || props.regions.status === s.EMPTY) return;

        const dicts = {categories: new EntityTree(), regions: new RegionTree()};
        dicts.categories.setTree(props.categories.categories);
        dicts.regions.setTree(props.regions.regions);
        setDicts(dicts);

    }, [props.regions.status, props.categories.status]);
    
    useEffect(() => {
        if(i18n.language !== "ru") {
            window.location.href = "#/main"
        }
    }, [i18n.language])

    console.log ( 'render' );
    if ( dicts == null ) return (<Spinner animation="border" variant="primary"/>);

    return (<>
            <Breadcrumb>
                <UISref to={"public.main"}><Breadcrumb.Item href="/">{t("main")}</Breadcrumb.Item></UISref>
            </Breadcrumb>
            <h1>{t("statistics")}</h1>
            <div className="statistic-total">
                <span>Всего по России</span>
                <span className="numbers">{total} объектов</span>
                 НКН на
                <span className="date">{Now2Str()}</span>
            </div>
            <Row className="">
                <Col md={5}>
                    <DistrictStatisticsBlock
                        regions={dicts.regions}
                        onDistrictChange={(d) => setCurrDistrict(d)}
                        onRegionChange={(r => setCurrRegion(r))} />
                </Col>
                <Col md={7}>
                    <RegionStatisticsBlock
                        categories={dicts.categories}
                        currRegion={currRegion} />
                </Col>
            </Row>
        </>)
};

export default connect((state) => ({
    categories: state.categories,
    regions: state.regions,
}))(StatisticsPage);

