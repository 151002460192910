import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import AboutBlock from 'modules/root/about.component';
import RandomNknBlock from 'modules/root/randomnkn.component';
import SearchAdvBlock from 'modules/root/search.advanced.component';
import MapBlock from 'modules/root/map.main.component';
import MainPageMap from 'modules/root/mainPageMap';
import CategoriesList from 'modules/root/categories.list.component';
import RegionsList from 'modules/root/regions.list.component';
import NewNknListBlock from 'modules/root/newnkn.list.component';
import RootStatistics from 'modules/root/statistics.component';

import './less/main.component.less';

if (ENV.debug.root) console.log('main.component.js');

export const RootMain = (props) => {
    // comes from router automatically
    const stateService = props.transition.router.stateService;

    return (<>
        <Container>
            <div className="main-block">
                <Row>
                    <Col md={4}>
                        <div className="left-col">
                            <div className="about-block">
                                <AboutBlock/>
                                <RandomNknBlock/>
                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="right-col">
                            <div className="map-main-page">
                                <div className="main-page_map-container" style={{minHeight: '200px'}}>
                                    <MainPageMap stateService={stateService}/>
                                </div>
                                {/*<MapBlock stateService={stateService}/>*/}
                                <SearchAdvBlock stateService={stateService} />
                            </div>
                            <hr/>
                            <div className="flex-container">
                                <div className="flex-item-left">
                                    {/*<RegionsList/>*/}
                                    <NewNknListBlock/>
                                </div>
                                <div className="flex-item-right">
                                    <CategoriesList/>
                                    <RootStatistics/>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </>)
};

export default RootMain;
