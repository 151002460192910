import React from 'react';
import { Accordion } from 'react-bootstrap';
import {UISref} from '@uirouter/react';
import {connect} from 'react-redux';
import renderAccordionCards from './accordion.list.render';
import RegionTree from "common/RegionTree";
import { useTranslation } from 'react-i18next';

if (ENV.debug.root) console.log('regions.list.component.js');

export const RegionsList = (props) => {
    const tree = new RegionTree();
    tree.setTree(props.regions);

    const { t, i18n } = useTranslation();

    const renderRegion = (reg) => {
        // debugger;
        return tree
        .getChildren(reg.id)
        .sort((a, b) => a.sortOrd - b.sortOrd)
        .map(reg => (
            <li key={`reglist${reg.id}${reg._id}`}>
                <UISref to={'public.region.list'} params={{regionId: reg.id, page: 0}}><a>{getValueByLocale (reg, 'shortDescr', i18n.language)}</a></UISref>
            </li>)
        )
    };

    const renderTitle = okr => {
        getValueByLocale (okr, 'shortDescr', i18n.language)
    }

    return (
        <div className="federal-district">
            <h2>Федеральный округ</h2>
            <div className="accordion-federal-district">
                <Accordion className="infoAccordion">
                    {renderAccordionCards(tree.getRootList(), 'okrugsmain', renderTitle, renderRegion)}
                </Accordion>
            </div>
        </div>)
};

const mapState = state => state.regions;

// export default RegionsList;
export default connect(mapState)(RegionsList);

