import React, {Component} from 'react';
// import StartPageMap from "../root/map.startPage.component";
// import StartPageMap2 from "../root/map.startPage2.component";
import StartPageMap3 from "../root/map.startPage3.component";
// компоненты bootstrap
import Breadcrumb from 'react-bootstrap/Breadcrumb';

// предлагаю отделять стили для страницы-контейнера и для отдельных карточек в списке
import './less/start.page.component.less';
import {UIView} from "@uirouter/react";


if (ENV.debug.root) console.log('start.page.component.js');

export const StartHeader = ( props ) => {
    return (<>
    <div></div>
    </>)
}

export const StartFooter = ( props ) => {
    return (<>
        <div></div>
    </>)
}

// Классы всегда именуются с большой буквы
export const StartPage = (props) => {
    const texts = {
        title: `Объекты нематериального культурного наследия народов Российской Федерации`,
        emptyNode: `Список заполняется`,
        emptyList: `Документы по вашему запросу находятся в стадии заполения`
    };
    return (<>
            {/*<Breadcrumb>*/}
            {/*    <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}
                <div className="main-content-start-page">
                    <UIView/>
                     <StartPageMap3 />
                </div>

        </>
    )
};

export default StartPage;
