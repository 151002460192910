import React from 'react';
import ReactDOM from 'react-dom';
import { UIRouter, UIView } from '@uirouter/react';
import { Provider } from 'react-redux';

import {router} from "./router.config";
import {store} from "./store.config";
import { Suspense } from 'react';

import './common/i18next'

if ( ENV.debug.root ) console.log ( 'app.js' );

ReactDOM.render(
    <Suspense fallback={<div>Loading...</div>}>
        <UIRouter router={router}>
            <Provider store={store}>
      	    	<UIView/>
            </Provider>
        </UIRouter>
    </Suspense>,
    document.getElementById('root')
);
