export default class EntityTree {
    constructor( data ){
        this.setTree(data);
        this.root = null;
    }

    getList ( parentId ) {
        if (this.treeList == null ) return [];
        // == is important: parentId may be null or undefined
        return this.treeList.filter ( entity => entity.parentId == parentId ).sort ( (a,b) => a.sortOrder - b.sortOrder );
    }

    getRootList () {
        return this.getList( this.root ? this.root.id : this.root );
    }

    getChildren ( parentId ) {
        return this.getList(parentId );
    }

    getFirstChild () {
        const roots = this.getRootList();
        if ( !roots.length ) return null;

        const childrens = this.getChildren(roots[0].id);
        return childrens.length ?  childrens[0] : roots[0];
    }

    setTree ( data ) {
        this.treeList = Array.isArray(data) ? data : [];
	}

	findById ( id ) {
        return this.treeList.find ( entity => entity.id === id );
    }

    getBranch ( childId ) {
        const branch = [];
        for ( let currChildNode = this.findById ( childId );
              currChildNode != null;
              currChildNode = this.findById ( currChildNode.parentId )) {
            branch.unshift(currChildNode);
        }
        return branch;
    }

    setRoot ( node ) {
    	this.root = node;
    }
}
