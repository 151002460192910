import React, {useEffect, useState} from "react";
import {Form, Spinner} from 'react-bootstrap';

import cubaService from "../../common/api/cuba.reqs.service";
import {useComponentWillMount} from 'common/utils';

const fetchDistricts = async () => {
    // console.log(`fetchDistricts`);
    const response = await cubaService.getAllItems('common$DictFederalDistrict\n');
    return response != null && response.data != null ? response.data : null;
};

const fetchDistrictStat = async (currDistrictId) => {
    // console.log(`fetchDistrictStat`);
    const response = await cubaService.getStatByRegion(currDistrictId);
    return response != null && response.data != null ? response.data : [];
};

const processDistrictStats = (data) => {
    const resStat = {};
    data.forEach(item => resStat[item.region.code] = item.count);
    return resStat;
};

export const DistrictStatisticsBlock = (props) => {

    const [districts, setDistricts] = useState(null);
    const [currDistrict, setCurrDistrict] = useState(null);
    const [currRegion, setCurrRegion] = useState(null);
    const [currDistrictStats, setCurrDistrictStats] = useState(null);


    // // step 1: get districts
    useComponentWillMount(() => fetchDistricts().then(data => setDistricts(data)));

    // step 2: set default district
    useEffect(() => {
        // console.log("useEffect for districts", districts);
        if (districts == null) return;
        if (districts.length === 0) return;
        setCurrDistrict(districts[0]);
    }, [districts]);

    // step 3: set default region when district was changed
    useEffect(() => {
        console.log("useEffect for currDistrict", currDistrict);
        if (props.regions == null) return;
        if (currDistrict == null) return;

        const children = props.regions.getChildren(currDistrict.id);
        const newRegion = children != null && children.length ? children[0] : null;
        setCurrRegion(newRegion);
        // notify parent
        props.onDistrictChange(currDistrict);
        props.onRegionChange(newRegion);

        fetchDistrictStat(currDistrict.id).then(data => setCurrDistrictStats(processDistrictStats(data)));
    }, [currDistrict]);

    const changeDistrict = (evt) => {
        const selectedId = evt.currentTarget.value;
        const newDistrict = districts.find(d => d.id === selectedId);
        setCurrDistrict(newDistrict);
        setCurrDistrictStats(null);
    };

    const changeRegion = (evt) => {
        const selectedId = evt.currentTarget.dataset.value;
        const newRegion = props.regions.findById(selectedId);
        setCurrRegion(newRegion);
        props.onRegionChange(newRegion);
    };

    return (<>
        <DistrictSelect onChange={changeDistrict} districts={districts}/>
        <h2>{}</h2>
        <div className="list-regions">
            <StatisticsByRegions
                regions={props.regions}
                currDistrict={currDistrict}
                currRegion={currRegion}
                districtStats={currDistrictStats}
                onChange={changeRegion}
            />
        </div>
    </>)
};

export default DistrictStatisticsBlock;

const StatisticsByRegions = ({regions, currDistrict, currRegion, districtStats, onChange}) => {
    if (regions == null) return null;
    if (currDistrict == null) return null;
    if (currRegion == null) return null;

    if (districtStats == null) return (<Spinner animation="border" variant="primary"/>);

    const children = regions.getChildren(currDistrict.id);
    return children.map(r => (
        <div key={`rsl${r.id}`}
             className={`single-record clearfix ${r.id === currRegion.id ? 'active' : ''}`}
             data-value={r.id}
             onClick={onChange}>
            <span className="float-left">{r.descr}</span>
            <span
                className="float-right">{districtStats && districtStats[r.id] != null ? districtStats[r.id] : -1}</span>
        </div>));
};


const DistrictSelect = ({districts, onChange}) => {
    if (districts === null) return null;
    if (districts.length === 0) return null;

    return (<Form.Group className={"district-select"}>
                    <Form.Control as="select" defaultValue="0" custom onChange={onChange}>
                        {districts.map(d => <option key={`sdl${d.id}`} value={d.id}>{d.descr}</option>)}
                    </Form.Control>
                </Form.Group>)
};

