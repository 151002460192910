import cubaService from 'common/api/cuba.reqs.service';
import QueryBuilder from "common/api/query-builder.service";
import { EMPTY_PHOTO_URL } from 'common/constants';
import DictionaryTree from "common/DictionaryTree";
import EntityTree from 'common/EntityTree';
import React, {useEffect, useState} from "react";

export const setupDictsData = ( nkn, categories ) => {
        if ( !nkn ) return;

        nkn.dictTree = new DictionaryTree(nkn.dictionaryLinks);
        const cats = new EntityTree();
        cats.setTree( categories);

        // build branch from categories tree
        nkn.categories = nkn.dictTree.category.map(cat => cats.getBranch(cat.id));
    };


export const nknPreview = ( {randomNkn} ) => {
    const [preview, setPreview] = useState(EMPTY_PHOTO_URL);

    useEffect(() => {
        getMainPhotoUrl(randomNkn).then ( data => setPreview(data));
    }, [] );
    return (<div className="block-img" style={{'backgroundImage': `url('${preview}')`}} />);
};


export const getMainPhotoUrl = async ( nkn ) => {
    if ( !nkn ) return EMPTY_PHOTO_URL;
    if ( nkn.mainPhoto ) return nkn.mainPhoto.url || EMPTY_PHOTO_URL;

    // get main img
    const q = new QueryBuilder({"heritageObject": nkn.id, "isMain": "true", "mediaType": "PHOTO"});
    q.setView('heritageMediaCardView');
    let media = await cubaService.getItemsByFilter('nkn$HeritageMedia', q.toString(), 'heritageMediaCardView');

    // get all if main is not set
    if (media.data.length === 0) {
        q.setData({"heritageObject": nkn.id, "mediaType": "PHOTO"});
        media = await cubaService.getItemsByFilter('nkn$HeritageMedia', q.toString(), 'heritageMediaCardView');
    }

    return Promise.resolve( media.data.length > 0 ? ENV.apiFrontEnd + `/files/${media.data[0].file.id}` : EMPTY_PHOTO_URL);
};


export const getRegion = async ( nkn ) => {
    if ( !nkn ) return EMPTY_PHOTO_URL;
    if ( nkn.mainPhoto ) return nkn.mainPhoto.url || EMPTY_PHOTO_URL;

    // get main img
    const q = new QueryBuilder({"heritageObject": nkn.id, "isMain": "true", "mediaType": "PHOTO"});
    q.setView('heritageMediaCardView');
    let media = await cubaService.getItemsByFilter('nkn$HeritageMedia', q.toString(), 'heritageMediaCardView');

    // get all if main is not set
    if (media.data.length === 0) {
        q.setData({"heritageObject": nkn.id, "mediaType": "PHOTO"});
        media = await cubaService.getItemsByFilter('nkn$HeritageMedia', q.toString(), 'heritageMediaCardView');
    }

    return Promise.resolve( media.data.length > 0 ? ENV.apiFrontEnd + `/files/${media.data[0].file.id}` : EMPTY_PHOTO_URL);
};

export const getResearcher = async ( nkn ) => {
    if ( !nkn ) return [];

    const calls = nkn.authors.map ( author => cubaService.getItemById('nkn$Researcher', author.id, 'researcherView'));
    const translations = await Promise.allSettled ( calls );

    // cleanup and unify
    const result = translations.map ( t => {
        const resObj = t.value.data;

        delete resObj.heritageObjects;
        resObj.localizationFields = resObj.localizationResearcherFields;
        delete resObj.localizationResearcherFields;
        return resObj;
    } );
    return Promise.resolve( result );
};
