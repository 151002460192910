import React, {useEffect, useState} from 'react';

// компоненты bootstrap
import {Container, Row, Col, Tab, Nav, Spinner} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Pagination from 'react-bootstrap/Pagination';
import Masonry from 'react-masonry-css'

// компонент для организации перехода по ссылке
//import {UISref} from '@uirouter/react';

// компоненты для получения данных от backend
import cubaService from 'common/api/cuba.reqs.service';
import QueryBuilder from 'common/api/query-builder.service.js';
import EntityTree from 'common/EntityTree';
import {DocumentShortCard} from './document.shortcard.component';
import {UISref} from "@uirouter/react";

// предлагаю отделять стили для страницы-контейнера и для отдельных карточек в списке
import './less/council.page.component.less';
import {useTranslation} from 'react-i18next';

if (ENV.debug.root) console.log('council.page.component.js');

const FILES_ROOT = ENV.apiFrontEnd + "/files/";

const leftMenuList = [
    {key: 'b293426a-e7bf-4c29-7adb-8d0e17111d10', titleId: "expert-council.regulations", locale: ['ru']},
    {key: 'f6a11022-a3d4-2d49-5e57-ff2601295ed5', titleId: "expert-council.regulations", locale: ['en']},
    {key: 'council', titleId: "expert-council.compositions", locale: ['ru', 'en']},
    {key: '6f719090-dd74-0813-f4b2-a640ec7d0daf', titleId: "expert-council.regional", locale: ['ru']},
    {key: '431de47a-4f0c-d819-192b-fd922077e1cf', titleId: "expert-council.minutes", locale: ['ru']},
    {key: 'another', titleId: "expert-council.other", locale: ['ru']},
];

// Классы всегда именуются с большой буквы
export const СouncilPage = (props) => {
    const [loading, setLoading] = useState(false)
    const [entityList, setEntityList] = useState([])
    const [expertList, setExpertList] = useState([])
    const [currTypeKey, setCurrTypeKey] = useState('council')
    const [docTypesTree, setDocTypesTree] = useState(null)
    const {t, i18n} = useTranslation()

    // чтения данных при старте компонента
    // сначала читаем типы, потом выбераем первый по списку тип,
    // потом грузим данные как если бы этот тип выбрал пользователь
    async function loadDicts() {
        const COUNCIL_ROOT_DESCR = `sovetRoot-${i18n.language}`;

        // ставим на паузу
        setLoading(true)

        // здесь читаем только типы
        const response = await cubaService.getAllItems('nkn$DocumentTypeDictionaryEntity')

        // сохраняем данные
        const data = await response.data;
        let docRoot = data.find(d => d.description === COUNCIL_ROOT_DESCR);

        const docTypesTree = new EntityTree(data)
        docTypesTree.setRoot(docRoot);
        setDocTypesTree(docTypesTree)

        // вычисляем id первого типа документов
        // const firstChild = docTypesTree.getFirstChild();
        setCurrTypeKey('council')
        // loadContent('council');

        // сохраняем полученный данные в состояние
        setLoading(false)
    }

    useEffect(() => {
        // грузим перваначальные данные
        loadDicts();
    }, [i18n.language])

    // вызывается при активации пункта дерева слева
    async function loadContent(currTypeKey) {
        setCurrTypeKey(currTypeKey);

        if (currTypeKey === 'another') return;
        if (currTypeKey === 'council') {
            setLoading(true)
            const responseExpert = await cubaService.getAllItemsByView('nkn$ExpertDictionaryEntity', 'expertDictionaryEntityView');// cubaService.getAllItems( 'nkn$ExpertDictionaryEntity' );
            // const responseImgExpert = await cubaService.getAllItemsByView('nkn$ExpertDictionaryEntity', 'expertFullView');

            setLoading(false)
            const expertList = responseExpert.data ? responseExpert.data : []
            setExpertList(expertList)
            return;
        }

        // ставим на паузу
        setLoading(true)

        const q = new QueryBuilder({"docType": currTypeKey});
        const response = await cubaService.getItemsByFilter('nkn$Document', q.toString());
        const data = response.data;

        // сохраняем полученный данные в состояние
        setLoading(false)
        const entityList = data ? data : null
        setEntityList(entityList)
    }


    useEffect(() => {
        // грузим перваначальные данные
        loadContent(currTypeKey);
    }, [currTypeKey])

    async function downloadItem(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        const docId = evt.currentTarget.id;
        if (docId == null) return;

        let response = await cubaService.getItemById('nkn$Document', docId, "documentFileView");
        if (response.data == null) return;
        if (response.data.file == null) return;

        const doc = response.data;
        setTimeout(() => {
            // window.open(`${FILES_ROOT}${doc.file.id}`);
            window.location.href = `${FILES_ROOT}${doc.file.id}`;
        }, 100);
    }

    function renderObjectsPanes() {
        if (loading) return (<Spinner animation="border" variant="primary"/>);

        return <div className="sort-objects">
            <Row>
                {entityList.length
                    ? entityList.map(e => {
                        return <Col md={12} key={e.id}><DocumentShortCard doc={e} onDownload={downloadItem}/></Col>
                    })
                    : <Col md={12}><p>{t("filling-out")}</p></Col>}
            </Row>

        </div>
    }

    // вызывается для отрисовки дочерних элементов списка слева
    function renderTypesBranch(parentId) {
        return docTypesTree.getChildren(parentId).map(docType => {
            return <Nav.Item key={docType.id}>
                <Nav.Link eventKey={docType.id}>{docType.title}</Nav.Link>
            </Nav.Item>

        });
    }

    // вызывается для отрисовки родительских элементов списка слева
    function renderTypesTree() {
        return docTypesTree.getRootList().map(docType => {
            return <div key={docType.id}>
                <span>{docType.title}</span>
                <ul>
                    {renderTypesBranch(docType.id)}
                </ul>
            </div>
        });
    }

    const ExpertCard = (props) => {
        const cardValues = {...props};
        if (i18n.language !== 'ru' && Array.isArray(cardValues.localizationFields) && cardValues.localizationFields.length > 0) {
            ['degree', 'position', 'lastName', 'firstName', 'middleName']
                .map(fieldName => {
                    if (cardValues.localizationFields[0].hasOwnProperty(fieldName)) {
                        cardValues[fieldName] = cardValues.localizationFields[0][fieldName]
                    }
                })
        }
        return <Col sm={12}>
            <div className="block-member">
                <Row>
                    <Col sm={5}>
                        {cardValues.image
                            ? <img style={{height: 'auto', width: '100%'}}
                                   src={`https://nkn.givc.ru/api/v2/files/${cardValues.image.id}`} alt=''/>
                            : <div style={{height: '158px', width: '100%', backgroundColor: '#e2ecff'}}/>}
                    </Col>
                    <Col sm={7}>
                        {cardValues.position ?
                            <h4>{cardValues.position}</h4>
                            : null}
                        <h3>{[cardValues.lastName, cardValues.firstName, cardValues.middleName].filter(Boolean).join(" ")}</h3>
                        <p className="position-right-col">{cardValues.degree}</p>
                    </Col>
                </Row>
            </div>
        </Col>
    }

    const renderExpertsPanes = () => {
        if (loading) return (<Spinner animation="border" variant="primary"/>);

        if (expertList.length > 0) {
            return expertList
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map( expert => <ExpertCard key={expert.id} {...expert}/>)
        } else {
            return <p className="emptyList">{t("filling-out")}</p>
        }
    }

    // TODO: add translation
    if (docTypesTree === null) return <div>Категории не найдены</div>;
    if (currTypeKey === null) return <div>Документы не загружены</div>;

    const breakpointColumnsObj = {
        default: 2,
        1100: 2,
        700: 1,
        500: 1
    };


    return (
        <React.Fragment>
            <Breadcrumb>
                <UISref to={"public.main"}><Breadcrumb.Item href="/">{t("main")}</Breadcrumb.Item></UISref>
            </Breadcrumb>

            <Tab.Container activeKey={currTypeKey} onSelect={loadContent}>
                <Row className="council-block">
                    <Col sm={3}>
                        <h1><b>{t("expert-council.title")}</b></h1>
                        <h2>{t("expert-council.federal")}</h2>
                        <Nav variant="pills" className="flex-column">
                            {leftMenuList
                                .filter(lml => lml.locale.includes(i18n.language))
                                .map(lml => <Nav.Item key={lml.key}>
                                    <Nav.Link eventKey={lml.key}>
                                        {t(lml.titleId)}
                                    </Nav.Link>
                                </Nav.Item>)}
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content className={"right-col-card"}>
                            <Tab.Pane eventKey="b293426a-e7bf-4c29-7adb-8d0e17111d10">
                                <h3>{t("expert-council.regulations")}</h3>
                                <Row>
                                    <Col sm={12}>
                                        {renderObjectsPanes()}
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="f6a11022-a3d4-2d49-5e57-ff2601295ed5">
                                <h3>{t("expert-council.regulations")}</h3>
                                <Row>
                                    <Col sm={12}>
                                        {renderObjectsPanes()}
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="council">
                                <h3>{t("expert-council.compositions2")}</h3>
                                <Row>
                                    <Masonry
                                        breakpointCols={breakpointColumnsObj}
                                        className="my-masonry-grid"
                                        columnClassName="my-masonry-grid_column"
                                    >
                                        {renderExpertsPanes()}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={ChAV} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h4>Председатель Экспертного совета</h4>*/}
                                        {/*        <h3>Черных Александр Васильевич</h3>*/}
                                        {/*        <p className="position-right-col">член-корреспондент РАН, доктор исторических наук, профессор, зав. сектором Отдела истории, археологии и этнографии ПФИЦ УрО РАН.</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={POA} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h4>заместитель председателя Экспертного совета</h4>*/}
                                        {/*        <h3>Пашина Ольга Алексеевна</h3>*/}
                                        {/*        <p className="position-right-col">доктор искусствоведения, ученый секретарь ГИИ, представитель от России в Международном совете по традиционной музыке (ICTM) при ЮНЕСКО</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={DVE} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h4>ответственный секретарь Экспертного совета</h4>*/}
                                        {/*        <h3>Добровольская Варвара Евгеньевна</h3>*/}
                                        {/*        <p className="position-right-col">кандидат филологических наук, зав. сектором нематериального культурного наследия ГРДНТ им.  В.Д. Поленова</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={RMV} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h4>руководитель рабочей группы</h4>*/}
                                        {/*        <h3>Русанова Мери Вахтанговна</h3>*/}
                                        {/*        <p className="position-right-col">первый зам. директора Государственного Российского Дома народного творчества имени В.Д. Поленова, руководитель Центра культуры народов России</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <div style={{height: '158px', width: '100%', backgroundColor: '#e2ecff'}}></div>*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Алиева Алла Ивановна</h3>*/}
                                        {/*        <p className="position-right-col">доктор филологических наук, г.н.с. Отдела фольклора ИМЛИ им. А.М. Горького РАН</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={VTG} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Владыкина Татьяна Григорьевна</h3>*/}
                                        {/*        <p className="position-right-col">доктор филологических наук, профессор, ведущий научный сотрудник отдела филологических исследований  Удмуртского федерального исследовательского центра Уральского отделения РАН, заслуженный деятель науки Удмуртской Республики</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={VAN} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Власов Андрей Николаевич</h3>*/}
                                        {/*        <p className="position-right-col">доктор филологических наук, профессор, заведующий  Отделом русского фольклора ИРЛИ (Пушкинский Дом) РАН</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={GNN} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Гилярова Наталья Николаевна</h3>*/}
                                        {/*        <p className="position-right-col">кандидат искусствоведения, профессор, зав. научным центром народной музыки им. К.В. Квитки МГК им. П. И. Чайковского, заслуженный деятель искусств России</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={GAU} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Греков Александр Умарович</h3>*/}
                                        {/*        <p className="position-right-col">кандидат искусствоведения, заслуженный деятель искусств России, член-корреспондент РАХ, зав. отделом декоративного и народного искусства НИИ теории и истории изобразительных искусств при РАХ</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={DOE} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Добжанская Оксана Эдуардовна</h3>*/}
                                        {/*        <p className="position-right-col">доктор искусствоведения, доцент, профессор кафедры искусствоведения Арктического государственного института культуры и искусств</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={DEA} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Дорохова Екатерина Анатольевна</h3>*/}
                                        {/*        <p className="position-right-col">кандидат искусствоведения, заместитель руководителя отдела «Центр русского фольклора» ГРДНТ имени В.Д. Поленова, с.н.с. Фонограммархива (Пушкинский Дом) ИРЛИ РАН</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={KNE} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Котельникова Наталия Евгеньевна</h3>*/}
                                        {/*        <p className="position-right-col">кандидат филологических наук, зам. главного редактора научного альманаха «Традиционная культура»</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={KEN} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Кузьмина Евгения Николаевна</h3>*/}
                                        {/*        <p className="position-right-col">доктор филологических наук, г.н.с., зав. сектором фольклора народов Сибири Института филологии СО РАН</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={KAV} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Кыласов Алексей Валерьевич</h3>*/}
                                        {/*        <p className="position-right-col">кандидат культурологии, руководитель Центра традиционных игр и спорта Российского НИИ культурного и природного наследия имени Д.С. Лихачева, президент Всемирного общества этноспорта (EWS)</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={LGV} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Лобкова Галина Владимировна</h3>*/}
                                        {/*        <p className="position-right-col">кандидат искусствоведения, доцент, зав. кафедрой этномузыкологии и заместитель начальника по научной работе Фольклорно-этнографического центра имени А. М. Мехнецова СПб ГК им. Н. А. Римского-Корсакова</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={MDV} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Морозов Дмитрий Викторович</h3>*/}
                                        {/*        <p className="position-right-col">руководитель отдела «Центр русского фольклора» ГРДНТ имени В.Д. Поленова, заместитель председателя Правления Общероссийской общественной организации «Российский фольклорный союз»</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={NAF} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Некрылова Анна Федоровна</h3>*/}
                                        {/*        <p className="position-right-col">кандидат искусствоведения, профессор, н.с. отдела фольклора Института русской литературы (Пушкинский Дом) РАН, член Союза театральных деятелей</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={PAV} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Пигин Александр Валерьевич</h3>*/}
                                        {/*        <p className="position-right-col">доктор филологических наук, профессор, в.н.с. Института языка, литературы и истории КарНЦ РАН, в.н.с. ИРЛИ (Пушкинский Дом) РАН</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={12}>*/}
                                        {/*  <div className="block-member">*/}
                                        {/*    <Row>*/}
                                        {/*      <Col sm={5}>*/}
                                        {/*        <img style={{height: 'auto', width: '100%'}} src={RTS} />*/}
                                        {/*      </Col>*/}
                                        {/*      <Col sm={7}>*/}
                                        {/*        <h3>Рудиченко Татьяна Семеновна</h3>*/}
                                        {/*        <p className="position-right-col">доктор искусствоведения, профессор, зав. лабораторией РГК им. С. В. Рахманинова, член Союза композиторов России</p>*/}
                                        {/*      </Col>*/}
                                        {/*    </Row>*/}
                                        {/*  </div>*/}
                                        {/*</Col>*/}
                                    </Masonry>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="6f719090-dd74-0813-f4b2-a640ec7d0daf">
                                <h3>{t("expert-council.regional")}</h3>
                                <Row>
                                    <Col sm={12}>
                                        {renderObjectsPanes()}
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="431de47a-4f0c-d819-192b-fd922077e1cf">
                                <h3>{t("expert-council.minutes")}</h3>
                                <Row>
                                    <Col sm={12}>
                                        {renderObjectsPanes()}
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="another">
                                <h3>{t("expert-council.other")}</h3>
                                <Row>
                                    <Col sm={12}>

                                    </Col>
                                </Row>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </React.Fragment>
    )
}

export default СouncilPage;
