import RootLayout from 'modules/root/root.layout';
import RootLayoutStart from 'modules/root/start.root.layuot';
import RootHeader from 'modules/root/header.component';
import RootFooter from 'modules/root/footer.component';
import RootMain from 'modules/root/main.component';

import NknMain from 'modules/nkn/main.component';
import NknCardFull from 'modules/nkn/card.full.component';

import RegionMain from 'modules/region/region.main.component';
import RegionList from 'modules/region/region.list.component';
import DistrictList from 'modules/region/district.list.component';
import CountryList from 'modules/region/country.list.component';

import CategoryMain from 'modules/category/category.main.component';
import CategoryList from 'modules/category/category.list.component';

import SearchMain from 'modules/search/search.main.component';
import SearchList from 'modules/search/search.list.component';

import DocumentList from 'modules/pages/document.list.component';

import StatisticsPage from 'modules/pages/statistics.page.component';
import CouncilPage from 'modules/pages/council.page.component';
import PublicationsPage from 'modules/pages/publications.page.component';
import ContactsPage from 'modules/pages/contacts.page.component';
import AboutPage from 'modules/pages/about.page.component';
import { StartPage, StartHeader, StartFooter } from 'modules/pages/start.page.component';

import MapMain from 'modules/map/map.main.component';
import MapDistrict from 'modules/map/map.district.component';
import MapRegion from 'modules/map/map.region.component';

if (ENV.debug.root) console.log('states.config.js');


/**
 * This is the parent state for the entire application.
 *
 * This state's primary purposes are:
 * 1) Shows the outermost chrome (including the navigation and logout for authenticated users)
 * 2) Provide a viewport (ui-view) for a substate to plug into
 */

const StartPageState = {
    url: '',
    name: 'start',
    // redirectTo: 'start',
    views: {
        '$default': {component: StartPage},
        'header@public': {component: StartHeader},
        'footer@public': {component: StartFooter},
    },
};

const publicState = {
    url: '',
    name: 'public',
    redirectTo: 'public.main',
    //component: PortalLayout,
    views: {
        '$default': {component: RootLayout},
        'header@public': {component: RootHeader},
        'footer@public': {component: RootFooter},
    },
};

const PublicMainState = {
    url: '/main',
    name: 'public.main',
    component: RootMain
};


const NknState = {
    url: '',
    name: 'public.nkn',
    component: NknMain
};


const NknCardFullState = {
    url: '/nkn/:nknId?{:pane}',
    name: 'public.nkn.card',
    component: NknCardFull

};

const RegionState = {
    url: '',
    name: 'public.region',
    component: RegionMain
};


const RegionListState = {
    url: '/region/:regionId?{page}',
    name: 'public.region.list',
    component: RegionList
};

const DistrictState = {
    url: '',
    name: 'public.district',
    component: RegionMain
};

const DistrictListState = {
    url: '/district/:districtId?{page}',
    name: 'public.district.list',
    component: DistrictList
};

const CountryState = {
    url: '',
    name: 'public.country',
    component: RegionMain
};

const CountryListState = {
    url: '/country',
    name: 'public.country.list',
    component: CountryList
};


const CategoryState = {
    url: '',
    name: 'public.category',
    component: CategoryMain
};


const CategoryListState = {
    url: '/category/:categoryId?{page}',
    name: 'public.category.list',
    component: CategoryList,
};

const SearchState = {
    url: '',
    name: 'public.search',
    component: SearchMain
};


const SearchListState = {
    url: '/search?{page}',
    name: 'public.search.list',
    component: SearchList
};


// стейт для документов
// без промежуточного вышестоящего состояния
// без дочернего состояния (карточка документа не предусмотрена)
// не забыть импортировать компонент вверху файла
// не забыть добавить в блок export defaul внизу файл
const DocListState = {
    url: '/docs',
    name: 'public.docs',
    component: DocumentList
};

const StatisticsPageState = {
    url: '/statistics',
    name: 'public.statistics',
    component: StatisticsPage
};

const CouncilPageState = {
    url: '/council',
    name: 'public.council',
    component: CouncilPage
};

const PublicationsPageState = {
    url: '/publications',
    name: 'public.publications',
    component: PublicationsPage
};

const ContactsPageState = {
    url: '/contacts',
    name: 'public.contacts',
    component: ContactsPage
};
const AboutPageState = {
    url: '/about',
    name: 'public.about',
    component: AboutPage
};

const MapState = {
    url: '',
    name: 'public.map',
    component: MapMain
};

const MapDistrictState = {
    url: '/map/district/:districtId',
    name: 'public.map.district',
    component: MapDistrict
};

const MapRegionState = {
    url: '/map/region/:regionId',
    name: 'public.map.region',
    component: MapRegion
};


export default [publicState, PublicMainState,
    NknState, NknCardFullState,
    RegionState, RegionListState, DistrictState, DistrictListState, CountryState, CountryListState,
    CategoryState, CategoryListState,
    SearchState, SearchListState,
    DocListState, StatisticsPageState,
    CouncilPageState, PublicationsPageState, ContactsPageState, AboutPageState,
    MapState, MapDistrictState, MapRegionState, StartPageState
];
