import React, {Component} from 'react';

// компоненты bootstrap
import {Container, Row, Col, Tab, Nav, Spinner} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Pagination from 'react-bootstrap/Pagination';

// компонент для организации перехода по ссылке
import {UISref} from '@uirouter/react';

// компоненты для получения данных от backend
import cubaService from 'common/api/cuba.reqs.service';
import QueryBuilder from 'common/api/query-builder.service.js';
import EntityTree from 'common/EntityTree';

// отрисовка карточки документа
import {DocumentShortCard} from './document.shortcard.component';


// предлагаю отделять стили для страницы-контейнера и для отдельных карточек в списке
import './less/document.list.component.less';
import {useState} from 'react';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

if (ENV.debug.root) console.log('publications.page.component.js');

const DOC_ROOT_DESCR = 'publRoot';
const FILES_ROOT = ENV.apiFrontEnd + "/files/";

// Классы всегда именуются с большой буквы
export const PublicationsPage = (props) => {
    const [entityList, setEntityList] = useState([])
    const [loading, setLoading] = useState(false)
    const [currTypeKey, setCurrTypeKey] = useState(null)
    const [docTypesTree, setDocTypesTree] = useState(null)
    const {t, i18n} = useTranslation()

    // чтения данных при старте компонента
    // сначала читаем типы, потом выбераем первый по списку тип,
    // потом грузим данные как если бы этот тип выбрал пользователь
    async function loadDicts() {
        const DOC_ROOT_DESCR = `publRoot-${i18n.language}`;

        // ставим на паузу
        setLoading(true)
        // здесь читаем только титы
        const response = await cubaService.getAllItems('nkn$DocumentTypeDictionaryEntity')

        // сохраняем данные
        const data = await response.data;
        let docRoot = data.find(d => d.description === DOC_ROOT_DESCR);

        const docTypesTree = new EntityTree(data);
        docTypesTree.setRoot(docRoot);
        setDocTypesTree(docTypesTree)

        // вычисляем id первого типа документов
        const firstChild = docTypesTree.getFirstChild();

        // сохраняем полученный данные в состояние
        const currTypeKey = firstChild ? firstChild.id : null
        setCurrTypeKey(currTypeKey)
        setLoading(false)
    }

    useEffect(() => {
        // грузим перваначальные данные
        loadDicts();
    }, [i18n.language])

    // вызывается при активации пункта дерева слева
    async function loadContent(currTypeKey) {
        if (currTypeKey === null) return;
        // ставим на паузу
        setLoading(true)

        const q = new QueryBuilder({"docType": currTypeKey});
        const response = await cubaService.getItemsByFilter('nkn$Document', q.toString());
        const data = response.data;

        // сохраняем полученный данные в состояние

        const entityList = data ? data : []
        setEntityList(entityList)
        setLoading(false)
    }

    // вызывается при старте компонента
    useEffect(() => {
        loadContent(currTypeKey);
    }, [currTypeKey])

    async function downloadItem(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        const docId = evt.currentTarget.id;
        if (docId == null) return;

        let response = await cubaService.getItemById('nkn$Document', docId, "documentFileView");
        if (response.data == null) return;
        if (response.data.file == null) return;

        const doc = response.data;
        setTimeout(() => {
            // TODO: здесь нужно попробовать открыть новую вкладку вместо прямого перехода
            window.open(`${FILES_ROOT}${doc.file.id}`, '_blank');

        }, 100);
    }

    const ObjectsPanes = () =>
        loading ? <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
            : <div className="sort-objects">
                <Row>
                    {entityList.length
                        ? entityList.map(e =>
                            <Col md={12} key={e.id}>
                                <DocumentShortCard doc={e} onDownload={downloadItem}/>
                            </Col>)
                        : <Col md={12}><p>{t("filling-out")}</p></Col>}
                </Row>
            </div>

/*<li key={docType.id} onClick={() => this.loadContent(docType.id)}>
              {docType.title}
          </li>

                  { entityList.length

                  ? <Pagination className="sort-pagination">
                     <Pagination.First />
                     <Pagination.Prev />
                     <Pagination.Item>{1}</Pagination.Item>
                     <Pagination.Ellipsis />

                     <Pagination.Item>{10}</Pagination.Item>
                     <Pagination.Item>{11}</Pagination.Item>
                     <Pagination.Item active>{12}</Pagination.Item>
                     <Pagination.Item>{13}</Pagination.Item>
                     <Pagination.Item disabled>{14}</Pagination.Item>

                     <Pagination.Ellipsis />
                     <Pagination.Item>{20}</Pagination.Item>
                     <Pagination.Next />
                     <Pagination.Last />
                   </Pagination>
                 : null}

          */

// вызывается для отрисовки дочерних элементов списка слева
const TypesBranch = ({parentId}) => {
    return docTypesTree.getChildren(parentId).map((docType) => {
        return <Nav.Item key={docType.id}>
            <Nav.Link eventKey={docType.id}>{docType.title}</Nav.Link>
        </Nav.Item>
    });
}

// вызывается для отрисовки родительских элементов списка слева
function renderTypesTree() {
    return docTypesTree.getRootList().map(docType => {
        return <div key={docType.id}>
            <h2>{docType.title}</h2>
            <ul>
                {renderTypesBranch(docType.id)}
            </ul>
        </div>
    });
}

// TODO: add translation
if (docTypesTree === null) return <div>Категории не найдены</div>;
if (currTypeKey === null) return <div>Документы не загружены</div>;

return (
    <React.Fragment>
        <Breadcrumb>
            <UISref to={"public.main"}><Breadcrumb.Item href="/">{t("main")}</Breadcrumb.Item></UISref>
        </Breadcrumb>

        <Tab.Container id="region-object-list" defaultActiveKey={currTypeKey} onSelect={loadContent}>
            <Row>
                <Col lg={3} md={4}>
                    <h1>{t("publications")}</h1>
                    <div className="left-col-card">
                        <Nav className="flex-column left-nav-card">
                            <TypesBranch parentId={docTypesTree.root.id} />
                        </Nav>
                    </div>
                </Col>
                <Col lg={9} md={8}>
                    <div className="right-col-card">
                        <Tab.Content>
                            <ObjectsPanes />
                        </Tab.Content>
                    </div>
                </Col>
            </Row>
        </Tab.Container>

    </React.Fragment>
)
}

// this.renderTypesTree ()

export default PublicationsPage;
