import React from 'react';
import {UISref} from '@uirouter/react';
import pinAddress from "./images/card.short/pin-address.png";

if ( ENV.debug.root ) console.log ( 'card.short.component.js' );

export const NknCardRandomTableView = ( {id, preview, region, category, title, shortDescription} ) => {
    return <div className="random-card">
        <div className="name-card"><h3>{ category }</h3></div>
        <div className="block-img" style={ preview ? {'backgroundImage': `url('${preview}')`} : [] }  />
        <div className="text-card">
            <UISref to={'public.nkn.card'} params={{nknId: id}} ><h2>{ title }</h2></UISref>
            <img src={ pinAddress } /><span className="address">{ region }</span>
            <p dangerouslySetInnerHTML={{__html: shortDescription }}/>
        </div>
    </div>
};
export default NknCardRandomTableView;

// import objAbout from "modules/root/images/obj-about.png";
// import objAudio from "modules/root/images/obj-audio.png";
// import objVideo from "modules/root/images/obj-video.png";
// import objPhoto from "modules/root/images/obj-photo.png";
// import objMap   from "modules/root/images/obj-map.png";
/*
            <div className="share-block clearfix">
                <img src={ objAbout } className="float-left" />
                <img src={ objPhoto } className="float-left" />
                <img src={ objAudio } className="float-left" />
                <img src={ objVideo } className="float-left" />
                <img src={ objMap } className="float-left" />
            </div>
 */
