export const SEARCH_DISTRICT_NAME = "federalDistrictCode";
export const SEARCH_REGION_NAME = "regionCode";
export const SEARCH_FIAS_NAME = "fiasCode ";
export const SEARCH_DICTVAL_NAME = "dictionaryCodes";
export const SEARCH_CATEGORY_NAME = "categoryCode";
export const SEARCH_TERM_NAME = "text";
export const SEARCH_STATUS_NAME = "status";
export const SEARCH_ACTUAL_STATE_NAME = "actualState";
export const SEARCH_AUTHOR_NAME = "authors";
export const SEARCH_PAGE_NAME = "page";

export const PAGE_SIZE = 9;// 9; //
