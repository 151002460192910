import React, { useState } from 'react';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import EntityTree from 'common/EntityTree';
import RegionTree from 'common/RegionTree';

import SearchShortPopup from 'modules/search/search.short.popup.component';
import imgAdvancedSearch from "./images/searchIcon.svg";

export const SearchAdvBlock = (props) => {
    const [show, setShow ] = useState ( false );
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { t } = useTranslation();


    const dicts = {'categories': new EntityTree(), 'regions': new RegionTree()};
    dicts.categories.setTree(props.categories);
    dicts.regions.setTree(props.regions);

    return (<>
		<div className="btn-advanced-search" onClick={handleShow}>{t("advanced-search")} <img
            src={imgAdvancedSearch}/>
		</div>
        <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t("advanced-search")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SearchShortPopup dicts={dicts} stateService={props.stateService}/>
            </Modal.Body>
        </Modal>
		</>)
};

const mapState = state => ({
    categories: state.categories,
    regions: state.regions,
});

export default connect(mapState)(SearchAdvBlock);

