import React from 'react';
import { Accordion } from 'react-bootstrap';
import {UISref} from '@uirouter/react';
import {connect} from 'react-redux';
import renderAccordionCards from './accordion.list.render';
import EntityTree from "../../common/EntityTree";
import { useTranslation } from 'react-i18next';
import { getValueByLocale } from 'common/utils';

if (ENV.debug.root) console.log('categories.list.component.js');

export const CategoriesList = (props) => {
    const { t, i18n } = useTranslation();
    const tree = new EntityTree();
    tree.setTree(props.categories);

    const renderCategory = (cat) => {
        const subCats = tree.getChildren ( cat.id );
        if (!subCats.length) return null; // <li>Категория заполняется</li>
        return subCats.map((item, idx) => (
            <li key={item.id}>
                <UISref to={'public.category.list'} params={{categoryId: item.id, page:0}}><a>{getValueByLocale(item,'title', i18n.language)}</a></UISref>
            </li>));
    };

    const renderCategoryTitle = (cat) => {
        return <UISref to={'public.category.list'} params={{categoryId: cat.id, page:0}}><a>{getValueByLocale(cat,'title', i18n.language)}</a></UISref>;
    };

    return (
        <div className="categories">
            <h2>{t("category-of-oich")}</h2>
            <div className="accordion-categories">
                <Accordion>
                    {renderAccordionCards(tree.getRootList(), 'catsmain', renderCategoryTitle, renderCategory)}
                </Accordion>
            </div>
        </div>
    )
};

const mapState = state => state.categories;
export default connect(mapState)(CategoriesList);

