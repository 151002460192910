import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import {PlayerIcon} from "react-player-controls";

import Duration from "../nkn/Duration";

import {b64_to_utf8} from "../../common/utils";

import volumeOn from "./images/sound-on.png";
import mute from "./images/mute.png";
import arrowRight from "./images/arrow-right.png"

import './less/map.video.modal.less';
import {useTranslation} from "react-i18next";

if (ENV.debug.root) console.log('map.video.modal.js');

const MEDIA_TYPE = "VIDEO";

const MapVideoModalBody = ( props ) => {
    const [playing, setPlaying] = useState(false);
    const [pip, setPip] = useState(false);
    const [played, setPlayed] = useState(0);
    const [seeking, setSeeking] = useState();
    const [duration, setDuration] = useState(0);
    const [controls, setControls] = useState(true);
    const [volume, setVolume] = useState(0.8);
    const [muted, setMuted] = useState(false);
    const [loop, setLoop] = useState(false);
    const [title, setTitle] = useState('');

    const playerEl = useRef(null);

    if ( !props.url ) return null;

    return <>
        <div className="video-player-wrapper">
            <ReactPlayer
                ref={playerEl}
                className='react-player'
                width='100%'
                height='100%'
                // url={presignedUrl}
                url={props.url}
                pip={pip}
                playing={playing}
                controls={controls}
                volume={volume}
                muted={muted}
                played={played}
                onPlay={() => setPlaying(true)}
                onEnablePIP={() => setPip(true)}
                onDisablePIP={() => setPip(false)}
                onPause={() => setPlaying(false)}
                onSeek={(e) => {
                    if (e.target != null) setPlayed(e.target.value)
                }}
                onEnded={() => setPlaying(loop)}
                onError={(e) => console.log('onError', e)}
                onProgress={(progress) => setPlayed(progress.played)}
                onDuration={(duration) => setDuration(duration)}
                config={{ file: {
                        attributes: {
                            controlsList: 'nodownload'  //<- this is the important bit
                        }
                    }}}
            />
        </div>
        <div className={!title ? 'faded' : ''}>
            <div className="nameSong" dangerouslySetInnerHTML={{__html: title}}/>
        </div>

        <table>
            <tbody>
            <tr>
                <td width="9%">
                    <button className="btnPlay" onClick={() => setPlaying(!playing)}>
                        {playing
                            ? <PlayerIcon.Pause/>
                            : <PlayerIcon.Play/>
                        }
                    </button>
                </td>
                <td width="53%">
                    <input
                        type='range' min={0} max={0.999999} step='any'
                        className="rangePlay"
                        value={played}
                        onMouseDown={() => setSeeking(true)}
                        onChange={(e) => {
                            if (e.target == null) return;
                            setPlayed(parseFloat(e.target.value));
                        }}
                        onMouseUp={(e) => {
                            setSeeking(false);
                            if (e.target == null) return;
                            playerEl.current.seekTo(parseFloat(e.target.value));
                        }}
                    />
                </td>
                <td width="9%"><Duration seconds={duration * (1 - played)}/></td>
                <td width="3%" title={props.t("volume-control")}>
                    {volume !== 0
                        ? <img src={volumeOn} alt={props.t("volume-control")}/>
                        : <img src={mute} alt={props.t("volume-control")}/>
                    }
                </td>
                <td width="15%" title={props.t("volume-control")}>
                    <input type='range' className="volumeControl" min={0} max={1} step='any' value={volume}
                           onChange={(e) => {
                               if (e.target == null) return;
                               setVolume(parseFloat(e.target.value));
                           }}/>
                </td>
            </tr>
            </tbody>
        </table>
    </>
}

const ModalContainer = ( {placemarkData, handleClose} ) => {
    if ( !placemarkData ) return null;
    const { t } = useTranslation();

    return (
        <Modal show={true} onHide={handleClose} className='mapVideoModal'>
            <Modal.Header closeButton className='mapVideoModal__header'>
                <Modal.Title className='mapAudioModal_title'>{placemarkData.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MapVideoModalBody url={placemarkData.mediaUrl} t={t}/>
            </Modal.Body>
            <Modal.Footer className="mapVideoModal__footer">
                <a style={{display:"flex", width:"100%"}} href={`/#/nkn/${placemarkData.nknId}`}>
                    <div className="mapVideoModal__footer-text">{t('more-info')}</div>
                    <img className="mapVideoModal__footer-icon" src={arrowRight} alt=""/>
                </a>
            </Modal.Footer>
        </Modal>
    )
}
export const MapVideoModal = (props) => {
    const [placemarkData, setPlacemarkData] = useState(null);

    const modalEl = useRef();

    const handleClose = () => setPlacemarkData(null);
    const handleShow = (e) => {
        const jsonData = b64_to_utf8(e.detail);

        // TODO: protect from null, bad json, etc with try|catch
        setPlacemarkData(JSON.parse(jsonData));
    }

    useEffect(() => {
        if (!modalEl) return;
        if (!modalEl.current) return;

        // Anything in here is fired on component mount.
        modalEl.current.addEventListener('fire-show-video', handleShow);
        return () => {
            // Anything in here is fired on component unmount.
            // modalEl.current.removeEventListener('fire-show-video', handleShow);
        }
    }, [])

    return (
        <div id="videoModalDiv" ref={modalEl}>
            <ModalContainer placemarkData={placemarkData} handleClose={handleClose}/>
        </div>
    );
}

export default MapVideoModal;
