import React, {useRef, useEffect, useState} from 'react';
import cubaService from "common/api/cuba.reqs.service";
import { useTranslation } from 'react-i18next';

import 'assets/map/stylemap.css';
import './less/mainPageMap.less';

import {mapRenders, mapSVGParams} from "./mapFedDistrictsRenders";
import {connect} from "react-redux";
import RegionTree from "../../common/RegionTree";
import { getValueByLocale } from 'common/utils';

const fetchRegionStat = async (currRegionId) => {
    // console.log(`fetchStat`);
    const response = await cubaService.getStatByRegion(currRegionId);
    return response != null && response.data != null ? response.data : [];
};


export const MainPageMap = (props) => {
    const [currParent, setCurrParent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totals, setTotals] = useState([]);
    const [stats, setStats] = useState([]);

    const tree = new RegionTree();
    tree.setTree(props.regions);

    useEffect( () => {
        setLoading(true);
        const calls = [];
        for ( let i = 1; i < 9; i++ ) calls.push ( fetchRegionStat(i) );

        Promise.all ( calls ).then( response => {
            const totals = response.map ( r => r.reduce( (accu, item ) =>  accu + item.count, 0 ));
            setTotals ( totals );
            setStats ( response );
        }).finally(() => setLoading(false));
    }, []);

    const renderRegion = (reg, lang) => {
        return tree
            .getChildren(reg ? reg.id : reg)
            .sort((a, b) => a.sortOrd - b.sortOrd)
            .map(item => {
                    let total = 0;
                    if ( item.iso3166Code != null ) {
                        const currDistrictStat = stats[(+currParent.id) - 1];
                        const currRegStat = currDistrictStat.find( r => r.region.code === item.id);
                        total = currRegStat.count;
                    } else {
                        total = totals[(+item.id) - 1];
                    }

                    return (<li key={item.id}>
                        <a href={item.iso3166Code ? `#${item.iso3166Code}` : `#federal${item.id}`}
                           data-id={item.id} className="map-tab-link"
                           onMouseEnter={(e) => onMenuMouseEnter(e, item)}
                           onMouseLeave={(e) => onMenuMouseLeave(e, item)}
                           onClick={(e) => onMenuClick(e, item)}>
                            {getValueByLocale(item, 'shortDescr', lang)}
                            <div className="info">
                                <p>{t('main-nkn-count')}</p>
                                <p><strong>{ total }</strong></p>
                            </div>
                        </a>
                    </li>)
                }
                // (<li key={`reglist${reg.id}${reg._id}`}>
                //     <UISref to={'public.region.list'} params={{regionId: reg.id}}><a>{reg.shortDescr}</a></UISref>
                // </li>)
            )
    };

    //viewBox="0 0 1073.05 603.37" 220
    const DrawSvgMap = (props) => {
        const childrens = tree
            .getChildren(props.currParent ? props.currParent.id : props.currParent)
            .sort((a, b) => a.sortOrd - b.sortOrd);
        const viewBox = props.currParent == null ? mapSVGParams["0"].viewBox : mapSVGParams[props.currParent.id].viewBox;
        const translate = props.currParent == null ? mapSVGParams["0"].translate : mapSVGParams[props.currParent.id].translate;

        return (<svg id="svg2" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}
                     preserveAspectRatio="xMidYMid meet">
                <g transform={`translate(${translate} 0)`}>
                    {childrens.map(dm => {
                        // debugger;
                        const FDRenderer = mapRenders[dm.iso3166Code ? dm.iso3166Code : dm.id];
                        return (
                            <a key={dm.id} href={dm.iso3166Code ? `#${dm.iso3166Code}` : `#federal${dm.id}`}
                               className="map-tab-link" data-color="#667B9B"
                               data-parent="null"
                               onMouseEnter={(e) => props.onMapMouseEnter(e, dm)}
                               onMouseLeave={(e) => props.onMapMouseLeave(e, dm)}
                               onClick={(e) => props.onMapClick(e, dm)}
                            >
                                <FDRenderer data-parent={dm.code}
                                /></a>)
                    })}
                </g>
            </svg>
        )
    }

    const onMenuMouseEnter = (e, dm) => {
        let self = e.currentTarget;
        let selfClass = dm.iso3166Code ? `#${dm.iso3166Code}` : `#federal${dm.id}`;
        let color = '#667B9B';
        let currentElement = document.querySelector(`.map a[href="${selfClass}"]`);
        let currentPolygon = currentElement.querySelectorAll('polygon');
        let currentPath = currentElement.querySelectorAll('path');
        if (currentPolygon) currentPolygon.forEach(el => el.style.cssText = `fill: ${color}; stroke-width: 0.1px`);
        if (currentPath) currentPath.forEach(el => el.style.cssText = `fill: ${color}; stroke-width: 0.1px`);
        self.classList.add('active');
    };

    const onMenuMouseLeave = (e, dm) => {
        let self = e.currentTarget;
        let selfClass = dm.iso3166Code ? `#${dm.iso3166Code}` : `#federal${dm.id}`;
        let currentElement = document.querySelector(`.map a[href="${selfClass}"]`);
        let currentPolygon = currentElement.querySelectorAll('polygon');
        let currentPath = currentElement.querySelectorAll('path');
        if (currentPolygon) currentPolygon.forEach(el => el.style.cssText = ``);
        if (currentPath) currentPath.forEach(el => el.style.cssText = ``);
        self.classList.remove('active');
    };

    const onMenuClick = (e, dm) => {
        e.preventDefault();
        if (dm.iso3166Code != null) { // iso3166Code is attr only for region, not for district
            // props.stateService.go('public.map.region', {regionId: dm.id});
            props.stateService.go('public.region.list', {regionId: dm.id, page: 0});
        } else {
            setCurrParent(dm);
        }
    };

    const onBreadcrumbsClick = (e) => {
        e.preventDefault();
        if (currParent != null) {
            props.stateService.go('public.district.list', {districtId: currParent.id, page: 0});
        }
    };

    const onMapMouseEnter = (e, dm) => {
        let self = e.currentTarget;
        let selfClass = dm.iso3166Code ? `#${dm.iso3166Code}` : `#federal${dm.id}`;
        let color = '#667B9B';
        let currentElement = document.querySelector(`.left-menu a[href="${selfClass}"]`);
        if (!currentElement) return;
        let currentPolygon = self.querySelectorAll('polygon');
        let currentPath = self.querySelectorAll('path');
        if (currentPolygon) currentPolygon.forEach(el => el.style.cssText = `fill: ${color}; stroke-width: 0.1px`);
        if (currentPath) currentPath.forEach(el => el.style.cssText = `fill: ${color}; stroke-width: 0.1px`);
        currentElement.classList.add('active');
    };

    const onMapMouseLeave = (e, dm) => {
        let self = e.currentTarget;
        let selfClass = dm.iso3166Code ? `#${dm.iso3166Code}` : `#federal${dm.id}`;
        let currentElement = document.querySelector(`.left-menu a[href="${selfClass}"]`);
        if (!currentElement) return;
        let currentPolygon = self.querySelectorAll('polygon');
        let currentPath = self.querySelectorAll('path');
        if (currentPolygon) currentPolygon.forEach(el => el.style.cssText = ``);
        if (currentPath) currentPath.forEach(el => el.style.cssText = ``);
        currentElement.classList.remove('active');
    };

    const onMapClick = (e, dm) => {
        e.preventDefault();
        if (dm.iso3166Code != null) { // iso3166Code is attr only for region, not for district
            props.stateService.go('public.map.region', {regionId: dm.id, page: 0});
        } else {
            setCurrParent(dm);
        }
    };

    const { t, i18n } = useTranslation();

    // const currMapItemsList = mapItemsList.filter(lla => {
    //     return lla.parent == currParent;
    // });

    return (<>
        {/*<h2>На карте России</h2>*/}
        <div className="main-page-map" style={{paddingTop: '20px'}}>
            <div className="breadcrumbs">
                        <span className="curr map-tab-link"
                              onClick={() => setCurrParent(null)}>{t("russia")}</span>
                {currParent != null && <>
                    <span><b> &lt; </b></span>
                    <span style={{cursor: 'pointer'}} onClick={onBreadcrumbsClick}><b>{getValueByLocale (currParent, 'descr', i18n.language) }</b></span>
                </>}
            </div>
            <div className="content">
                <div className="left-menu">
                    <ul>
                        {stats.length > 0 && renderRegion(currParent, i18n.language)}
                    </ul>
                </div>

                <div className="map">
                    {stats.length > 0 && <DrawSvgMap
                        // currMapItemsList={currMapItemsList}
                        currParent={currParent}
                        onMapMouseEnter={onMapMouseEnter}
                        onMapMouseLeave={onMapMouseLeave}
                        onMapClick={onMapClick}
                    />}
                </div>
            </div>
        </div>
    </>)
};

/*
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <span>{dataList.district}</span>
        </Tooltip>
    );

                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 150, hide: 150 }}
                            overlay={renderTooltip}
                        >
 */

const mapState = state => state.regions;

// export default MainPageMap;
export default connect(mapState)(MainPageMap);
