import React, {useEffect, useState} from "react";
import {Col, Form, Row, Spinner} from 'react-bootstrap';
import Chart from "react-google-charts";

import cubaService from "../../common/api/cuba.reqs.service";
import {getRandomIntegerNumber, useComponentWillMount} from 'common/utils';
import EntityTree from "../../common/EntityTree";

const pieOptions = {
    slices: [{color: "#865cd2"}, {color: "#4b6aa0"}, {color: "#80b54b"}, {color: "#c45c5c"}, {color: "#e69a48"}],
    title: '',
    legend: 'none',
    is3D: 'false',
    pieHole: 0.5,
    chartArea: {left: 0, top: 0, width: "100%", height: "100%"},
    fontName: "Roboto",
    allowHtml: true
};

const fetchRegionStat = async (currRegionId) => {
    // console.log(`fetchStat`);
    const response = await cubaService.getStatCatsByRegion(currRegionId);
    return response != null && response.data != null ? response.data : [];
};

const processStatData4Chart = (data) => {
    return data.reduce ( (accu, item ) => {
        accu.push ([item.title, item.count ]);
        return accu;
        }, [ [' ', ' '] ] );
};


const processStatData = (data) => {
    // build tree
    return data.reduce((accu, item) => {
        if (item.category.parentId == null) {
            const parent = accu.find(r => r.id === item.category.id);
            if (parent == null) {
                // create new root object
                accu.push({...item.category, count: item.count || 0, children: []});
            }
        }
        if (item.category.parentId != null) {
            const parent = accu.find(r => r.id === item.category.parentId);
            if (parent != null) {
                parent.children.push({...item.category, count: item.count || 0});
            }
        }
        return accu;
    }, []);
};

const processStatData4Table = (statDataTree, expanded) => {
    // if ( expanded == null ) return;
    if ( statDataTree == null ) return;

        const colors = ["fiolet", "blue", "green", "red", "orange"];
        const resData = [[{type: "string", p: {html: true}}, ' ', ' ', '', '']];

    statDataTree.forEach((item, idx) => {
            resData.push([`<div class="block-color ${colors[idx]}"></div>`, item.title, `<span class="number">${item.count}</span>`, idx, true]);
            if ( idx === expanded ) {
                item.children.forEach(ch => {
                    resData.push([`<div class="block-color"></div>`, `<span class="subcategory">${ch.title}</span>`, ch.count, idx, false ]);
                })
            }
        });
        return resData;
};

export const RegionStatisticsBlock = (props) => {

    const [currDistrict, setCurrDistrict] = useState(null);
    const [currRegion, setCurrRegion] = useState(null);

    const [statData, setStatData] = useState(null);
    const [statDataCount, setStatDataCount] = useState(0)
    const [statDataPie, setStatDataPie] = useState(null);
    const [statDataTable, setStatDataTable] = useState(null);
    const [expanded, setExpanded] = useState(null);
    const [total, setTotal] = useState ( 0 );

    // step 3: set default region when district was changed
    useEffect(() => {
        console.log("useEffect for currRegion", props.currRegion);
        if (props.categories == null) return;
        if (props.currRegion == null) return;

        setCurrRegion(props.currRegion);

        fetchRegionStat(props.currRegion.id).then(data => {
            const statDataTree = processStatData(data);
            const total = data.reduce( (accu, item ) =>  accu + item.count, 0 );
            setTotal( total );
            setStatData ( statDataTree );
            setExpanded (0);
            const statDataCount = statDataTree.reduce((a,b) => a + b.count, 0)
            setStatDataCount(statDataCount)

            setStatDataPie(processStatData4Chart(statDataTree));
            setStatDataTable(processStatData4Table(statDataTree, 0));

        });
    }, [props.currRegion]);

    useEffect(() => {
        // if ( expanded == null ) return;

        setStatDataTable(processStatData4Table(statData, expanded));

    }, [expanded]);

    return (<>
        <div className="block-pie-chart">
            <div className="name-object">{currRegion ? currRegion.descr : ''} </div>
            <h2>Количество объектов по категориям</h2>
            <Row className="">
                <Col md={7}>
                    <Chart
                        width={'100%'}
                        height={'auto'}
                        chartType="Table"
                        loader={<div>Загрузка диаграммы</div>}
                        data={statDataTable}
                        options={{...pieOptions, alternatingRowStyle: false}}
                        graph_id="TableChart"
                        legend_toggle
                        rootProps={{'data-testid': '1'}}
                        chartWrapperParams={{ view: { columns: [0, 1, 2] } }}
                        chartEvents={[
                            {
                                eventName: 'select',
                                callback(Chart, c1, c2, c3) {
                                    const selection = Chart.chartWrapper.getChart().getSelection();
                                    let currCatRoot = null;

                                    if ( selection.length ) {
                                        const selRow = selection[0].row;
                                        const isRoot = statDataTable[selRow+1][4];
                                        currCatRoot = statDataTable[selRow+1][3];

                                        if ( isRoot && currCatRoot === expanded ) currCatRoot = null;
                                    }
                                    setExpanded ( currCatRoot );
                                },
                            },
                        ]}
                    />
                </Col>
                <Col md={5}>
                    { total ? <Chart
                        width={'100%'}
                        height={'auto'}
                        chartType="PieChart"
                        loader={<div>Загрузка диаграммы</div>}
                        data={statDataPie}
                        options={pieOptions}
                        graph_id="PieChart"
                        rootProps={{'data-testid': '1'}}
                    /> : <p style={{textAlign: 'center'}}>Данные заполняются</p>
                    }
                    <div className="block-pie-chart__total">
                        <div className="block-pie-chart__total-value">{statDataCount}</div>
                        <div className="block-pie-chart__total-title">объектов НКН</div>
                    </div>
                </Col>
            </Row>
        </div>
    </>)
};

export default RegionStatisticsBlock;

