import React from 'react';
import {UISref} from '@uirouter/react';

if (ENV.debug.root) console.log('card.short.component.js');

const NknCardNewListView = ({id, title, region, categories}) => {
    return (<UISref to={'public.nkn.card'} params={{nknId: id}}>
        <div className="block-new-object">
            <h5>{title}</h5>
            <p>{region}<br/>
                {categories}</p>
        </div>
    </UISref>)
};
export default NknCardNewListView;
