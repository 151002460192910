import {UIRouterReact, servicesPlugin, hashLocationPlugin} from '@uirouter/react';

import states from './states.config';

if ( ENV.debug.root ) console.log ( 'router.config.js' );


// Create a new instance of the Router
export const router = new UIRouterReact();
router.plugin(servicesPlugin);
router.plugin(hashLocationPlugin);

// Lazy load visualizer
// import ('@uirouter/visualizer').then(module => router.plugin(module.Visualizer));

// Register the initial (eagerly loaded) states
states.forEach(state => router.stateRegistry.register(state));

// Global config for router
router.urlService.rules.initial({ state: 'start' });


