import React, {useEffect, useState, Component} from 'react';
import { useTranslation } from 'react-i18next';
import {Row, Col, Nav, Spinner} from 'react-bootstrap';

import EntityTree from "common/EntityTree";
import RegionTree from "common/RegionTree";

import {connect} from "react-redux";
import {searchSliceActions} from "../../redux/search.reducer";
import {fetchCategories, fetchRegions} from "../../redux/dict.reducer";

import SearchFullPanel from 'modules/search/search.full.component';
import SearchResultsList from 'modules/search/search.results.list.component';
import SearchTagsList from 'modules/search/search.tag.list.component';
import BreadcrumbNkn from "modules/nkn/breadcrumb.nkn.component";

import {useComponentWillMount} from 'common/utils';
import * as s from "../../redux/load.status.constants";

import './less/search.list.component.less';

ENV.debug.search && console.log('search.list.component.js');

export const SearchList = (props) => {
    const [loading, setLoading] = useState(true);
    const [dicts, setDicts] = useState(null);
    const { t } = useTranslation()

    // step 1
    useComponentWillMount(() => {
        ENV.debug.search && console.log("useEffect for dicts");
        if (props.categories.status === s.EMPTY) props.dispatch(fetchCategories());
        if (props.regions.status === s.EMPTY) props.dispatch(fetchRegions());
    });

    // step 2
    useEffect(() => {
        // ENV.debug.search && console.log ( "props.categories = ", props.categories.status  );
        // ENV.debug.search && console.log ( "props.regions = ", props.regions.status  );
        if (props.categories.status === s.EMPTY || props.regions.status === s.EMPTY) return;

        const dicts = {categories: new EntityTree(), regions: new RegionTree()};
        dicts.categories.setTree(props.categories.categories);
        dicts.regions.setTree(props.regions.regions);
        setDicts(dicts);

    }, [props.regions.status, props.categories.status]);

    // step 3
    useEffect(() => {
        ENV.debug.search && console.log("useEffect for dicts");
        if (dicts == null) return;

        ENV.debug.search && console.log(props.search.filter);

        if (props.search.term.length !== 0 || Object.keys(props.search.filter).length !== 0) {
            // props.dispatch(searchSliceActions.forceUpdate ());
        } else {
            props.dispatch(searchSliceActions.dropAll());
        }
        if (props.search.page !== undefined) {
            props.dispatch(searchSliceActions.setPage(props.search.page));
        }
        setLoading(false);

    }, [dicts]);

    // UIRouter not pass page when stay in same path
    const dropPage = () => props.dispatch(searchSliceActions.setPage(0));

    if (loading) {
        return (<>
            <BreadcrumbNkn onClick={dropPage} />
            <h1>{t("advanced-search")}</h1>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </>);
    }

    return (<>
        <BreadcrumbNkn onClick={dropPage} />
        <h1>{t("advanced-search")}</h1>
        <Row bsPrefix={"row row-column"}>
            <Col lg={3} md={12}>
                <div className="left-col-card">
                    <Nav className="flex-column left-nav-card" >
                        <SearchFullPanel dicts={dicts} router={props.$transition$.router} />
                    </Nav>
                </div>
            </Col>
            <Col lg={9} md={12}>
                <div className="right-col-card">
                    <div className="sort-objects">
                        <SearchTagsList />
                        <SearchResultsList router={props.$transition$.router} startPage={props.$stateParams.page} />
                    </div>
                </div>
            </Col>
        </Row>
    </>)

};

const mapState = state => ({
    categories: state.categories,
    regions: state.regions,
    breadcrumbs: state.crumbs,
    search: state.search,
});

export default connect(mapState)(SearchList);

//
// class SearchList1 extends Component {
//     constructor(props) {
//         super(props);
//         this.props = props;
//
//         const storeState = store.getState();
// 		this.state = { entityList: [], viewId: 0, loading: true, filter: storeState.filter };
// 		console.log( "storeState.filter =", storeState.filter  );
//         this.dicts = { categories: new EntityTree(), regions: new RegionTree() };
//
//         this.loadContent = this.loadContent.bind (this);
//         this.onClear = this.onClear.bind (this);
//         this.initFilter = this.initFilter.bind(this);
//     }
//
// 	async loadDicts() {
//         // load regions and categories trees
//         const calls = [];
//         calls.push ( cubaService.getAllItems( 'nkn$HeritageObjectCategoryDictionaryEntity' ) );
//         calls.push ( cubaService.getAllItemsByView( 'common$DictRegion', 'regionWithDistrictView' ) );
//
//         const data = await Promise.all ( calls );
//         const catList = data[0].data;
//         const regionList = data[1].data;
//         this.dicts.categories.setTree(catList);
//         this.dicts.regions.setTree(regionList);
// 	}
//
//     // вызывается при нажатии на кнопку "найти" колонки слева
//     async loadContent ( criteria ) {
//
//         store.dispatch({
//             type: "SET_CRUBMS",
//             crumbs: []
//         });
//
//     	this.filter = criteria;
//
//         const srch = filter2search ( criteria );
//         const response = await cubaService.searchNknObjects( srch );
//         const data = response.data;
//
//         // add service structures for easy acceess to dictionary values
//         data.forEach( nkn => {
//             nkn.dictTree = new DictionaryTree(nkn.dictionaryLinks);
//
//             // build branch from categories tree
//             nkn.categories = nkn.dictTree.category.map ( cat => this.dicts.categories.getBranch( cat.id ) );
//
//         });
//
//         this.setState ({ loading: false, entityList: data ? data: [] });
//     }
//
// 	async componentDidMount() {
//         this.setState ({ loading: true });
//         await this.loadDicts ();
//         await this.loadContent ( this.state.filter );
// 	}
//
//     renderLeftColumn () {
//         return ;
//     };
//
// 	onClear ( ) {
//         this.loadContent ( {} );
//     }
//
//     initFilter () {
//         this.filter = {};
//
//     }
//
//     render() {
//         //if ( this.state.loading ) return this.renderLoading();
//         // debugger;
//
//         return (<React.Fragment>
//             <Breadcrumb>
//                 <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
//             </Breadcrumb>
//             <h1>Расширенный поиск</h1>
//             <Row>
//               <Col lg={3} md={4}>
//                   <div className="left-col-card">
//                       <Nav className="flex-column left-nav-card" >
//                         {this.renderLeftColumn()}
//                       </Nav>
//                   </div>
//               </Col>
//               <Col lg={9} md={8}>
//                   <div className="right-col-card">
// 					<div className="sort-objects">
//                         <SearchTagsList />
// 				  		<SearchResultsList />
// 				  	</div>
//                   </div>
//               </Col>
//             </Row>
//       </React.Fragment>)
//     }
// }

//export default SearchList;
// export default connect(mapStateToProps)(SearchList);
