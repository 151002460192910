import {useEffect, useRef} from "react";
import {SEARCH_OPTIONS, DICT_REGION_NAME, DICT_AUTHOR_NAME, MAX_DOT_TAIL} from "common/constants";
import * as sc from "modules/search/search.constants";

export const getRandomIntegerNumber = (min, max) => {
  	return min + Math.floor(Math.random() * (max - min));
};

export const isEmptyHtmlValue = ( test ) => removeHtmlTags(test).trim().length === 0;

export const removeHtmlTags = ( html ) => {
	if ( typeof html !== 'string' ) return '';
	if ( !html.length ) return '';

	html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
	html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
	html = html.replace(/<\/div>/ig, '\n');
	html = html.replace(/<\/li>/ig, '\n');
	html = html.replace(/<li>/ig, '  *  ');
	html = html.replace(/<\/ul>/ig, '\n');
	html = html.replace(/<\/p>/ig, '\n');
	// was html = html.replace(/<br\s*[\/]?>/gi, "\n");
	html = html.replace(/<br\s*\/?>/gi, "\n");
	html = html.replace(/<[^>]+>/ig, '');
	html = html.replace(/(&.*;)/ig, '');

	html = html.replace(/@/ig, '');
	return html;
};

export const buildCommonNknAttrList = ( list, attr = `title`, delimiter = ` / `) => list && list.length ? list.map ( l => l[attr] ).join (delimiter) : '';
export const buildNknCategoriesList = ( list, delimiter = `, ` ) => list && list.length ? list.map ( cat => buildCommonNknAttrList ( cat ) ).join(delimiter) : '';
export const buildNknHeritagePlacesList = ( list ) => list && list.length ? `, ${list.map ( hp => hp.address.addrText).join(", ")}` : '';

export const extractCoordinates = ( list, idx = 0 ) => {
    if ( list == null ) return null;
    if ( list.length === 0 ) return null;
    if ( list [idx] == null ) return null;
    if ( list [idx].address == null ) return null;
    if ( list [idx].address.coordinate == null ) return null;
    return [ list [idx].address.coordinate.latitude, list [idx].address.coordinate.longitude ];
};

export const useComponentWillMount = func => {
	const willMount = useRef(true);
	if (willMount.current) {
		func();
	}
	useComponentDidMount(() => {
		willMount.current = false;
	});
};
export const useComponentDidMount = func => useEffect(func, []);


export const makeFilterMultiselect = (params) => {
	const filter = {};
	params.forEach( item => {
		// debugger;
		if ( item.multiselect == null ) return;

		const option = SEARCH_OPTIONS[item.optId];
		if ( !option) return;

		let fNodeName = sc.SEARCH_DICTVAL_NAME;
		switch ( option.entityName ) {
			case DICT_REGION_NAME: fNodeName = sc.SEARCH_REGION_NAME; break;
			case DICT_AUTHOR_NAME: fNodeName = sc.SEARCH_AUTHOR_NAME; break;
		}

		if ( filter[fNodeName] == null ) filter[fNodeName] = [];

		if ( Array.isArray(item.multiselect)) {
			item.multiselect.forEach(selectedItem =>
				filter[fNodeName].push({ id: selectedItem.valueId, title: selectedItem.valueTitle }));
		} else {
			filter[fNodeName].push({ id: item.multiselect.valueId, title: item.multiselect.valueTitle });
		}
	});

	return filter;
};

export const makeFilter = (params) => {
	const filter = {};
	params.forEach( item => {
		if ( item.dictValue == null ) return;

		const option = SEARCH_OPTIONS[item.optId];
		if ( !option) return;

		const fNodeName = option.entityName === DICT_REGION_NAME ? sc.SEARCH_REGION_NAME : sc.SEARCH_DICTVAL_NAME;
		if ( filter[fNodeName] == null ) filter[fNodeName] = [];
		filter[fNodeName].push ({id: item.dictValue, title: item.valueTitle});
	});

	return filter;
};


export const buildPhAVPaneDescr = (item, lang) => {
	if (!item) return '';

	const shouldFixLastDot = (text) => {
		return text.lastIndexOf('.') <= text.length - MAX_DOT_TAIL;
	};

	return ['description' , 'recordDate' , 'recordPlace' , 'recordAuthor' , 'originalStorage' ].reduce ( (accu, attr) => {
		const rawValue = getValueByLocale(item, attr, lang);
		if (  rawValue ) {
			const cleanValue = removeHtmlTags(rawValue.trim());
			accu.push ( `${cleanValue}${ shouldFixLastDot(cleanValue) ? '. ' : ''}` );
		}
		return accu;
	}, [] ).join(' ');
};

export function utf8_to_b64(str) {
	return window.btoa(unescape(encodeURIComponent(str)));
}

export function b64_to_utf8(str) {
	return decodeURIComponent(escape(window.atob(str)));
}

export const getLocaleNode = (localeArray, localeName) => {
	if (!Array.isArray(localeArray)) return null;
	return localeArray.find(loc => loc.locale.toUpperCase() === localeName.toUpperCase());
}

export const getValueByLocale = ( obj, field, lang ) => {
	if ( !obj ) return '';
	if ( lang === 'ru' ) return obj[field];

	if ( Array.isArray(obj.localizationFields) &&
		obj.localizationFields.length > 0 &&
		obj.localizationFields[0][field] ) {
		return obj.localizationFields[0][field];
	} else {
		// return obj[field];
		return '';
	}
}

export const buildAuthorFio = (a, lang) => {
	const author = [];
	if (getValueByLocale(a, 'lastName', lang)) author.push(`${getValueByLocale(a, 'lastName', lang)} `);
	if (getValueByLocale(a, 'firstName', lang)) author.push(`${getValueByLocale(a, 'firstName', lang)} `);
	if (getValueByLocale(a, 'middleName', lang)) author.push(`${getValueByLocale(a, 'middleName', lang)}`);
	return author.join('');
};

export const buildAuthorTitle = (a, lang) => {
	const author = [];
	if (getValueByLocale(a,'lastName', lang) ) author.push(`${getValueByLocale(a,'lastName', lang)} `);
	if (getValueByLocale(a,'firstName', lang) ) author.push(`${getValueByLocale(a,'firstName', lang)} `);
	if (getValueByLocale(a,'middleName', lang) ) author.push(`${getValueByLocale(a,'middleName', lang)}`);
	if ( author.length ) author.push(', ');
	if (getValueByLocale(a,'academicDegree', lang) ) author.push(`${getValueByLocale(a,'academicDegree', lang)}, `);
	if (getValueByLocale(a,'academicTitle', lang) ) author.push(`${getValueByLocale(a,'academicTitle', lang)}, `);
	if (getValueByLocale(a,'positionTitle', lang) ) author.push(`${getValueByLocale(a,'positionTitle', lang)} `);
	return author.join('');
}
