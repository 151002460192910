import React from 'react';
import {Row, Col, Navbar, Nav, Container } from 'react-bootstrap';
import {UISref} from '@uirouter/react';

import SearchShortInput from 'modules/search/search.short.input.component';

import "./less/header.component.less";
import imgGlasses from "./images/glasses.png";
import logoHeaderDesk from "./images/header-logo.svg";
// import logoHeaderMobile from "./images/logo-header-mobile.png"
import {TOP_MENU} from "../../common/constants";
import { useTranslation } from 'react-i18next';

if (ENV.debug.root) console.log('header.component.js');



export const RootHeader = (props) => {
    const stateService = props.transition.router.stateService;

    // react не контролирует выключение режима для слабовидящих,
    // он может только его включить. выключение происходит по нажатию на кнопку,
    // создаваемую uhpv
    const onImpiredClick = () => window.special.On();

    //Translation
    const { t, i18n } = useTranslation();
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("lang", lang);
    };

    const MenuListView = (props) => {
        return props.menu
            .filter(m => m.lang.includes(i18n.language))
            .map((m, idx) => (
            <UISref key={`m${idx}`} to={m.path}><Nav.Link>{t(m.titleId)}</Nav.Link></UISref>));
    };

    // const navTranslate = t("nav", {returnObjects: true})
    return (
        <>

                <Navbar className="top-tools" expand="md">
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" label={"Развернуть"}>
                            {/*<span className="navbar-toggler-icon"></span>*/}
                            <span className="navbar-toggler-icon"/>
                            <span className="navbar-toggler-icon-special">Меню</span>
                        </Navbar.Toggle>

                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto"><MenuListView menu={TOP_MENU} /></Nav>
                        </Navbar.Collapse>

                        <div className={i18n.language === "ru" ? "visually-impaired" : "visually-impaired_none"}> 
                            <a id="specialButton" title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" onClick={onImpiredClick}>
                                <img alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" style={{height: '100%', width: 'auto'}} src={imgGlasses} />
                            </a>
                        </div>

                        <div className="select-lang">
                            <span onClick={() => changeLanguage("ru")} className={i18n.language === "ru" ? "ru-lang active" : "ru-lang"}>рус</span>
                            <span onClick={() => changeLanguage("en")} className={i18n.language === "en" ? "en-lang active" : "en-lang"}>en</span>
                        </div>
                        {/*<Nav href="#log-in-account">*/}
                        {/*    <div className="log-in-account">*/}
                        {/*        <a href={ADMIN_URL}><img style={{height: '100%', width: 'auto'}} src={imgLogin} alt=""/></a>*/}
                        {/*    </div>*/}
                        {/*</Nav>*/}
                    </Container>
                    </Navbar>

        <Container>
                <div className="top-search">
                    <Row>
                        <Col md={7}>
                            <UISref to={'start'}>
                                <a className="logo">
                                    <img style={{height: '100%', width: 'auto'}} src={logoHeaderDesk} alt="logo"
                                        className="logo-header-desk" />
                                    <div className="logo-header-text">{t("logo-header-text")}</div>
                                </a>
                            </UISref>
                        </Col>
                        <Col md={5}>
                            <SearchShortInput stateService={stateService} />
                        </Col>
                    </Row>
                </div>
            </Container>
        </>)
};

export default RootHeader
