import React, {Component} from 'react';
import {UIView} from '@uirouter/react';



export class SearchMain extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return ( <UIView></UIView>);

    }
}

export default SearchMain;
