import React, { useEffect, useRef, useState } from "react";
import { Container, Nav } from "react-bootstrap";
import { YMaps, Map, ZoomControl, Placemark } from "react-yandex-maps";
import logoStartPage from "./images/logo-start-page.svg";
import logoRFC from "./images/logo_RFC.svg";
import logoMKRF from "./images/MINCULT.svg";
import mediaBtn from "./images/mediaBtn.svg";
import mediaArrow from "./images/mediaArrow.svg";
import mediaPreview from "./images/mediaPreview.png";
import vk from "./images/vk.svg";

import { mapState } from "./map.startPage3.constants";
import {
  createZoomControlLayout,
  prepareRegionsObjects,
  paintRegions,
  preparePinsFromNkns,
} from "./map.startPage3.helper";

import "./less/mapStartPage.component.less";
import cubaService from "../../common/api/cuba.reqs.service";
import DictionaryTree from "../../common/DictionaryTree";
import {
  buildCommonNknAttrList,
  extractCoordinates,
  useComponentWillMount,
} from "../../common/utils";
import { EMPTY_PHOTO_URL } from "../../common/constants";
import * as s from "../../redux/load.status.constants";
import { store } from "../../store.config";
import { fetchCategories, fetchRegions } from "../../redux/dict.reducer";
import EntityTree from "../../common/EntityTree";
import RegionTree from "../../common/RegionTree";
import { connect } from "react-redux";
import { MapRegion } from "../map/map.region.component";
import MapAudioModal from "./map.audio.modal";
import MapVideoModal from "./map.video.modal";
import { UISref } from "@uirouter/react";
import { useTranslation } from "react-i18next";
import { getValueByLocale } from "../../common/utils";

if (ENV.debug.root) console.log("startPageMap.component.js");

// TODO: possible replace with cubaService.fetchStreamUrl
const FILES_ROOT = ENV.apiFrontEnd + "/files/";

const fetchInMapMedia = () => {
  console.log(`fetchInMapMedia`);
  return cubaService.getItemsByFilter(
    "nkn$HeritageMedia",
    JSON.stringify({
      filter: {
        conditions: [{ property: "isOnMap", operator: "=", value: true },
            // { property: "localizationHeritageMedia", operator: "=", value: true },
        ],
      },
      view: "heritageMediaMainPageCardView",
    })
  );
  // return response != null ? response.data : [];
};
const fetchFullNkn = (medias) => {
  console.log(`fetchFullMediaCards`);
  if (!(medias && medias.data)) return;

  // {"property": "isMain", "operator": "=", "value": true},
  const ids = medias.data.map((media) => media.heritageObject.id);
  return cubaService.getItemsByFilter(
    "nkn$HeritageObject",
    JSON.stringify({
      filter: { conditions: [{ property: "id", operator: "in", value: ids }] },
      view: "heritageObjectCardView",
      //"returnCount": true,
    })
  );
  // const calls = medias.data.map ( media => cubaService.getItemById( "nkn$HeritageMedia", media.id, "heritageMediaMainPageCardView") );
  // const calls = ids.map ( cubaService.getItemsByFilter(
  // JSON.stringify({
  //     "filter": { "conditions": [{"property": "isMain", "operator": "=", "value": true} ] },
  //     "view": 'heritageMediaMainPageCardView',
  // // "returnCount": true,
  // })
  // ));
  // debugger;
  // return Promise.allSettled(calls);

  // return response != null ? response.data : [];
};

// на всякий случай, если надо будет откатиться на классическое предстваление nkn
const processFullNkns = (nkns, dicts, lang) => {
  return nkns.map((nkn) => {
    // const dictTree = new DictionaryTree(nkn.dictionaryLinks);
    // build branch from categories tree
    // const nknCategory = dictTree.category.length > 0 ? dicts.categories.getBranch(dictTree.category[0].id) : [];
    // const nknCategory = dictTree.category.length > 0 ? dicts.categories.getBranch(dictTree.dictionaryEntity.id) : [];
    // const nknCategory = nkn.categories && nkn.categories.length ? buildCommonNknAttrList ( props.nkn.categories[0] ) : '';
    //[0].dictionaryLinks[0].dictionaryEntity.title
    const placemarkData = {};
    // placemarkData.category = buildCommonNknAttrList(nknCategory);
    // build branch from categories tree
    // nkn.categories = dictTree.category.map(cat => dicts.categories.getBranch(cat.id));
    let nknTitle = getValueByLocale(nkn, 'title', lang);
/*     nkn.region =
      nkn.heritagePlaces && nkn.heritagePlaces[0]
        ? nkn.heritagePlaces[0].region.descr
        : null;
 */

    let regionList = dicts.regions.treeList
        let regLocName = '';
        if ( nkn.heritagePlaces && nkn.heritagePlaces.length ) {
            const regLoc = regionList.find ( r => r.id ===  nkn.heritagePlaces[0].region.id );
            if ( regLoc ) regLocName = getValueByLocale(regLoc, 'descr', lang );
        }
    // placemarkData.categories = nkn.categories && nkn.categories.length ? buildCommonNknAttrList ( nkn.categories[0] ) : '';
    placemarkData.preview =
      nkn.mainPhoto && nkn.mainPhoto.url ? nkn.mainPhoto.url : EMPTY_PHOTO_URL;
    placemarkData.coordinates = extractCoordinates(nkn.heritagePlaces, 0);
    placemarkData.title = nknTitle;
    placemarkData.nknId = nkn.id;
    placemarkData.region = regLocName;

    const onMapMedia = nkn.heritageMedia.find((hm) => hm.isOnMap);
    placemarkData.mediaId = onMapMedia.id;
    placemarkData.medaType = onMapMedia.mediaType;

    // ссылка на файл как есть, без потока
    placemarkData.srcMediaUrl = onMapMedia.url;

    // ссылка на урл для получения потокового урла
    placemarkData.streamUrl = onMapMedia.streamUrl;

    // ссылка на потоковый урл
    placemarkData.file = null;

    return placemarkData;
  });
};

// на всякий случай, если можно будет вернуться на media
// const processFullNkns = (mediaOnMapList, dicts) => {
//     return mediaOnMapList.map(nknMedia => {
//         // const dictTree = new DictionaryTree(nkn.dictionaryLinks);
//         // build branch from categories tree
//         // const nknCategory = dictTree.category.length > 0 ? dicts.categories.getBranch(dictTree.category[0].id) : [];
//         // const nknCategory = dictTree.category.length > 0 ? dicts.categories.getBranch(dictTree.dictionaryEntity.id) : [];
//         // const nknCategory = nkn.categories && nkn.categories.length ? buildCommonNknAttrList ( props.nkn.categories[0] ) : '';
//         //[0].dictionaryLinks[0].dictionaryEntity.title
//
//         const nkn = nknMedia.heritageObject;
//         const placemarkData = {};
//         // placemarkData.category = buildCommonNknAttrList(nknCategory);
//         // build branch from categories tree
//         // nkn.categories = dictTree.category.map(cat => dicts.categories.getBranch(cat.id));
//         const mediaRegion = nkn.heritagePlaces && nkn.heritagePlaces[0] ? nkn.heritagePlaces[0].region.descr : null;
//
//         // placemarkData.categories = nkn.categories && nkn.categories.length ? buildCommonNknAttrList ( nkn.categories[0] ) : '';
//         // placemarkData.preview = nknMedia.url ? nknMedia.url : EMPTY_PHOTO_URL;
//         placemarkData.preview = nkn.mainPhoto?.url ? nkn.mainPhoto?.url : EMPTY_PHOTO_URL;
//         placemarkData.coordinates = extractCoordinates(nkn.heritagePlaces, 0);
//         placemarkData.title = nkn.title;
//         placemarkData.mediaId = nknMedia.id;
//         placemarkData.nknId = nkn.id;
//         placemarkData.medaType = nknMedia.mediaType;
//         placemarkData.region = mediaRegion;
//         placemarkData.file = nknMedia.streamUrl ? nknMedia.streamUrl : null;
//
//         return placemarkData;
//     });
// };

export const StartPageMap3 = (props) => {
  const mapRef = React.createRef(null);
  // const [ymaps, setYMaps] = useState(null);
  const [layout, setLayout] = useState(null);
  const [pins, setPins] = useState(null);
  const [dicts, setDicts] = useState(null);
  // const [entityList, setEntityList] = useState(null);
  // const [catRoots, setCatRoots] = useState([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [mapZoom, setMapZoom] = useState(mapState);
  // const [minZoom, setMinZoom] = useState(2);
  // const [maxZoom, setMaxZoom] = useState(4);
  const { t, i18n } = useTranslation();

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    if (windowSize.innerWidth <= 576) {
      setMapZoom({ center: [69.46079531460988,101.56187123312105], zoom: 2, type: null, controls: [] });
    } else if(windowSize.innerWidth <= 768) {
        setMapZoom({ center: [69.37341755120957,105.48621543107754], zoom: 2, type: null, controls: [] });
    } else {
        setMapZoom({ center: [65.6942277477303,91.59517649609371], zoom: 2, type: null, controls: [],
        });
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  // step 1: test if dicts loaded
  useComponentWillMount(() => {
    console.log("useEffect for props");
    if (props.categories.status === s.EMPTY) store.dispatch(fetchCategories());
    if (props.regions.status === s.EMPTY) store.dispatch(fetchRegions());
  });

  // step 2: build trees
  useEffect(() => {
    console.log("useEffect for dicts");
    // console.log ( "props.categories = ", props.categories.status  );
    // console.log ( "props.regions = ", props.regions.status  );
    if (props.categories.status === s.EMPTY || props.regions.status === s.EMPTY)
      return;

    const dicts = { categories: new EntityTree(), regions: new RegionTree() };
    dicts.categories.setTree(props.categories.categories);
    dicts.regions.setTree(props.regions.regions);
    setDicts(dicts);
  }, [props.regions.status, props.categories.status]);

  // step 3: use trees
  useEffect(() => {
    console.log("useEffect for nkn");
    if (dicts == null) return;

    // for rendering
    // setCatRoots(dicts.categories.getRootList());
    let entityList = [];

    fetchInMapMedia()
      .then(fetchFullNkn)
      .then((nkns) => {
        // debugger;

        if (!(nkns && nkns.data)) return;

        entityList = processFullNkns(nkns.data, dicts, i18n.language);
        const presignedCalls = entityList.map((entity) =>
          cubaService.makeRequest(entity.streamUrl)
        );

        return Promise.allSettled(presignedCalls);
      })
      .then((links) => {
        // debugger;
        links.map((link, idx) => (entityList[idx].file = link.value.data.url));
        const pins = entityList.reduce(preparePinsFromNkns, []);
        setPins(pins);
      });
  }, [dicts, i18n.language]);

  const onYandexApiAvailable = (ymaps) => {
    if (mapRef && mapRef.current) {
      var objectManager = new ymaps.ObjectManager();

      // Создадим холст.
      var pane = new ymaps.pane.StaticPane(mapRef.current, {
        zIndex: 100,
        css: {
          width: "100%",
          height: "100%",
          background: "linear-gradient(107.51deg, #12348D 0.1%, #0E2662 100%)",
        },
      });
      mapRef.current.panes.append(
        "linear-gradient(107.51deg, #12348D 0.21%, #0E2662 99.57%)",
        pane
      );

      // Создадим балун.
      var districtBalloon = new ymaps.Balloon(mapRef.current);
      districtBalloon.options.setParent(mapRef.current.options);

      // Создадим кнопки управления масштабом.
      const ZoomLayout = createZoomControlLayout(ymaps);
      const zoomControl = new ymaps.control.ZoomControl({
        options: {
          layout: ZoomLayout,
          position: { bottom: "0px", top: "auto", right: "2%", left: "auto" },
        },
      });
      // const minZoom = 2;
      // const maxZoom = 4;
      mapRef.current.controls.add(zoomControl);
      // mapRef.current.controls.add(minZoom);
      // mapRef.current.controls.add(maxZoom);

      ymaps.borders
        .load("RU", {
          lang: "ru",
          quality: 1,
        })
        .then(function (result) {
          // Создадим объект regions, где ключи это ISO код региона.
          var regions = result.features.reduce(prepareRegionsObjects, {});
          paintRegions(regions);

          // Добавим регионы на карту.
          result.features = [];
          for (var reg in regions) {
            result.features.push(regions[reg]);
          }
          objectManager.add(result);

          const selectedRegionId = "";
          objectManager.events
            .add("mouseenter", function (e) {
              var id = e.get("objectId");
              objectManager.objects.setObjectOptions(id, {
                strokeWidth: 2,
                boxShadow: "10px 10px 40px 10px gray",
                fillColor: "#2D62EE",
              });
            })
            .add("mouseleave", function (e) {
              var id = e.get("objectId");
              if (selectedRegionId !== id) {
                objectManager.objects.setObjectOptions(id, {
                  strokeWidth: 1,
                  fillColor: "#193B94",
                });
              }
            });

          mapRef.current.geoObjects.add(objectManager);
        });
    }
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  return (
    <div className="startPageMap__container" style={{ paddingTop: "70px" }}>
      <YMaps
        version={"2.1.79"}
        query={{
          lang: "ru_RU",
          apikey: "99918d52-587e-4de3-84c7-3ac13b2676ef",
          // apikey: '60445215-6d3a-4f88-87fe-8d52b72e5bc9',
          load: "package.full",
        }}
        style={{ position: "fixed", bottom: 0 }}

      >
        <Map
          width={"100%"}
          height={"100%"}
          // Создаем ссылку на инстанс мапа, чтобы использовать его
          instanceRef={mapRef}
          state={mapZoom}
          options={{ restrictMapArea: [[-70.64518041717906,-88.40482350387113],[89.08302070744847,-88.40482350394144]],
              minZoom: 2,
              maxZoom: 5
          }}
          // Используем коллбэк функцию при загрузке карты
          onLoad={(ymaps) => onYandexApiAvailable(ymaps)}
          // Подключаем модули регионов и ObjectManager
          modules={[
            "borders",
            "ObjectManager",
            "pane.StaticPane",
            "Balloon",
            "GeoObjectCollection",
            "GeoObject",
            "templateLayoutFactory",
            "control.ZoomControl",
          ]}
        >
          {layout && <ZoomControl options={{ layout, size: "small" }} />}
          {pins && pins.map((pin) => <Placemark key={pin.id} {...pin} />)}
        </Map>
      </YMaps>

      <div
        className={"startPageMap"}
      >
        <div className="startPageMap__header">
          <div
            className="startPageMap__header__logo">
            <div>
              <img
                src={logoMKRF}
                alt="logoStartPage"
                style={{ maxWidth: "130px" }}
              />
            </div>
            <div style={{ marginTop: "50px" }}>
              <img src={logoRFC} alt="logoRFC" />
            </div>
            <div style={{ marginTop: "50px" }}>
              <img src={logoStartPage} alt="logoStartPage" />
            </div>
          </div>
          <div className="startPageMap__header__name">
            {t("start-page-title.part1")} <br />
            {t("start-page-title.part2")}
          </div>
          <div className="startPageMap__language">
            <span
              onClick={() => changeLanguage("ru")}
              className={i18n.language === "ru" ? "ru-lang active" : "ru-lang"}
            >
              рус
            </span>
            <span
              onClick={() => changeLanguage("en")}
              className={i18n.language === "en" ? "en-lang active" : "en-lang"}
            >
              en
            </span>
          </div>

          <UISref to={"public.main"}>
            {windowSize.innerWidth > 415 ? (
              <a className="startPageMap__btn">{t("go-to-the-catalog")}</a>
            ) : (
              <a className="startPageMap__btn">{t("go-to-the-catalog")}</a>
              // <a className="startPageMap__btn">{t("registry")}</a>
            )}
          </UISref>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <MapAudioModal medias={pins} />
          <MapVideoModal medias={pins} />
        </div>
      </div>

      <div
        className="startPageMap__footer"
        style={{
          position: "absolute",
          bottom: "0",
          width: "100%",
          maxWidth: "30px",
          left: "3%",
          transform: "translate(-50%, 0)",
        }}
      >
        <div className="startPageMap__footer-social">
          <div className="startPageMap__footer-social-vk">
            <img src={vk} alt="vk" />
          </div>
        </div>
        <div className="startPageMap__footer-controlMap" />
      </div>
    </div>
  );
};

export default connect((state) => ({
  categories: state.categories,
  regions: state.regions,
}))(StartPageMap3);
