import React, { useContext, useState } from 'react';

import { Accordion, Card, OverlayTrigger, Popover } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import parse, { attributesToProps } from 'html-react-parser';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { buildAuthorTitle, getValueByLocale, isEmptyHtmlValue } from 'common/utils';
import cubaService from "../../common/api/cuba.reqs.service";
import PaneHeader from './pane.header.component';
import PaneFooter from './pane.footer.component';
import { t } from 'i18next';
import './less/card.full.component.less';

const FILES_ROOT = ENV.apiFrontEnd + "/files/";

if (ENV.debug.root) console.log('pane.about.component.js');

export const PaneAbout = (props) => {
    console.log("render PaneAbout");
    const { nkn, authors } = props;
    const { i18n } = useTranslation();
    const [noDataFlagChange, onNoDataFlagChange] = React.useState(true);

    return (
        <>
            <PaneHeader nkn={ nkn }/>
            <FullDescriptionBlock
                fullDescription={ getValueByLocale(nkn, 'fullDescription', i18n.language) }
                cutDescription={ getValueByLocale(nkn, 'cutDescription', i18n.language) }/>

            <MaterialItemsBlock materialItems={ getValueByLocale(nkn, 'materialItems', i18n.language) }/>
            <TechnologiesBlock technologies={ getValueByLocale(nkn, 'technologies', i18n.language) }/>
            <TransferWayBlock transferWay={ getValueByLocale(nkn, 'transferWay', i18n.language) }/>

            { noDataFlagChange ? null : <h3>{ t("reference-information") }</h3> }

            <Accordion defaultActiveKey="0" className="infoAccordion">
                <HistoryCard recordHistory={ getValueByLocale(nkn, 'recordHistory', i18n.language) }
                             onNoDataFlagChange={ onNoDataFlagChange }/>
                <InformationCard informationSources={ getValueByLocale(nkn, 'informationSources', i18n.language) }
                                 onNoDataFlagChange={ onNoDataFlagChange }/>
                <ActualStateCard actualState={ getValueByLocale(nkn, 'actualState', i18n.language) }
                                 onNoDataFlagChange={ onNoDataFlagChange }/>
                <KeepingFormsCard useForms={ getValueByLocale(nkn, 'useForms', i18n.language) }
                                  onNoDataFlagChange={ onNoDataFlagChange }/>
                <AuthorsListCard authors={ authors } lang={ i18n.language } onNoDataFlagChange={ onNoDataFlagChange }/>
            </Accordion>
            <PaneFooter nkn={ nkn }/>
        </>
    )
};

const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <span className={ classNames('name-info', { 'collapsedStyling': isCurrentEventKey }) }
              onClick={ decoratedOnClick }>
        { children }
    </span>
    );
};

const AuthorsListCard = ({ authors, lang, onNoDataFlagChange }) => {
    if (authors == null || authors.length === 0) return null;
    const resAuthors = authors.map((a, idx) => buildAuthorTitle(a, lang));
    if (resAuthors.every(a => !a.length)) return null;
    onNoDataFlagChange(false);

    return (<Card>
            <Card.Header>
                <ContextAwareToggle eventKey="fullCardAuthors">{ t("author") }</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="fullCardAuthors">
                <Card.Body>
                    { resAuthors.map((a, idx) => <p key={ `fca${ idx }` }>{ a }</p>) }
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
};

const KeepingFormsCard = ({ useForms, onNoDataFlagChange }) => {
    if (useForms == null || useForms.length === 0 || isEmptyHtmlValue(useForms)) return null;
    onNoDataFlagChange(false);

    return (<Card>
            <Card.Header>
                <ContextAwareToggle eventKey="fullCardForms">{ t("preservation-and-use") }</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="fullCardForms">
                <Card.Body>
                    <div dangerouslySetInnerHTML={ { __html: useForms } }/>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
};

const ActualStateCard = ({ actualState, onNoDataFlagChange }) => {
    if (actualState == null || actualState.length === 0 || isEmptyHtmlValue(actualState)) return null;
    onNoDataFlagChange(false);

    return (<Card>
            <Card.Header>
                <ContextAwareToggle eventKey="fullCardState">{ t("current-state") }</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="fullCardState">
                <Card.Body>
                    <div dangerouslySetInnerHTML={ { __html: actualState } }/>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
};

const isEmptyInfoSrc = (val) => {
    if (!val) return true;
    if (!val.length) return true;
    return !isEmptyHtmlValue(val);
}

const InformationCard = ({ informationSources, onNoDataFlagChange }) => {
    if (informationSources == null) return null;

    if (isEmptyInfoSrc(informationSources.archives) &&
        isEmptyInfoSrc(informationSources.bibliography) &&
        isEmptyInfoSrc(informationSources.others)) return null;

        // заказчик решил отказаться от заполнения информационных полей по одному, и захотел заполнять все скопом
        const renderInfoList = (list) => <ol>{ list.map((a, idx) => <li key={ idx }><span>{ a }</span><br/></li>) }</ol>;
        onNoDataFlagChange(false);

        return (<Card>
                <Card.Header>
                    <ContextAwareToggle
                        eventKey="fullCardInformation">{ t("sources-of-information.title") }</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="fullCardInformation">
                    <Card.Body>
                        { informationSources.archives && informationSources.archives.length
                            ? <>
                                <Card.Subtitle><b>{ t("sources-of-information.archive") }</b></Card.Subtitle>
                                <Card.Text><span
                                    dangerouslySetInnerHTML={ { __html: informationSources.archives } }/></Card.Text>
                            </>
                            : null }
                        { informationSources.bibliography && informationSources.bibliography.length
                            ? <>
                                <Card.Subtitle><b>{ t("sources-of-information.bibliography") }</b></Card.Subtitle>
                                <Card.Text><span
                                    dangerouslySetInnerHTML={ { __html: informationSources.bibliography } }/></Card.Text>
                            </>
                            : null }
                        { informationSources.others && informationSources.others.length
                            ? <>
                                <Card.Subtitle><b>{ t("sources-of-information.other") }</b></Card.Subtitle>
                                <Card.Text><span
                                    dangerouslySetInnerHTML={ { __html: informationSources.others } }/></Card.Text>
                            </>
                            : null }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    };

    const HistoryCard = ({ recordHistory, onNoDataFlagChange }) => {
        if (recordHistory == null || recordHistory.length === 0 || isEmptyHtmlValue(recordHistory)) return null;
        onNoDataFlagChange(false);

        return (<Card>
                <Card.Header>
                    <ContextAwareToggle eventKey="fullCardSources">{ t("history-of-iaf") }</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="fullCardSources">
                    <Card.Body>
                        <div dangerouslySetInnerHTML={ { __html: recordHistory } }/>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    };

    const TransferWayBlock = ({ transferWay }) => {
        if (transferWay == null || transferWay.length === 0 || isEmptyHtmlValue(transferWay)) return null;
        return (
            <React.Fragment>
                <h3>{ t("transmitting-tradition") }</h3>
                <div className="obj-cards clearfix" dangerouslySetInnerHTML={ { __html: transferWay } }/>
            </React.Fragment>
        )
    };

    const TechnologiesBlock = ({ technologies }) => {
        if (technologies == null || technologies.length === 0 || isEmptyHtmlValue(technologies)) return null;
        return (
            <React.Fragment>
                <h3>{ t("technologies-related") }</h3>
                <div className="obj-cards clearfix" dangerouslySetInnerHTML={ { __html: technologies } }/>
            </React.Fragment>
        )
    };

    const MaterialItemsBlock = ({ materialItems }) => {
        if (materialItems == null || materialItems.length === 0 || isEmptyHtmlValue(materialItems)) return null;
        return (
            <React.Fragment>
                <h3>{ t("items-related") }</h3>
                <div className="obj-cards clearfix" dangerouslySetInnerHTML={ { __html: materialItems } }/>
            </React.Fragment>
        )
    };

    const WikiPopup = (props) => {
        // src={this.state.nkn.mainPhoto ? this.state.nkn.mainPhoto.url : EMPTY_PHOTO_URL}
        const [term, setTerm] = useState(null)
        const imgUrl = term && term.image ? `${ FILES_ROOT }${ term.image.id }` : null; // EMPTY_PHOTO_URL

        const popover = term ? (<Popover id="popover-basic">
                <Popover.Content>
                    <div className="popover-body_container">
                        { imgUrl && <div
                            className="popover-body_container-img"
                            style={ {
                                backgroundImage: `url(${ imgUrl })`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            } }>

                        </div> }
                        <div className="popover-body_container-title">{ term.title }</div>
                        <div className="popover-body_container-descr">{ term.description }</div>
                    </div>

                </Popover.Content>
            </Popover>)
            : (<Popover id="popover-basic">
                <Popover.Content>
                    Данные не загружены
                </Popover.Content>
            </Popover>)

        const onToggle = (isOpen) => {
            if (!isOpen) return;
            if (term) return;

            cubaService.getItemById('nkn$DictionaryEntity', props.href, "dictionaryEntityView")
            .then(response => setTerm(response?.data));
        };

        return (
            <OverlayTrigger trigger={ ['hover', 'focus'] } placement="top" overlay={ popover } onToggle={ onToggle }>
                <span style={ { color: '#1E82FA', textDecoration: 'underline' } }>{ props["data-value"] }</span>
            </OverlayTrigger>
        )
    }

    const FullDescriptionBlock = (props) => {
        const { fullDescription, cutDescription } = props;
        if (fullDescription == null || fullDescription.length === 0) return null;
        if (cutDescription == null || cutDescription.length === 0) return null;
        const [descrCollapsed, setDescrCollapsed] = useState(true);
        /*
        "<p><a href=\"d2081c2d-7201-0a9d-e0b3-5787b1274adc\" class=\"wysiwyg-mention\" data-mention data-value=\"Подсказка зала\">@Подсказка зала</a> это термин</p>\n"
         */

        const options = {
            replace: domNode => {
                if (domNode.attribs && domNode.name === 'a') {
                    const props = attributesToProps(domNode.attribs);
                    return <WikiPopup { ...props } />;
                }
            }
        };

        const html = descrCollapsed ? cutDescription : fullDescription;
        const rendered = parse(html, options);
        return (<>
            <h3>{ t("description") }</h3>
            <div className="object-description">
                { rendered }</div>
            <div className="read-more"
                 onClick={ () => setDescrCollapsed(!descrCollapsed) }>{ descrCollapsed ? t("read-more") : t("collapse") }</div>
        </>);
    };


    export default PaneAbout;
