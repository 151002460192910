import React, {Component, useContext} from 'react';
import { useTranslation } from 'react-i18next';

// предлагаю отделять стили для страницы-контейнера и для отдельных карточек в списке
import './less/document.card.component.less';

import imgFile from "./images/pdf-file2.png";

export const DocumentShortCard = (props) =>  {
    const { t } = useTranslation();
    return (
        <div className="block-document">
        	<img  src={ imgFile } />
        	<div className="document-name"><span>{props.doc.title}</span><span>{props.doc.annotation}</span></div>
        	<a href="" target={"_blank"}><div className="download-btn" id={props.doc.id} onClick={props.onDownload}>{t("open")}</div></a>
        </div>
    )
};
